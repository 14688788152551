import { useState, useContext } from "react";
import { Box, Popover } from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import {
    UserContext,
    getDocumentTypeFromContext,
} from "../../common/userContext";
import { formatPathName } from "../../common/typeUtils";
import InfoIcon from "@mui/icons-material/InfoOutlined";

export type DocumentTypeLabelProps = {
    id: string;
};

export function DocumentTypeLabel({ id }: DocumentTypeLabelProps) {
    // const linkRef = useRef<ElementInternals>(null);
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const contextData = useContext(UserContext);
    const documentType = getDocumentTypeFromContext(contextData, id);
    const formatted = formatPathName(documentType?.pathName ?? "Missing");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const openId = open ? id : undefined;

    if (!documentType?.info) return <>{formatted}</>;
    return (
        <Box
            sx={{
                m: 0,
                b: 0,
            }}
        >
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                {formatted}
                <Box
                    sx={{
                        m: 0,
                        display: "flex",
                    }}
                >
                    <InfoIcon
                        aria-describedby={openId} 
                        onClick={handleClick}
                        sx={{
                            fontSize: 20,
                            ml: 1,
                        }}
                    />
                    <Popover
                        id={openId}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                    >
                        <Box component="div" data-color-mode="light">
                            <MDEditor.Markdown
                                //style={{background: "#FFFFFF", color: "#494949", borderRadius: "10", padding: "5px"}}
                                style={{ padding: "5px" }}
                                source={documentType.info}
                            />
                        </Box>
                    </Popover>
                </Box>
            </Box>
        </Box>
    );
}
