/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUsers = /* GraphQL */ `
  query GetUsers {
    getUsers {
      id
      firstName
      lastName
      phone
      email
      dateOfBirth
      level
      yearsOfExperience
      currentJobTitle
      currentJobType
      seekingLevels
      seekingSpecialities
      bankName
      bankBsb
      bankAccountNumber
      superannuationNumber
      taxFileNumber
      abnNumber
      wwccNumber
      wwccExpiry
      ahpraNumber
      prescriberNumber
      citizenshipStatus
      activeCampaignContactId
      activeCampaignPendingUpdate
      created
      updated
    }
  }
`;
export const getUserById = /* GraphQL */ `
  query GetUserById($pk: ID!) {
    getUserById(pk: $pk) {
      id
      firstName
      lastName
      phone
      email
      dateOfBirth
      level
      yearsOfExperience
      currentJobTitle
      currentJobType
      seekingLevels
      seekingSpecialities
      bankName
      bankBsb
      bankAccountNumber
      superannuationNumber
      taxFileNumber
      abnNumber
      wwccNumber
      wwccExpiry
      ahpraNumber
      prescriberNumber
      citizenshipStatus
      activeCampaignContactId
      activeCampaignPendingUpdate
      created
      updated
    }
  }
`;
export const getUserAuth0ById = /* GraphQL */ `
  query GetUserAuth0ById($pk: ID!) {
    getUserAuth0ById(pk: $pk) {
      id
      useMfa
    }
  }
`;
export const getDocumentTypes = /* GraphQL */ `
  query GetDocumentTypes {
    getDocumentTypes {
      id
      pathName
      hasMany
      applicationSpecific
      info
      url
    }
  }
`;
export const getDocumentTypeById = /* GraphQL */ `
  query GetDocumentTypeById($pk: ID!) {
    getDocumentTypeById(pk: $pk) {
      id
      pathName
      hasMany
      applicationSpecific
      info
      url
    }
  }
`;
export const getDocuments = /* GraphQL */ `
  query GetDocuments {
    getDocuments {
      id
      userId
      documentName
      documentTypeId
      uploaded
      originalFileName
      contentType
      expires
      details
      updated
    }
  }
`;
export const getDocumentById = /* GraphQL */ `
  query GetDocumentById($pk: ID!) {
    getDocumentById(pk: $pk) {
      id
      userId
      documentName
      documentTypeId
      uploaded
      originalFileName
      contentType
      expires
      details
      updated
    }
  }
`;
export const getDocumentsForUser = /* GraphQL */ `
  query GetDocumentsForUser {
    getDocumentsForUser {
      id
      userId
      documentName
      documentTypeId
      uploaded
      originalFileName
      contentType
      expires
      details
      updated
    }
  }
`;
export const getApplicationTemplates = /* GraphQL */ `
  query GetApplicationTemplates {
    getApplicationTemplates {
      id
      name
      userId
      state
      sector
      hospital
      positionType
      level
      notes
      dueDate
      created
      updated
    }
  }
`;
export const getApplicationById = /* GraphQL */ `
  query GetApplicationById($pk: ID!) {
    getApplicationById(pk: $pk) {
      id
      name
      userId
      state
      sector
      hospital
      positionType
      level
      notes
      dueDate
      created
      updated
    }
  }
`;
export const getApplicationsForUser = /* GraphQL */ `
  query GetApplicationsForUser {
    getApplicationsForUser {
      id
      name
      userId
      state
      sector
      hospital
      positionType
      level
      notes
      dueDate
      created
      updated
    }
  }
`;
