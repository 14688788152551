import { useState, useEffect } from "react";
import MDEditor from "@uiw/react-md-editor";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
} from "@mui/material";

export type MarkdownDialogProps = {
    open: boolean;
    title: string;
    content: string;
    callback: (changed: boolean, markdown: string) => void;
};

export function MarkdownDialog({
    open,
    title,
    content,
    callback,
}: MarkdownDialogProps) {
    const maxInput = 2048;
    const [markdown, setMarkdown] = useState<string>();

    const markdownChanged = (info: string | undefined) => {
        var trimmed = info?.substring(0, maxInput);
        setMarkdown(trimmed ?? "");
    };

    useEffect(() => {
        setMarkdown(content);
    }, [content]);

    const handleCancel = () => {
        callback(false, content);
    };

    const handleConfirm = () => {
        callback(true, markdown ?? "");
    };

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            sx={{ alignSelf: "center", minWidth: "400px", minHeight: "500px" }}
        >
            <DialogContent>
                <Typography variant="h5" gutterBottom component="div">
                    {title}
                </Typography>
                <hr color="error" />
                <Box component="div" data-color-mode="light" sx={{ mt: 2 }}>
                    <MDEditor
                        value={markdown}
                        onChange={markdownChanged}
                        preview="edit"
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ mt: 0 }}>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleConfirm}>Update</Button>
            </DialogActions>
        </Dialog>
    );
}
