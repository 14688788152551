import { User as UserOidc } from "@auth0/auth0-spa-js";
import { User, UserInput } from "../../API";

export type ProfileSectionProps = {
    userInput: UserInput;
    gridItemWidth: number;
    setValue: (name: string, value: any) => void;
};

export const AreasOfSpecialization = [
    "CriticalCare",
    "Medical",
    "Surgical",
    "Psychiatry",
    "GP",
];

export function getUserInput(
    sub: string,
    email: string,
    user: User | undefined
): UserInput {

    if (!user) {
        const newUser: UserInput = {
            id: sub,
            email: email,
            phone: "",
            firstName: "",
            lastName: "",
            dateOfBirth: undefined,
            level: undefined,
            yearsOfExperience: undefined,
            currentJobTitle: undefined,
            currentJobType: undefined,
            seekingLevels: [],
            seekingSpecialities: [],
            bankName: undefined,
            bankBsb: undefined,
            bankAccountNumber: undefined,
            superannuationNumber: undefined,
            taxFileNumber: undefined,
            abnNumber: undefined,
            wwccNumber: undefined,
            wwccExpiry: undefined,
            ahpraNumber: undefined,
            prescriberNumber: undefined,
            citizenshipStatus: undefined,
            activeCampaignContactId: undefined,
            activeCampaignPendingUpdate: true,
        };
        return newUser;
    }
    const existingUser: UserInput = {
        id: sub,
        email: email,
        phone: user.phone,
        firstName: user.firstName,
        lastName: user.lastName,
        dateOfBirth: user.dateOfBirth,
        level: user.level,
        yearsOfExperience: user.yearsOfExperience,
        currentJobTitle: user.currentJobTitle,
        currentJobType: user.currentJobType,
        seekingLevels: user.seekingLevels,
        seekingSpecialities: user.seekingSpecialities,
        bankName: user.bankName,
        bankBsb: user.bankBsb,
        bankAccountNumber: user.bankAccountNumber,
        superannuationNumber: user.superannuationNumber,
        taxFileNumber: user.taxFileNumber,
        abnNumber: user.abnNumber,
        wwccNumber: user.wwccNumber,
        wwccExpiry: user.wwccExpiry,
        ahpraNumber: user.ahpraNumber,
        prescriberNumber: user.prescriberNumber,
        citizenshipStatus: user.citizenshipStatus,
        activeCampaignContactId: user.activeCampaignContactId,
        activeCampaignPendingUpdate: user.activeCampaignPendingUpdate,
    };
    return existingUser;
}

export function getUpdatedInput(input: UserInput, name: string, value: any) {
    const updated: UserInput = {
        ...input,
        [name]: value,
    };
    return updated;
}
