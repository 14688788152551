import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    FormLabel,
    DialogActions,
    MenuItem,
    Select,
    SelectChangeEvent,
    Tooltip,
} from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import { PageHeader } from "../common/pageHeader";
import { ApplicationEditMode } from "./applicationCommon";
import { UserContext } from "../../common/userContext";
import { Application, PositionType } from "../../API";
import { getNextMonthISODate } from "../../common/typeUtils";

const aDate = getNextMonthISODate();

const blank: Application = {
    __typename: "Application",
    id: "Blank",
    name: "Blank",
    state: "",
    sector: undefined,
    hospital: undefined,
    positionType: undefined,
    level: undefined,
    dueDate: aDate,
    notes: "Create an application without using a template",
    applicationSections: [],
    userId: "",
    created: 0,
    updated: 0,
};

function sortTemplates(templates: Application[]): Application[] {
    const sorted = [...templates].sort((a, b) => {
        const typeCompare = (
            a.positionType ? "" : String(a.positionType)
        ).localeCompare(b.positionType ? "" : String(b.positionType));
        if (typeCompare !== 0) return typeCompare;
        return a.name.localeCompare(b.name);
    });
    return sorted;
}

export type ApplicationAddProps = {
    open: boolean;
    handleClose: (event: any) => void;
};

export function ApplicationAdd(props: ApplicationAddProps) {
    const navigate = useNavigate();
    const contextData = useContext(UserContext);
    const allTemplates = sortTemplates(contextData.templates);
    const [positionType, setPositionType] = useState<PositionType | undefined>(
        PositionType.Permanent
    );

    const [templates, setTemplates] = useState<Application[]>([
        blank,
        ...allTemplates.filter((t) => t.positionType === positionType),
    ]);

    const [template, setTemplate] = useState<Application>(blank);

    const positionTypeChanged = (positionType: PositionType) => {
        let positionTypeTemplates = [
            blank,
            ...allTemplates.filter((t) => t.positionType === positionType),
        ];
        setTemplates(positionTypeTemplates);
        setTemplate(blank);
        setPositionType(positionType);
    };

    const templateChanged = (event: SelectChangeEvent<any>) => {
        setTemplate(
            templates.find((t) => t.id === event.target.value) ?? blank
        );
    };

    const handleCancel = (e) => {
        props.handleClose(e);
    };

    const handleSubmit = () => {
        if (!template || template.id === blank.id)
            navigate(
                `/applicationEdit/add/${
                    ApplicationEditMode[ApplicationEditMode.AddBlank]
                }`
            );
        else
            navigate(
                `/applicationEdit/${template.id}/${
                    ApplicationEditMode[ApplicationEditMode.AddFromTemplate]
                }`
            );
    };

    return (
        <Box sx={{ minWidth: "400px", maxWidth: "400px" }}>
            <PageHeader title="Create New Application Checklist" />
            <hr color="error" />
            <Box>
                <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
                    <FormLabel>Position Type</FormLabel>
                    <Select
                        name="positionType"
                        size="small"
                        value={positionType}
                        onChange={(e) =>
                            positionTypeChanged(
                                PositionType[e.target.value ?? "Permanent"]
                            )
                        }
                        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                    >
                        {Object.values(PositionType).map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>

                    <Tooltip title="Choose a template to base your application on or select the blank one to create an application from scratch">
                        <FormLabel sx={{ mt: 2 }}>
                            Available Checklist Templates
                        </FormLabel>
                    </Tooltip>
                    <Select
                        name="templateId"
                        size="small"
                        value={template?.id}
                        onChange={templateChanged}
                    >
                        {templates.map((next) => (
                            <MenuItem value={next.id} key={next.id}>
                                {/*next.name === blankTemplate ? blankTemplate : `${next.type} - ${next.name}`*/}
                                {next.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                {template?.notes && (
                    <Box
                        sx={{
                            mt: 2,
                            display: "flex",
                            flexDirection: "column",
                            //height: "200px",
                        }}
                    >
                        <Box
                            component="div"
                            data-color-mode="light"
                            sx={{ mt: 1 }}
                        >
                            <MDEditor
                                value={template?.notes ?? ""}
                                maxHeight={190}
                                preview="preview"
                                hideToolbar={true}
                                style={{
                                    // padding: "5px",
                                    flex: "1 auto",
                                }}
                            />
                        </Box>
                    </Box>
                )}

                <DialogActions sx={{ mt: 1 }}>
                    <Button variant="outlined" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSubmit}>
                        Continue
                    </Button>
                </DialogActions>
            </Box>
        </Box>
    );
}

export function ApplicationAddDialog(props: ApplicationAddProps) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogContent>
                <ApplicationAdd {...props} />
            </DialogContent>
        </Dialog>
    );
}
