import { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
} from "@mui/material";

export interface ConfirmDialogUnsavedChangesProps {
    showDialog: boolean;
    cancelNavigation: () => void;
    confirmNavigation: (boolean) => void;
}

export function ConfirmDialogUnsavedChanges({
    showDialog,
    cancelNavigation,
    confirmNavigation,
}: ConfirmDialogUnsavedChangesProps) {
    const [confirmed, setConfirmed] = useState<boolean>(false);

    useEffect(() => {
        setConfirmed(false);
    }, [showDialog]);

    const onConfirmed = (saveFirst:boolean) => {
        setConfirmed(true);
        confirmNavigation(saveFirst);
    };

    return (
        <Dialog open={showDialog && confirmed === false} sx={{ alignSelf: "center" }}>
            <DialogContent>
                <Typography variant="h5" gutterBottom component="div">
                    Unsaved Changes
                </Typography>
                <hr color="error" />
                <DialogContentText>
                    There are unsaved changes to your application which will be
                    lost if you continue. Would you like to save your changes
                    first?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => onConfirmed(true)}
                >
                    Yes
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => onConfirmed(false)}
                >
                    No
                </Button>
                <Button variant="outlined" onClick={cancelNavigation}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
