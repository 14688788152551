import { useState } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";
import { PageHeader } from "../common/pageHeader";
import { PageInfo } from "../common/pageInfo";
import { getDocumentTypeName } from "./applicationCommon";
import { getErrorMessage } from "../../common/errorUtils";
import { Document, DocumentType } from "../../API";

export type ApplicationSectionDocumentEditProps = {
    open: boolean;
    applicationDocumentId: string;
    documentType: DocumentType;
    selected: Document | undefined;
    available: Document[];
    callback: (
        applicationDocumentId: string,
        document: Document | undefined
    ) => void;
    handleClose: (event: any) => void;
};

function ApplicationSectionDocumentEdit({
    applicationDocumentId,
    documentType,
    selected,
    available,
    callback,
    handleClose,
}: ApplicationSectionDocumentEditProps) {
    const documentTypeName = getDocumentTypeName(documentType);
    const [pageError, setPageError] = useState<string>("");
    const [replacement, setReplacement] = useState<Document | undefined>();

    const handleCancel = (event) => {
        handleClose(selected);
    };

    const handleSelect = async () => {
        if (!replacement) {
            setPageError(
                getErrorMessage(
                    "selecting a document",
                    "Please select a document first!"
                )
            );
            return;
        }
        callback(applicationDocumentId, replacement);
    };

    return (
        <Box sx={{ minWidth: "400px", maxWidth: "400px" }}>
            <PageHeader title="Replace Linked Document" />
            <hr color="error" />
            <PageInfo message={pageError} color="error" />

            <Typography>Currently linked document</Typography>

            <Paper>
                <List dense>
                    <ListItemButton disabled>
                        <ListItemText>
                            {selected
                                ? selected.documentName
                                : "There is currently no linked document for this item"}
                        </ListItemText>
                    </ListItemButton>
                </List>
            </Paper>

            <Typography sx={{ mt: 1 }}>Replace With</Typography>

            {available.length > 0 && (
                <>
                    <Paper style={{ maxHeight: 150, overflow: "auto" }}>
                        <List dense>
                            {available.map((row, index) => (
                                <ListItemButton
                                    key={index}
                                    onClick={() => {
                                        setPageError("");
                                        setReplacement(row);
                                    }}
                                    selected={replacement?.id === row.id}
                                >
                                    <ListItemText
                                        primary={row.documentName}
                                        secondary={documentTypeName}
                                        primaryTypographyProps={{
                                            display: "inline",
                                            ml: 1,
                                            fontWeight:
                                                replacement?.id === row.id
                                                    ? "bold"
                                                    : undefined,
                                        }}
                                        secondaryTypographyProps={{
                                            display: "inline",
                                            //fontStyle: "italic",
                                            ml: 1,
                                        }}
                                    />
                                </ListItemButton>
                            ))}
                        </List>
                    </Paper>
                </>
            )}

            {available.length === 0 && (
                <Paper>
                    <List dense>
                        <ListItemButton disabled>
                            <ListItemText>
                                {`There are no ${ selected ? "additional" : "" } '${documentTypeName}' documents available. Add one via the My Documents page`}
                            </ListItemText>
                        </ListItemButton>
                    </List>
                </Paper>
            )}

            <DialogActions sx={{ mt: 1 }}>
                <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSelect}
                    disabled={available.length === 0}
                >
                    Replace
                </Button>
            </DialogActions>
        </Box>
    );
}

export function ApplicationSectionDocumentEditDialog(
    props: ApplicationSectionDocumentEditProps
) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogContent>
                <ApplicationSectionDocumentEdit {...props} />
            </DialogContent>
        </Dialog>
    );
}
