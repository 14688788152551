import { Document, DocumentType } from "../API";
import { now, uniq } from "lodash";
import { isPossiblePhoneNumber } from "libphonenumber-js";

export const pathSeparator = "/";
export const pathRegex = "^(\\/[a-zA-Z0-9 ]+)+$";
export const rootRegex = "^\\/([a-zA-Z0-9 ]+)$";

export type PathName = {
    path: string;
    name: string;
};

export function formatPathName(pathName: string): string {
    return pathName
        .split(pathSeparator)
        .filter((v) => v !== "")
        .join(" - ");
}

export function isValidPathName(pathName: string): boolean {
    const match = pathName.match(pathRegex);
    if (!match) return false;
    return match.length === 2;
}

export function parsePathName(pathName: string): PathName {
    const match = pathName.match(pathRegex);
    if (!match || match.length !== 2)
        throw new Error(`Path name '${pathName}' is invalid!`);
    return {
        path: match[0].substring(0, match[0].length - match[1].length),
        name: match[1].substring(1),
    };
}

export function tryParsePath(pathName: string): string | undefined {
    try {
        if (!isValidPathName(pathName)) return undefined;
        const path = parsePathName(pathName).path;
        if (path === "") return undefined;
        return path;
    } catch (error) {
        return undefined;
    }
}

export function getDocumentTypePaths(documentTypes: DocumentType[]): string[] {
    var paths: string[] = [];
    for (let documentType of documentTypes) {
        if (documentType.hasMany) {
            paths = [...paths, documentType.pathName];
        } else {
            const path = tryParsePath(documentType.pathName);
            if (path) paths = [...paths, path];
        }
    }
    return uniq(paths).sort();
}

export function getDocumentGroups(documentTypes: DocumentType[]): string[] {
    // Just the first folder level of the path used as potential groupings for application checklist
    var paths: string[] = [];
    for (let documentType of documentTypes) {
        if (documentType.hasMany === false) continue;
        const isRootCategory = documentType.pathName.match(rootRegex);
        if (!isRootCategory || isRootCategory.length !== 2) continue;
        paths = [...paths, isRootCategory[1]];
    }
    return uniq(paths).sort();
}

export function getDocumentGroup(
    document: Document,
    documentTypes: DocumentType[]
): string {
    // Just the first folder level of the path used as potential groupings for application checklist
    const documentType = documentTypes.find(
        (d) => d.id === document.documentTypeId
    );
    if (!documentType) return "Missing";
    const part = documentType.pathName
        .split(pathSeparator)
        .find((p) => p !== "");
    return part ?? "Missing";
}

export function sortDocumentsForMatching(documents: Document[]): Document[] {
    const sorted = [...documents].sort((a, b) => {
        const typeCompare = a.documentTypeId.localeCompare(b.documentTypeId);
        if (typeCompare !== 0) return typeCompare;
        let expiresCompare = 0;
        try {
            const noExpiry = Number.MAX_SAFE_INTEGER;
            const aExpires = a.expires ? Date.parse(a.expires) : noExpiry;
            const bExpires = b.expires ? Date.parse(b.expires) : noExpiry;
            expiresCompare =
                aExpires === bExpires ? 0 : aExpires < bExpires ? 1 : -1;
        } catch (error) {
            console.log(error);
        }
        if (expiresCompare !== 0) return expiresCompare;
        const updatedCompare =
            a.updated === b.updated ? 0 : a.updated > b.updated ? -1 : 1;
        if (updatedCompare !== 0) return updatedCompare;
        return a.documentName.localeCompare(b.documentName);
    });
    return sorted;
}

export function isValidName(name: string): boolean {
    return name !== "";
}

// export function sortFaqs(faqs: Faq[]): Faq[] {
//   const sorted = [...faqs].sort((a, b) => {
//     const compareCategory = a.category.localeCompare(b.category);
//     if (compareCategory !== 0) return compareCategory;
//     if (a.rank !== b.rank) return a.rank - b.rank;
//     const compareQuestion = a.question.localeCompare(b.question);
//     return compareQuestion;
//   });
//   return sorted;
// }

export function parseAndConvertToISODate(dateString: string): string {
    const date = new Date(Date.parse(dateString));
    return convertToISODate(date);
}

export function parseISOAndConvertToDate(
    dateString: string | null | undefined
): Date | null {
    if (!dateString) return null;
    const dateSeconds = Date.parse(dateString);
    return new Date(dateSeconds);
}

export function convertToISODate(date: Date): string {
    return date.toISOString().split("T")[0];
}

export function getNextYearISODate(): string {
    var date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    return convertToISODate(date);
}

export function getNextMonthISODate(): string {
    var date = new Date();
    date.setMonth(date.getMonth() + 1);
    return convertToISODate(date);
}

export function hasExpired(dateString: string | null | undefined): boolean {
    if (dateString === undefined || dateString === null) return false;
    var date = Date.parse(dateString);
    return date < now();
}

export function isValidISODate(dateString: string | null | undefined): boolean {
    try {
        if (dateString === undefined || dateString === null) return false;
        var date = Date.parse(dateString);
        return Number.isNaN(date) === false;
    } catch (error) {
        console.log("Error parsing date: " + dateString + " :" + error);
        return false;
    }
}

export function tryParseDateAndSetISOValue(
    dateValue: Date | string | null | undefined,
    callback: (value: string | null) => void
): void {
    if (!dateValue) {
        callback(null);
        return;
    }

    if (dateValue.toString() === "Invalid Date") {
        callback(null);
        return;
    }

    try {
        if (typeof dateValue === "string") {
            callback(parseAndConvertToISODate(dateValue));
        } else {
            callback(convertToISODate(dateValue as Date));
        }
    } catch (error) {
        console.log(error);
        return;
    }
}

export function isValidPhoneNumber(phone: string | undefined): boolean {
    if (!phone) return false;
    return isPossiblePhoneNumber(phone);
}

export function getPropertyDescriptor(
    obj: any,
    prop: string
): PropertyDescriptor | undefined {
    let desc;
    do {
        desc = Object.getOwnPropertyDescriptor(obj, prop);
    } while (!desc && (obj = Object.getPrototypeOf(obj)));
    return desc;
}

// https://keestalkstech.com/2021/10/having-fun-grouping-arrays-into-maps-with-typescript/

export function groupBy<K, V>(array: V[], grouper: (item: V) => K) {
    return array.reduce((store, item) => {
        var key = grouper(item);
        if (!store.has(key)) {
            store.set(key, [item]);
        } else {
            store.get(key)!.push(item);
        }
        return store;
    }, new Map<K, V[]>());
}

export function isDefined<T>(item: T | undefined | null): item is T {
    return !!item;
}

export function filterDefined<T>(items: (T | undefined | null)[]): T[] {
    return items.filter(isDefined);
}
