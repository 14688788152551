import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@mui/material";

import { PageHeader } from "./pageHeader";

export type ConfirmDialogProps = {
    open: boolean;
    title: string;
    description: string;
    action: string;
    cancel?: string;
    data: any;
    callback: (action: string, data: any, result: boolean) => void;
};

export const ConfirmDialogPropsClosed: ConfirmDialogProps = {
    open: false,
    title: "",
    description: "",
    action: "",
    data: undefined,
    callback: () => {},
};

export function ConfirmDialog({
    action,
    cancel,
    callback,
    data,
    description,
    open,
    title,
}: ConfirmDialogProps) {
    const handleCancel = () => {
        callback(action, data, false);
    };

    const handleConfirm = () => {
        callback(action, data, true);
    };

    return (
        <Dialog open={open} onClose={handleCancel} sx={{ alignSelf: "center" }}>
            <DialogContent sx={{ minWidth: "400px", maxWidth: "800px" }}>
                <PageHeader title={title} />
                <hr color="error" />
                <DialogContentText>{description}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ mr: 2, mb:1 }}>
                <Button onClick={handleCancel}>{cancel ?? 'Cancel'}</Button>
                <Button onClick={handleConfirm} variant="contained">
                    {action}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
