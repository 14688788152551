import { useState } from "react";
import { TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";

export type GridToolbarSearchProps = {
  search: (text: String) => void;
};

export function GridToolbarSearch({ search }: GridToolbarSearchProps) {
  const [searchText, setSearchText] = useState("");

  const handleSearchTextChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchText(event.target.value);
    search(event.target.value);
  };

  const handleClearSearchClicked = (event: any) => {
    event.stopPropagation();
    setSearchText("");
    search("");
  };

  return (
    // <Box
    //   color="primary"
    //   // sx={{
    //   //   p: 0.5,
    //   //   pb: 0,
    //   // }}
    // >
    <TextField
      variant="standard"
      value={searchText}
      onChange={handleSearchTextChanged}
      placeholder="Filter…"
      color="primary"
      inputProps={{ maxLength: 20 }}
      InputProps={{
        startAdornment: <SearchIcon fontSize="small" color="primary" />,
        color: "primary",
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            color="primary"
            style={{
              visibility: searchText ? "visible" : "hidden",
            }}
            onClick={handleClearSearchClicked}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
      // https://www.codegrepper.com/code-examples/whatever/customize+material+ui+textfield+underline+using+styled+component
      sx={{
        width: {
          xs: 1,
          sm: "auto",
        },
        // color: "primary",
        m: (theme) => theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          mr: 0.5,
        },
        "& .MuiInput-underline:before": {
          borderBottom: 1,
          borderColor: "divider",
        },
        "&& .MuiInput-underline:hover:before": {
          borderBottom: 2,
          borderColor: "divider",
        }
      }}
    />
  //  </Box>
  );
}
