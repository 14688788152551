import { useState, useEffect } from "react";
import {
    Box,
    FormControl,
    FormLabel,
    InputLabel,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Markdown from "markdown-to-jsx";
import { PageHeader } from "../common/pageHeader";
import { MarkdownDialog, MarkdownDialogProps } from "../common/markdownDialog";
import { ApplicationInput, QualificationLevel, PositionType } from "../../API";
import * as utils from "../../common/typeUtils";

function getUpdatedInput(
    input: ApplicationInput,
    name: string,
    value: string | undefined
) {
    const updateValue = value ?? "";
    const updated: ApplicationInput = {
        ...input,
        [name]: updateValue,
    };
    return updated;
}

export type TemplateDetailsProps = {
    template: ApplicationInput;
    onChanged: (ApplicationInput) => void;
    isValid: React.MutableRefObject<() => boolean>;
};

export function TemplateDetails({
    template,
    onChanged,
    isValid,
}: TemplateDetailsProps) {
    const closedProps: MarkdownDialogProps = {
        open: false,
        title: "Edit Notes",
        content: "",
        callback: (i, d) => {},
    };

    const [markdownProps, setMarkdownProps] = useState(closedProps);

    useEffect(() => {
        isValid.current = validate;
    });

    const setValue = (name: string, value: string | undefined) => {
        const updated = getUpdatedInput(template, name, value);
        onChanged(updated);
    };

    const editNotes = () => {
        let notes = template.notes ?? "";

        const editProps: MarkdownDialogProps = {
            open: true,
            title: "Edit Notes",
            content: notes,
            callback: editNotesCallback,
        };
        setMarkdownProps(editProps);
    };

    const editNotesCallback = (changed: boolean, notes: string) => {
        if (changed) {
            setValue("notes", notes);
        }
        setMarkdownProps(closedProps);
    };

    const validate: () => boolean = () => {
        if (!template.name) return false;
        if (!utils.isValidISODate(template.dueDate)) return false;
        return true;
    };

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <MarkdownDialog {...markdownProps} />
            <PageHeader title="Details" />

            <Box>
                <TextField
                    autoFocus
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin="dense"
                    inputProps={{ maxLength: 256 }}
                    name="name"
                    label="Template Name*"
                    value={template.name}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                    error={template.name ? false : true}
                    helperText={template.name ? "" : "required field"}
                />

                <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin="dense"
                >
                    <InputLabel id="leveLabel">Level</InputLabel>
                    <Select
                        labelId="leveLabel"
                        label="Level"
                        name="level"
                        value={template.level}
                        onChange={(e) =>
                            setValue(
                                e.target.name,
                                QualificationLevel[e.target.value ?? ""]
                            )
                        }
                        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                    >
                        {Object.values(QualificationLevel).map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin="dense"
                >
                    <InputLabel id="typeLabel">Type</InputLabel>
                    <Select
                        labelId="typeLabel"
                        label="Job Type"
                        name="positionType"
                        value={template.positionType}
                        onChange={(e) =>
                            setValue(
                                e.target.name,
                                PositionType[e.target.value ?? ""]
                            )
                        }
                        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                    >
                        {Object.values(PositionType).map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
                    <Box
                        sx={{
                            m: 0,
                            display: "flex",
                            flexDirection: "row",
                            verticalAlign: "center",
                        }}
                    >
                        <FormLabel>Template Information</FormLabel>
                        <Tooltip title="Add additional information regarding template here">
                            <IconButton
                                onClick={editNotes}
                                sx={{
                                    padding: "0",
                                    justifyContent: "center",
                                    ml: 1,
                                    color: "text.primary",
                                }}
                            >
                                <EditIcon sx={{ fontSize: "medium" }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {/* https://stackoverflow.com/questions/65815511/make-child-of-material-ui-grid-item-stretch-to-fit-the-remaining-height-of-the-p */}
                    <Box
                        data-color-mode="light"
                        component="div"
                        sx={{
                            mt: 1,
                            mb: 1,
                            // maxHeight: "300px",
                            border: "solid #CCCCCC 1px",
                            borderRadius: "5px",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Markdown
                            style={{
                                //maxHeight: "300px",
                                padding: "0px 5px 0px 5px",
                                //scroll: "auto",
                            }}
                        >
                            {template.notes ?? ""}
                        </Markdown>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
