import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Backdrop,
    Box,
    Button,
    Divider,
    Grid,
    CircularProgress,
    Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { callApi } from "../../common/apiUtils";
import { ConfirmDialogUnsavedChanges } from "../common/confirmDialogUnsavedChanges";
import { UserContext, UserWrapper } from "../../common/userContext";
import { useCallbackPrompt } from "../../common/useCallbackPrompt";
import { getErrorMessage } from "../../common/errorUtils";
import { PageHeader } from "../common/pageHeader";
import { PageInfo } from "../common/pageInfo";
import { TemplateDetails } from "./templateDetails";
import { ConfirmDialog, ConfirmDialogProps } from "../common/confirmDialog";
import {
    TemplateSectionEdit,
    TemplateDocumentData,
    TemplateSectionData,
} from "./templateSection";
import {
    TemplateSectionDialog,
    TemplateSectionProps,
} from "./templateSectionDialog";
import {
    Application,
    ApplicationInput,
    ApplicationSectionInput,
    ApplicationDocumentInput,
    DocumentType,
} from "../../API";
import {
    getNewApplicationAsInput,
    getExistingApplicationAsInput,
    ApplicationLookupResult,
} from "../../common/applicationUtils";
import { ContextDataInterface } from "../../common/userContext";
import { groupBy, filterDefined } from "../../common/typeUtils";
import {
    createApplicationTemplate,
    updateApplicationTemplate,
} from "../../graphql/mutations";
import { isEqual } from "lodash";
//import * as common from "../applications/applicationCommon";

const { v4: uuid } = require("uuid");

//const templateUserId = "00000000-0000-0000-0000-000000000000";

export const getApplicationById = /* GraphQL */ `
    query GetApplicationById($pk: ID!) {
        getApplicationById(pk: $pk) {
            id
            name
            userId
            state
            sector
            hospital
            positionType
            level
            notes
            dueDate
            created
            updated
            applicationSections {
                id
                applicationId
                name
                created
                updated
                applicationDocuments {
                    id
                    applicationSectionId
                    documentTypeId
                    documentId
                    created
                    updated
                }
            }
        }
    }
`;

async function getApplicationInput(
    user: UserWrapper,
    id: string | undefined
): Promise<ApplicationLookupResult> {
    if (!id) return getNewApplicationAsInput(true);
    return getExistingApplicationAsInput(user, id, false);
}

function getSectionData(
    applicationSection: ApplicationSectionInput,
    contextData: ContextDataInterface
) {
    let documentDatas: TemplateDocumentData[] = [];
    for (let d of applicationSection.applicationDocuments) {
        const documentType = contextData.documentTypes.find(
            (u) => u.id === d.documentTypeId
        );
        if (!documentType)
            // Was the document type deleted by admins?
            continue;
        const documentData: TemplateDocumentData = {
            applicationDocument: {
                isNew: false,
                applicationSectionId: applicationSection.id,
                id: d.id,
                documentTypeId: d.documentTypeId,
                documentId: d.documentId,
            },
            documentType: documentType,
        };
        documentDatas = [...documentDatas, documentData];
    }
    const sectionData: TemplateSectionData = {
        applicationSection: {
            id: applicationSection.id,
            applicationId: applicationSection.applicationId,
            isNew: false,
            name: applicationSection.name,
            applicationDocuments: [], // will be filled in later for any updates
        },
        documents: documentDatas,
    };
    return getUpdatedSection(
        sectionData.applicationSection.applicationId,
        sectionData.applicationSection.name,
        filterDefined(sectionData.documents.map((d) => d.documentType)),
        sectionData
    );
}

export function getNewTemplateDocument(
    applicationSectionId: string,
    documentType: DocumentType
) {
    const updated: ApplicationDocumentInput = {
        isNew: true,
        id: uuid(),
        applicationSectionId: applicationSectionId,
        documentTypeId: documentType.id,
        documentId: undefined,
    };
    const updatedData: TemplateDocumentData = {
        applicationDocument: updated,
        documentType: documentType,
    };
    return updatedData;
}

function getUpdatedSection(
    applicationId: string,
    sectionName: string,
    documentTypes: DocumentType[],
    existingData: TemplateSectionData | undefined
) {
    const existing = existingData?.applicationSection;
    const existingDocuments = existingData?.documents ?? [];
    const updated: ApplicationSectionInput = {
        isNew: existing?.isNew ?? true,
        id: existing?.id ?? uuid(),
        applicationId: applicationId,
        name: sectionName,
        applicationDocuments: [],
    };
    const documentTypesMap = groupBy(documentTypes, (d) => d.id);

    const documentsMap = groupBy(
        existingDocuments,
        (d) => d.applicationDocument.documentTypeId
    );

    let updatedDocuments: TemplateDocumentData[] = [];
    for (let [key, values] of documentTypesMap) {
        let unmatchedDocumentTypes: DocumentType[] = values;
        if (documentsMap.has(key)) {
            const existing = documentsMap.get(key)!;
            // Only take up to the required number of values, throw the rest away
            existing.splice(values.length);
            updatedDocuments = [...updatedDocuments, ...existing];
            unmatchedDocumentTypes = values.slice(existing.length, undefined);
        }
        for (let unmatched of unmatchedDocumentTypes) {
            updatedDocuments = [
                ...updatedDocuments,
                getNewTemplateDocument(updated.id, unmatched),
            ];
        }
    }

    if (updatedDocuments.length !== documentTypes.length)
        console.log("Logic error matching section documents");

    const updatedProps: TemplateSectionData = {
        applicationSection: updated,
        documents: updatedDocuments,
    };

    return updatedProps;
}

function getSectionInputs(
    applicationId: string,
    sectionDatas: TemplateSectionData[]
) {
    // Sorted for comparison
    return sectionDatas
        .sort((a, b) =>
            a.applicationSection.name.localeCompare(b.applicationSection.name)
        )
        .map((s) => {
            const documentInputs = s.documents
                .map((d) => d.applicationDocument)
                .sort((a, b) => a.id.localeCompare(b.id)); // getDocumentInput(s.applicationSection.id, d));
            return {
                ...s.applicationSection,
                applicationId: applicationId,
                applicationDocuments: documentInputs,
            } as ApplicationSectionInput;
        });
}

function hasChanges(
    orig: ApplicationInput,
    updatedDetails: ApplicationInput,
    updatedSections: TemplateSectionData[]
) {
    const updatedSectionInputs = getSectionInputs(
        updatedDetails.id,
        updatedSections
    );
    const updatedCombined = {
        ...updatedDetails,
        applicationSections: updatedSectionInputs,
    };
    const hasChanges = isEqual(orig, updatedCombined) === false;
    return hasChanges;
}

export default function EditTemplate() {
    const { id } = useParams();
    const isNew = id === "add";
    const navigate = useNavigate();
    const navigateAway = useRef(false);
    const validateDetailsRef = useRef<() => boolean>(() => false);
    const contextData = useContext(UserContext);
    const [orig, setOrig] = useState<ApplicationInput>({} as ApplicationInput);
    const [details, setDetails] = useState<ApplicationInput>();
    const [pageError, setPageError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [backdropOpen, setBackdropOpen] = useState<boolean>(true);
    const [sections, setSections] = useState<TemplateSectionData[]>([]);
    const [navigateRequest, setNavigateRequest] = useState(new Date());
    const [unsavedChanges, setUnsavedChanges] = useState(isNew);
    const [templateSectionProps, setTemplateSectionProps] =
        useState<TemplateSectionProps>({
            open: false,
            sectionName: undefined,
            selected: [],
            takenSections: [],
            callback: (r, d) => {},
            handleClose: (e) => {},
        });
    const [showUnsavedChangesPrompt, confirmNavigation, cancelNavigation] =
        useCallbackPrompt(unsavedChanges);
    const [confirmDialogProps, setConfirmDialogProps] =
        useState<ConfirmDialogProps>({
            open: false,
            title: "",
            description: "",
            action: "",
            data: "",
            callback: () => {},
        });

    function getSection(name: string) {
        return sections.find((s) => s.applicationSection.name === name);
    }

    function getSectionsExcept(name: string) {
        return [...sections.filter((s) => s.applicationSection.name !== name)];
    }

    const getUpdatedApplication = () => {
        const valid = validateDetailsRef.current();
        if (!details || !valid) return undefined;
        const sectionInputs = getSectionInputs(details.id, sections);
        return {
            ...details,
            applicationSections: sectionInputs,
        };
    };

    function updateUnsavedChanges(
        details: ApplicationInput | undefined,
        sections: TemplateSectionData[]
    ) {
        if (!details) return;
        if (isNew) return;
        const hasUnsavedChanges = hasChanges(orig, details, sections);
        setUnsavedChanges(hasUnsavedChanges);
    }

    const confirmNavigationWithSave = async (saveFirst: boolean) => {
        if (saveFirst) await updateChecklist(false); // THis will navigate after the save
        confirmNavigation();
    };

    const onDetailsChanged = (details: ApplicationInput) => {
        setDetails(details);
        updateUnsavedChanges(details, sections);
    };

    useEffect(() => {
        const getApplicationTemplate = async () => {
            const result = await getApplicationInput(
                contextData.user,
                isNew ? undefined : id
            );
            if (!result.applicationInput) {
                setBackdropOpen(false);
                setPageError(
                    getErrorMessage("retrieving the template", result.error)
                );
                return;
            }
            setOrig(result.applicationInput);
            setDetails(result.applicationInput);
            const sectionDatas =
                result.applicationInput.applicationSections.map((s) =>
                    getSectionData(s, contextData)
                );
            setSections(sectionDatas);
            setBackdropOpen(false);
        };
        if (loading) {
            setLoading(false);
            getApplicationTemplate();
        }
    }, [contextData, id, sections, setSections, isNew, loading]);

    useEffect(() => {
        if (navigateAway.current && unsavedChanges === false) {
            navigate("/templateAdmin");
        }
    }, [unsavedChanges, navigateRequest, navigate]);

    const navigateToList = () => {
        // Use effect above waits for unsavedChanges to be set to turn off the confirm dialog before navigating
        navigateAway.current = true;
        setUnsavedChanges(false);
        setNavigateRequest(new Date());
    };

    const updateChecklist = async (navigateAfterSave: boolean) => {
        const updated = getUpdatedApplication();
        if (!updated) return;
        setBackdropOpen(true);
        const [operation, operationName] = isNew
            ? [createApplicationTemplate, "createApplicationTemplate"]
            : [updateApplicationTemplate, "updateApplicationTemplate"];
        const updatedApplication = await callApi<Application>(
            contextData.user,
            operationName,
            {
                query: operation,
                variables: { item: updated },
            }
        );
        if (!updatedApplication.Result) {
            setPageError(
                getErrorMessage(
                    "updating the template",
                    updatedApplication.Error
                )
            );
            setBackdropOpen(false);
            return;
        }
        if (isNew) {
            contextData.setTemplates([
                ...contextData.templates,
                updatedApplication.Result,
            ]);
        } else {
            const except = contextData.templates.filter(
                (r) => r.id !== updatedApplication.Result?.id
            );
            contextData.setTemplates([...except, updatedApplication.Result]);
        }
        setBackdropOpen(false);
        if (navigateAfterSave) navigateToList();
    };

    const handleAddSection = () => {
        editSection(undefined);
    };

    const handleEditSection = (section: TemplateSectionData) => {
        editSection(section);
    };

    const editSection = (section: TemplateSectionData | undefined) => {
        let editProps: TemplateSectionProps = {
            open: true,
            sectionName: section?.applicationSection.name,
            selected: section
                ? filterDefined(section.documents.map((d) => d.documentType))
                : [],
            takenSections: sections.map((s) => s.applicationSection.name),
            callback: handleTemplateSectionDialogResult,
            handleClose: (e) =>
                setTemplateSectionProps({
                    ...templateSectionProps,
                    open: false,
                    sectionName: "",
                    selected: [],
                }),
        };
        setTemplateSectionProps(editProps);
    };

    const handleDeleteSection = (section: TemplateSectionData) => {
        setConfirmDialogProps({
            ...confirmDialogProps,
            open: true,
            title: "Delete Document Group",
            action: "Delete",
            data: section.applicationSection.name,
            description: `Are you sure that you would like to delete the document group ${section.applicationSection.name}?`,
            callback: handleDeleteSectionConfirmResult,
        });
    };

    const handleDeleteSectionConfirmResult = (
        action: string,
        data: any,
        result: boolean
    ) => {
        setConfirmDialogProps({
            ...confirmDialogProps,
            open: false,
            data: "",
            description: "",
        });

        if (!result || action !== "Delete" || !data) return;

        deleteSection(data as string);
    };

    const deleteSection = (name: string) => {
        const sectionsUpdate = getSectionsExcept(name);
        setSections(sectionsUpdate);
        updateUnsavedChanges(details, sectionsUpdate);
    };

    const handleTemplateSectionDialogResult = (
        updated: boolean,
        sectionName: string,
        selection: DocumentType[]
    ) => {
        setTemplateSectionProps({
            ...templateSectionProps,
            open: false,
            sectionName: "",
            selected: [],
        });
        if (!updated || !sectionName) return;
        const existing = getSection(sectionName);
        if (selection.length === 0) {
            if (!existing) return;
            deleteSection(existing.applicationSection.name);
            return;
        }
        const updatedSection = getUpdatedSection(
            id!,
            sectionName,
            selection,
            existing
        );
        const except = getSectionsExcept(sectionName);
        const updatedSections = [...except, updatedSection].sort((a, b) =>
            a.applicationSection.name.localeCompare(b.applicationSection.name)
        );
        setSections(updatedSections);
        updateUnsavedChanges(details, updatedSections);
    };

    if (!id)
        return (
            <Typography variant="h6" gutterBottom component="div" color="error">
                The requested template could not be found
            </Typography>
        );

    if (contextData.user.admin === false) return <div />;

    return (
        <Grid
            container
            spacing={0}
            sx={{ mt: 0, height: "100%", width: "100%" }}
        >
            <ConfirmDialogUnsavedChanges
                showDialog={showUnsavedChangesPrompt}
                confirmNavigation={confirmNavigationWithSave}
                cancelNavigation={cancelNavigation}
            />
            <ConfirmDialog {...confirmDialogProps} />
            <TemplateSectionDialog {...templateSectionProps} />
            <Grid item xs={8}>
                {details && (
                    <PageHeader
                        title={
                            isNew
                                ? `New template`
                                : `Template for ${details.level} ${
                                      details.positionType
                                  } Role${unsavedChanges ? "*" : ""}`
                        }
                    />
                )}
                <PageInfo message={pageError} color="error" />
                <Divider
                    orientation="horizontal"
                    flexItem
                    sx={{ ml: "3px", mr: "3px" }}
                />
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleAddSection}
                    sx={{mt: 1}}
                >
                    ADD NEW DOCUMENT GROUP
                </Button>
                {sections.length === 0 ? (
                    <Typography
                        sx={{
                            color: "text.secondary",
                            fontSize: "smaller",
                            mt: 2,
                            ml: 1,
                        }}
                    >
                        It seems there is nothing here at the moment. Add a new
                        document grouping to begin building a new checklist.
                    </Typography>
                ) : (
                    <Box
                        sx={{ display: "flex", flexDirection: "column", mt: 2 }}
                    >
                        {sections.map((value, index) => (
                            <TemplateSectionEdit
                                key={index}
                                data={value}
                                editSection={() => handleEditSection(value)}
                                deleteSection={() => handleDeleteSection(value)}
                            />
                        ))}
                    </Box>
                )}
            </Grid>
            <Grid item xs={4}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                    }}
                >
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ ml: "5px", mr: "5px" }}
                    />
                    {details && (
                        <TemplateDetails
                            template={details}
                            onChanged={onDetailsChanged}
                            isValid={validateDetailsRef}
                        />
                    )}
                </Box>
            </Grid>
            <Grid item xs={12} style={{ padding: "2px 16px" }}>
                <Box
                    sx={{
                        mt: 0,
                        with: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button variant="outlined" onClick={navigateToList}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => updateChecklist(true)}
                        sx={{ ml: 1 }}
                    >
                        Save
                    </Button>
                </Box>
            </Grid>

            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    );
}
