import { Sector } from "../API"

export type hospital = {
    state: string,
    sector: Sector,
    location: string,
    name: string,
};

export const states : string[] = [
	"Australian Capital Territory",
	"New South Wales",
	"Northern Territory",
	"South Australia",
	"Tasmania",
	"Victoria", 
	"Western Australia",
];

export const actPublic : hospital[] = [
	{
		state:"Australian Capital Territory",
		sector:Sector.Public,
		location:"Bruce",
		name:"Calvary Public Hospital",
	},
	{
		state:"Australian Capital Territory",
		sector:Sector.Public,
		location:"Garran",
		name:"The Canberra Hospital",
	},
	{
		state:"Australian Capital Territory",
		sector:Sector.Public,
		location:"Curtin",
		name:"Queen Elizabeth II Family Centre",
	},
	{
		state:"Australian Capital Territory",
		sector:Sector.Public,
		location:"Belconnen",
		name:"University of Canberra Hospital",
	},
];

export const actPrivate : hospital[] = [
	{
		state:"Australian Capital Territory",
		sector:Sector.Private,
		location:"Garran",
		name:"Brindabella Endoscopy and Day Surgery Centre",
	},
	{
		state:"Australian Capital Territory",
		sector:Sector.Private,
		location:"Deakin",
		name:"Calvary John James Hospital",
	},
	{
		state:"Australian Capital Territory",
		sector:Sector.Private,
		location:"Bruce",
		name:"Calvary Private Hospital Bruce",
	},
	{
		state:"Australian Capital Territory",
		sector:Sector.Private,
		location:"Deakin",
		name:"Canberra Imaging Group Angiography/Interventional Suite",
	},
	{
		state:"Australian Capital Territory",
		sector:Sector.Private,
		location:"Garran",
		name:"National Capital Private Hospital",
	},
];

export const nswPublic : hospital[] = [
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Albury",
		name:"Albury Wodonga Health (Albury Campus)",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Armidale",
		name:"Armidale Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Auburn",
		name:"Auburn Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Ballina",
		name:"Ballina Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Balmain",
		name:"Balmain Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Balranald",
		name:"Balranald Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Bankstown",
		name:"Bankstown Lidcombe Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Baradine",
		name:"Baradine Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Barham",
		name:"Barham Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Barraba",
		name:"Barraba Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Batemans Bay",
		name:"Batemans Bay Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Bathurst",
		name:"Bathurst Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Batlow",
		name:"Batlow/Adelong Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Bellingen",
		name:"Bellinger River District Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Belmont",
		name:"Belmont Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Berrigan",
		name:"Berrigan Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Bingara",
		name:"Bingara Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Blacktown",
		name:"Blacktown Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Blayney",
		name:"Blayney Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Katoomba",
		name:"Blue Mountains District ANZAC Memorial Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Boggabri",
		name:"Boggabri Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Bombala",
		name:"Bombala Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Bonalbo",
		name:"Bonalbo Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Boorowa",
		name:"Boorowa Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Bourke",
		name:"Bourke Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Goulburn",
		name:"Bourke Street Health Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Bowral",
		name:"Bowral and District Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Prairiewood",
		name:"Braeside Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Braidwood",
		name:"Braidwood Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Brewarrina",
		name:"Brewarrina Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Broken Hill",
		name:"Broken Hill Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Bulahdelah",
		name:"Bulahdelah Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Bulli",
		name:"Bulli Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Byron Bay",
		name:"Byron Bay Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Kogarah",
		name:"Calvary Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Waratah",
		name:"Calvary Mater Newcastle Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Camden",
		name:"Camden Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Campbelltown",
		name:"Campbelltown Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Canowindra",
		name:"Canowindra Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Campsie",
		name:"Canterbury Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Casino",
		name:"Casino Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Cessnock",
		name:"Cessnock Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"",
		name:"Children's Hospital at Westmead",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Cobar",
		name:"Cobar Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Coffs Harbour",
		name:"Coffs Harbour Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Coledale",
		name:"Coledale Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Collarenebri",
		name:"Collarenebri Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Concord",
		name:"Concord Repatriation General Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Condobolin",
		name:"Condobolin Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Coolah",
		name:"Coolah Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Coolamon",
		name:"Coolamon Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Cooma",
		name:"Cooma Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Coonabarabran",
		name:"Coonabarabran Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Coonamble",
		name:"Coonamble Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Cootamundra",
		name:"Cootamundra Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Coraki",
		name:"Coraki Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"North Ryde",
		name:"Coral Tree Family Centre",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Corowa",
		name:"Corowa Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Cowra",
		name:"Cowra Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Crookwell",
		name:"Crookwell Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Cudal",
		name:"Cudal Health Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Culcairn",
		name:"Culcairn Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Westmead",
		name:"Cumberland Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Berry",
		name:"David Berry Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Delegate",
		name:"Delegate Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Deniliquin",
		name:"Deniliquin Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Denman",
		name:"Denman Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Dorrigo",
		name:"Dorrigo Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Dubbo",
		name:"Dubbo Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Dunedoo",
		name:"Dunedoo Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Dungog",
		name:"Dungog Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Eugowra",
		name:"Eugowra Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Prairiewood",
		name:"Fairfield Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Finley",
		name:"Finley Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Forbes",
		name:"Forbes Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Forster (opening soon)",
		name:"Forster-Tuncurry District Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Gilgandra",
		name:"Gilgandra Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Glen Innes",
		name:"Glen Innes Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Gloucester",
		name:"Gloucester Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Goodooga",
		name:"Goodooga Health Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Gosford",
		name:"Gosford Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Goulburn",
		name:"Goulburn Base Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Lord Howe Island",
		name:"Gower Wilson Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Grafton",
		name:"Grafton Base Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Greenwich",
		name:"Greenwich Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Grenfell",
		name:"Grenfell Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Griffith",
		name:"Griffith Base Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Gulargambone",
		name:"Gulargambone Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Gulgong",
		name:"Gulgong Health Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Gundagai",
		name:"Gundagai Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Gunnedah",
		name:"Gunnedah Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Guyra",
		name:"Guyra Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Windsor",
		name:"Hawkesbury Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Hay",
		name:"Hay Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Henty",
		name:"Henty Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Hillston",
		name:"Hillston Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Holbrook",
		name:"Holbrook Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Hornsby",
		name:"Hornsby Ku-ring-gai Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Newcastle",
		name:"Hunter New England Mater Mental Health Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"",
		name:"Illawarra Mental Health Services",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Inverell",
		name:"Inverell Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Ivanhoe",
		name:"Ivanhoe Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Jerilderie",
		name:"Jerilderie Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"New Lambton",
		name:"John Hunter Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Junee",
		name:"Junee Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Malabar",
		name:"Justice Health Services",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Carramar",
		name:"Karitane Mothercraft Society",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Kempsey",
		name:"Kempsey Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Kenmore",
		name:"Kenmore Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Kiama",
		name:"Kiama Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Kurri Kurri",
		name:"Kurri Kurri Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Kyogle",
		name:"Kyogle Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Lake Cargelligo",
		name:"Lake Cargelligo Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Leeton",
		name:"Leeton Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Lightning Ridge",
		name:"Lightning Ridge Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Lismore",
		name:"Lismore Base Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Lithgow",
		name:"Lithgow Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Liverpool",
		name:"Liverpool Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Lockhart",
		name:"Lockhart Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Killarney Vale",
		name:"Long Jetty Health Care Centre",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Dubbo",
		name:"Lourdes Hospital Dubbo",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Macksville",
		name:"Macksville Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Maclean",
		name:"Maclean Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"North Ryde",
		name:"Macquarie Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Maitland",
		name:"Maitland Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Manilla",
		name:"Manilla Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Manly",
		name:"Manly Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Taree",
		name:"Manning Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Menindee",
		name:"Menindee Health Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"",
		name:"Mercy Care Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Merriwa",
		name:"Merriwa Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Milton",
		name:"Milton Ulladulla Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Molong",
		name:"Molong Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Mona Vale",
		name:"Mona Vale Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Moree",
		name:"Moree Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Morisset",
		name:"Morisset Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Moruya",
		name:"Moruya Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Mount Druitt",
		name:"Mount Druitt Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Mudgee",
		name:"Mudgee Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Mullumbimby",
		name:"Mullumbimby Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Harden",
		name:"Murrumburrah-Harden Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Murwillumbah",
		name:"Murwillumbah Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Muswellbrook",
		name:"Muswellbrook Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Narrabri",
		name:"Narrabri Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Narrandera",
		name:"Narrandera Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Narromine",
		name:"Narromine Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Kingswood",
		name:"Nepean Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Wahroonga",
		name:"Neringah Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Nimbin",
		name:"Nimbin Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Frenchs Forest",
		name:"Northern Beaches Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Nyngan",
		name:"Nyngan Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Oberon",
		name:"Oberon Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Orange",
		name:"Orange Health Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Pambula",
		name:"Pambula Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Parkes",
		name:"Parkes Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Peak Hill",
		name:"Peak Hill Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Warrawong",
		name:"Port Kembla Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Port Macquarie",
		name:"Port Macquarie Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Portland",
		name:"Portland Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Randwick",
		name:"Prince of Wales Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Queanbeyan",
		name:"Queanbeyan District Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Quirindi",
		name:"Quirindi Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Concord West",
		name:"Rivendell Child, Adolescent and Family Unit",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Lismore",
		name:"Riverlands Drug and Alcohol Centre",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Randwick",
		name:"Royal Hospital for Women",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"St Leonards",
		name:"Royal North Shore Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Camperdown",
		name:"Royal Prince Alfred Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Camperdown",
		name:"Royal Prince Alfred Institute of Rheumatology & Orthopaedics",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Ryde",
		name:"Royal Rehabilitation Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Eastwood",
		name:"Ryde Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Rylstone",
		name:"Rylstone Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Darlinghurst",
		name:"Sacred Heart Hospice",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Scone",
		name:"Scone Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Mount Warrigal",
		name:"Shellharbour Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Nowra",
		name:"Shoalhaven District Memorial Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Singleton",
		name:"Singleton District Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Bega",
		name:"South East Regional Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Springwood",
		name:"Springwood Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Kogarah",
		name:"St George Hospital NSW",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Richmond",
		name:"St John of God Hospital Richmond",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Auburn",
		name:"St Joseph's Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Darlinghurst",
		name:"St Vincent's Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Caringbah",
		name:"Sutherland Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Randwick",
		name:"Sydney Children's Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Surry Hills",
		name:"Sydney Dental Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Sydney",
		name:"Sydney Hospital (oldest hospital in Australia, dating from 1788) / Sydney Eye Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Tamworth",
		name:"Tamworth Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Temora",
		name:"Temora Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Tenterfield",
		name:"Tenterfield Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Concord West",
		name:"Thomas Walker Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Tibooburra",
		name:"Tibooburra Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Tingha",
		name:"Tingha Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Tocumwal",
		name:"Tocumwal Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Nelson Bay",
		name:"Tomaree Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Tottenham",
		name:"Tottenham Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Trangie",
		name:"Trangie Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Belmore",
		name:"Tresillian Family Care Centre, Canterbury",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Kingswood",
		name:"Tresillian Family Care Centre, Kingswood",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Trundle",
		name:"Trundle Multi Purpose Health Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Tweed Heads",
		name:"The Tweed Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Tullamore",
		name:"Tullamore Multi Purpose Health Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Tumbarumba",
		name:"Tumbarumba Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Tumut",
		name:"Tumut Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Urana",
		name:"Urana Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Urbenville",
		name:"Urbenville Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Emmaville",
		name:"Vegetable Creek Multi Purpose Service Emmaville",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Wagga Wagga",
		name:"Wagga Wagga Calvary Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Wagga Wagga",
		name:"Wagga Wagga Rural Referral Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Walcha",
		name:"Walcha Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Walgett",
		name:"Walgett Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Waverley",
		name:"War Memorial Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Warialda",
		name:"Warialda Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Warren",
		name:"Warren Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Wauchope",
		name:"Wauchope Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Wee Waa",
		name:"Wee Waa Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Wellington",
		name:"Wellington Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Wentworth",
		name:"Wentworth Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Penrith",
		name:"Wentworth Psychiatric Services",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Werris Creek",
		name:"Werris Creek Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Westmead",
		name:"Westmead Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Wilcannia",
		name:"Wilcannia Multi Purpose Service",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Murrurundi",
		name:"Wilson Memorial Community Hospital, Murrurundi",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Wingham",
		name:"Wingham Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Wollongong",
		name:"Wollongong Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Woy Woy",
		name:"Woy Woy Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"West Wyalong",
		name:"Wyalong Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Hamlyn Terrace",
		name:"Wyong Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Concord West",
		name:"Yaralla Estate, also known as the Dame Edith Walker Estate",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Yass",
		name:"Yass Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Public,
		location:"Young",
		name:"Young Hospital",
	},
];

export const nswPrivate : hospital[] = [
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Kogarah",
		name:"Aesthetic Day Surgery",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"West Albury",
		name:"Albury Wodonga Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Dundas",
		name:"Allowah Presbyterian Children's Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Armidale",
		name:"Armidale Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Ballina",
		name:"Ballina Day Surgery",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Coffs Harbour",
		name:"Baringa Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Bathurst",
		name:"Bathurst Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Bega",
		name:"Bega Valley Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Berkeley Vale",
		name:"Berkeley Vale Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Woy Woy",
		name:"Brisbane Waters Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Wagga Wagga",
		name:"Calvary Health Care Riverina",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"",
		name:"Camden Haven Medical Center (Laurieton Hospital)",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Campbelltown",
		name:"Campbelltown Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Castle Hill",
		name:"Castle Hill Day Surgery",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Castlecrag",
		name:"Castlecrag Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Erina",
		name:"Central Coast Day Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Five Dock",
		name:"Centre for Digestive Diseases",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Coffs Harbour",
		name:"Coffs Harbour Day Surgery",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Port Macquarie",
		name:"Coolenberg Day Surgery",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Crows Nest",
		name:"Crows Nest Day Surgery",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Dee Why",
		name:"Dee Why Endoscopy Unit",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Darlinghurst",
		name:"Diagnostic Endoscopy Centre",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Orange",
		name:"Dudley-Orange Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Randwick",
		name:"Eastern Heart Clinic",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Epping",
		name:"Epping Surgery Centre",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Figtree",
		name:"Figtree Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Forster",
		name:"Forster Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Port Macquarie",
		name:"Hastings Day Surgery",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Kellyville",
		name:"The Hills Clinic Kellyville",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Baulkham Hills",
		name:"The Hills Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Shortland",
		name:"Hunter Valley Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Hunters Hill",
		name:"Hunters Hill Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Hurstville",
		name:"Hurstville Private",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Caringbah",
		name:"Kareena Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"North Turramurra",
		name:"Lady Davidson Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Gateshead",
		name:"Lake Macquarie Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Thirroul",
		name:"Lawrence Hargrave Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Chipping Norton",
		name:"Liverpool Day Surgery",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Macquarie University",
		name:"Macquarie University Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"North Sydney",
		name:"Mater Hospital Sydney",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Petersham",
		name:"MetroRehab Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Blacktown",
		name:"Metwest Surgical",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Miranda",
		name:"Miranda Eye Surgical Centre",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Mogo",
		name:"Mogo Day Surgery",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Mosman",
		name:"Mosman Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Hornsby",
		name:"Mount Wilga Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Kingswood",
		name:"Nepean Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"New Lambton Heights",
		name:"Newcastle Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"St Leonards",
		name:"North Shore Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Greenwich",
		name:"Northside Clinic",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Cremorne",
		name:"Northside Cremorne Clinic",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Campbelltown",
		name:"Northside Macarthur Clinic",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Wentworthville",
		name:"Northside West Clinic",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Bella Vista",
		name:"Norwest Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Nowra",
		name:"Nowra Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Chatswood",
		name:"Ophthalmic Surgery Centre (North Shore)",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Orange",
		name:"Orange Day Surgery Centre",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Pennant Hills",
		name:"Pennant Hills Day Endoscopy Centre",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Hornsby",
		name:"Perfect Vision Day Surgery",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"",
		name:"Potentialz Unlimited, Clinical Psychologies in Sydney",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Port Macquarie",
		name:"Port Macquarie Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Randwick",
		name:"Prince of Wales Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"",
		name:"Radiation Oncology Institute",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"",
		name:"Radiation Oncology Institute",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Hornsby",
		name:"San Day Surgery Hornsby",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Westmead",
		name:"The Skin & Cancer Foundation Australia",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Curl Curl",
		name:"South Pacific Private",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Bowral",
		name:"Southern Highlands Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Kogarah",
		name:"St George Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Burwood",
		name:"St John of God Burwood Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"North Richmond",
		name:"St John of God Hospital Richmond",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Potts Point",
		name:"St Luke's Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Darlinghurst",
		name:"St Vincent's Private Sydney",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Lismore",
		name:"St Vincents Private Hospital ",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Strathfield",
		name:"Strathfield Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Hurstville",
		name:"The Surgery Centre Hurstville",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Caringbah",
		name:"Sutherland Heart Clinic",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Wahroonga",
		name:"Sydney Adventist Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Bronte",
		name:"The Sydney Clinic",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"",
		name:"Sydney Day Surgery",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Ashfield",
		name:"The Sydney Private Hospital Incorporating the NSW Eye Centre",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Liverpool",
		name:"Sydney South West Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Tamworth",
		name:"Tamara Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Tweed Heads",
		name:"Tweed Day Surgery",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Ulladulla",
		name:"Ulladulla Endoscopy and Medical Centre",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Chatswood",
		name:"Vision Day Surgery Chatswood",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Hurstville",
		name:"Vision Day Surgery Hurstville",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Wagga Wagga",
		name:"Wagga Endoscopy Centre",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Warners Bay",
		name:"Warners Bay Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Ashfield",
		name:"Wesley Hospital Ashfield",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Kogarah",
		name:"Wesley Hospital Kogarah",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Westmead",
		name:"Western Sydney Oncology",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Westmead",
		name:"Westmead Private Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Merrylands",
		name:"Westmead Rehabilitation Hospital",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Wollongong",
		name:"Wollongong Day Surgery",
	},
	{
		state:"New South Wales",
		sector:Sector.Private,
		location:"Woollahra",
		name:"Wolper Jewish Hospital",
	},
];

export const ntPublic : hospital[] = [
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Alice Springs",
		name:"Alice Springs Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Nhulunbuy",
		name:"Gove District Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Katherine",
		name:"Katherine District Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Farrar",
		name:"Palmerston Regional Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Tiwi",
		name:"Royal Darwin Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Tennant Creek",
		name:"Tennant Creek Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"",
		name:Sector.Private,
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Tiwi",
		name:"Darwin Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"",
		name:"Queensland",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"",
		name:Sector.Public,
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Alpha",
		name:"Alpha Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Aramac",
		name:"Aramac Primary Healthcare Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Atherton",
		name:"Atherton Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Augathella",
		name:"Augathella Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Aurukun",
		name:"Aurukun Primary Health Care Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Ayr",
		name:"Ayr Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Babinda",
		name:"Babinda Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Badu Island",
		name:"Badu Island Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Toowoomba",
		name:"Baillie Henderson Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Bamaga",
		name:"Bamaga Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Baralaba",
		name:"Baralaba Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Barcaldine",
		name:"Barcaldine Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Beaudesert",
		name:"Beaudesert Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Biggenden",
		name:"Biggenden Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Biloela",
		name:"Biloela Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Blackall",
		name:"Blackall Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Blackwater",
		name:"Blackwater Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Boigu Island",
		name:"Boigu Island Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Bollon",
		name:"Bollon Outpatients Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Boonah",
		name:"Boonah Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Boulia",
		name:"Boulia Primary Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Bowen",
		name:"Bowen Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Bundaberg",
		name:"Bundaberg Base Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Burketown",
		name:"Burketown Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Caboolture",
		name:"Caboolture Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Cairns",
		name:"Cairns Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Caloundra",
		name:"Caloundra Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Camooweal",
		name:"Camooweal Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Capella",
		name:"Capella Outpatients Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Yeppoon",
		name:"Capricorn Coast Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Charleville",
		name:"Charleville Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Charters Towers",
		name:"Charters Towers Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Charters Towers",
		name:"Charters Towers Rehabilitation Unit",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Cherbourg",
		name:"Cherbourg Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Childers",
		name:"Childers Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Chillagoe",
		name:"Chillagoe Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Chinchilla",
		name:"Chinchilla Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Clermont",
		name:"Clermont Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Cloncurry",
		name:"Cloncurry Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Coconut Island",
		name:"Coconut Island Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Coen",
		name:"Coen Primary Health Care Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Collinsville",
		name:"Collinsville Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Cooktown",
		name:"Cooktown Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Cracow",
		name:"Cracow Outpatients Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Croydon",
		name:"Croydon Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Cunnamulla",
		name:"Cunnamulla Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Dajarra",
		name:"Dajarra Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Dalby",
		name:"Dalby Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Darnley Island",
		name:"Darnley Island Primary Health Care Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Dauan Island",
		name:"Dauan Island Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Dimbulah",
		name:"Dimbulah Outpatients Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Dirranbandi",
		name:"Dirranbandi Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Doomadgee",
		name:"Doomadgee Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Duaringa",
		name:"Duaringa Outpatients Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Dysart",
		name:"Dysart Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Eidsvold",
		name:"Eidsvold Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Chermside",
		name:"Ellen Barron Family Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Emerald",
		name:"Emerald Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Esk",
		name:"Esk Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Forsayth",
		name:"Forsayth Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Gatton",
		name:"Gatton Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Gayndah",
		name:"Gayndah Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Sapphire",
		name:"Gemfields Outpatients Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Georgetown",
		name:"Georgetown Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Gin Gin",
		name:"Gin Gin Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Gladstone",
		name:"Gladstone Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Glenmorgan",
		name:"Glenmorgan Outpatients Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Southport",
		name:"Gold Coast University Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Goondiwindi",
		name:"Goondiwindi Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Gordonvale",
		name:"Gordonvale Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Yarrabah",
		name:"Gurriny Yealamucka Primary Health Care Service",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Gympie",
		name:"Gympie Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Herberton",
		name:"Herberton Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Pialba",
		name:"Hervey Bay Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Home Hill",
		name:"Home Hill Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Hope Vale",
		name:"Hope Vale Primary Health Care Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Hughenden",
		name:"Hughenden Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Ingham",
		name:"Ingham Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Inglewood",
		name:"Inglewood Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Injune",
		name:"Injune Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Innisfail",
		name:"Innisfail Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Ipswich",
		name:"Ipswich Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Isisford",
		name:"Isisford Primary Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Thursday Island",
		name:"Island Medical Service",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Jandowae",
		name:"Jandowae Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Palm Island",
		name:"Joyce Palmer Health Service",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Julia Creek",
		name:"Julia Creek Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Jundah",
		name:"Jundah Primary Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Karumba",
		name:"Karumba Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Kilcoy",
		name:"Kilcoy Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Kingaroy",
		name:"Kingaroy Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Kirwan",
		name:"Kirwan Mental Health Rehabilitation Unit",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Kowanyama",
		name:"Kowanyama Primary Health Care Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Kubin",
		name:"Kubin Primary Health Care Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Laidley",
		name:"Laidley Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Laura",
		name:"Laura Outpatients Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Lockhart River",
		name:"Lockhart River Primary Health Care Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Meadowbrook",
		name:"Logan Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Longreach",
		name:"Longreach Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Mabuiag Island",
		name:"Mabuiag Island Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Mackay",
		name:"Mackay Base Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Nelly Bay",
		name:"Magnetic Island Health Service Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Napranum",
		name:"Malakoola Primary Health Care Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Malanda",
		name:"Malanda Outpatients Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Maleny",
		name:"Maleny Soldier's Memorial Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Mapoon",
		name:"Mapoon Primary Health Care Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Mareeba",
		name:"Mareeba Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Dunwich",
		name:"Marie Rose Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Maryborough",
		name:"Maryborough Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"South Brisbane",
		name:"Mater Adult Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"South Brisbane (closed 2014)",
		name:"Mater Children's Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"South Brisbane",
		name:"Mater Mothers' Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Meandarra",
		name:"Meandarra Outpatients Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Miles",
		name:"Miles Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Millaa Millaa",
		name:"Millaa Millaa Outpatients Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Millmerran",
		name:"Millmerran Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Mitchell",
		name:"Mitchell Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Monto",
		name:"Monto Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Moonie",
		name:"Moonie Outpatients Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Moranbah",
		name:"Moranbah Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Mornington Island",
		name:"Mornington Island Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Morven",
		name:"Morven Outpatients Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Mossman",
		name:"Mossman Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Mount Garnet",
		name:"Mount Garnet Outpatients Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Mornington, Mount Isa",
		name:"Mount Isa Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Mount Morgan",
		name:"Mount Morgan Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Mount Perry",
		name:"Mount Perry Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Moura",
		name:"Moura Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Mundubbera",
		name:"Mundubbera Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Mungindi",
		name:"Mungindi Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Murgon",
		name:"Murgon Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Murray Island",
		name:"Murray Island Primary Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Muttaburra",
		name:"Muttaburra Primary Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Nambour",
		name:"Nambour Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Nanango",
		name:"Nanango Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Normanton",
		name:"Normanton Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Oakey",
		name:"Oakey Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Wacol",
		name:"The Park Centre for Mental Health",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Pormpuraaw",
		name:"Pormpuraaw Primary Health Care Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Chermside",
		name:"The Prince Charles Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Woolloongabba",
		name:"Princess Alexandra Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Proserpine",
		name:"Proserpine Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Coopers Plains",
		name:"Queen Elizabeth II Jubilee Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"South Brisbane",
		name:"Queensland Children's Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Quilpie",
		name:"Quilpie Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Ravenshoe",
		name:"Ravenshoe Outpatients Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Redcliffe",
		name:"Redcliffe Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Cleveland",
		name:"Redland Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Richmond",
		name:"Richmond Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Robina",
		name:"Robina Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Rockhampton",
		name:"Rockhampton Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Roma",
		name:"Roma Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Herston",
		name:"Royal Brisbane & Women's Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Herston (closed 2014)",
		name:"Royal Children's Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Saibai Island",
		name:"Saibai Island Primary Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Sarina",
		name:"Sarina Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Springsure",
		name:"Springsure Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"St George",
		name:"St George Hospital Qld",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"St Pauls",
		name:"St Pauls Primary Health Care Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Stanthorpe",
		name:"Stanthorpe Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Stephens Island",
		name:"Stephens Island Primary Health Care Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"",
		name:"Sunshine Coast University Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Surat",
		name:"Surat Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Tambo",
		name:"Tambo Primary Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Tara",
		name:"Tara Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Taroom",
		name:"Taroom Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Texas",
		name:"Texas Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Thargomindah",
		name:"Thargomindah Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Theodore",
		name:"Theodore Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Thursday Island",
		name:"Thursday Island Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Thursday Island",
		name:"Thursday Island Primary Health Care Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Toowoomba",
		name:"Toowoomba Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Douglas",
		name:"Townsville Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Tully",
		name:"Tully Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Wallumbilla",
		name:"Wallumbilla Outpatients Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Wandoan",
		name:"Wandoan Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Warraber Island",
		name:"Warraber Island Primary Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Warwick",
		name:"Warwick Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Weipa",
		name:"Weipa Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Windorah",
		name:"Windorah Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Winton",
		name:"Winton Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Wondai",
		name:"Wondai Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Woorabinda",
		name:"Woorabinda Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Wujal Wujal",
		name:"Wujal Wujal Primary Health Care Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Lota",
		name:"Wynnum Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Yam Island",
		name:"Yam Island Primary Health Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Yaraka",
		name:"Yaraka Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Public,
		location:"Yorke Island",
		name:"Yorke Island Primary Health Centre",
	},
];

export const ntPrivate : hospital[] = [
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Southport",
		name:"Allamanda Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Sunnybank",
		name:"Brisbane Endoscopy Services P/L",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Brisbane",
		name:"Brisbane Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Caboolture",
		name:"Caboolture Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Cairns",
		name:"The Cairns Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Cairns, Queensland",
		name:"Cairns Day Surgery",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Cairns",
		name:"Cairns Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Caloundra",
		name:"Caloundra Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Chermside",
		name:"Chermside Day Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Spring Hill",
		name:"Eye-Tech Day Surgeries",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Upper Mt Gravatt",
		name:"Eye-Tech Day Surgeries Southside",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Bundaberg",
		name:"Friendly Society Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"",
		name:"Gold Coast Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Greenslopes",
		name:"Greenslopes Day Surgery",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Greenslopes",
		name:"Greenslopes Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Gympie",
		name:"Gympie Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Pialba",
		name:"Hervey Bay Surgical Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Rockhampton",
		name:"Hillcrest Rockhampton Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Arundel",
		name:"Hopewell Hospice",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Ipswich",
		name:"Ipswich Day Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Tugun",
		name:"John Flynn Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Birtinya",
		name:"Kawana Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"North Mackay",
		name:"Mackay Specialist Day Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"South Brisbane",
		name:"Mater Children's Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Pimlico",
		name:"Mater Hospital Pimlico",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Mackay",
		name:"Mater Misericordiae Day Unit",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Bundaberg",
		name:"Mater Misericordiae Hospital Bundaberg",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Gladstone",
		name:"Mater Misericordiae Hospital Gladstone",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Mackay",
		name:"Mater Misericordiae Hospital Mackay",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Rockhampton",
		name:"Mater Misericordiae Hospital, Rockhampton",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Yeppoon",
		name:"Mater Misericordiae Hospital Yeppoon",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"South Brisbane",
		name:"Mater Mother's Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Cleveland",
		name:"Mater Private Hospital Redland",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"South Brisbane",
		name:"Mater Private Hospital South Brisbane",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Hyde Park",
		name:"Mater Women's and Children's Hospital Hyde Park",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Gaythorne",
		name:"Montserrat Day Hospital Gaythorne",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Indooroopilly",
		name:"Montserrat Day Hospital Indooroopilly",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"North Lakes",
		name:"Montserrat Day Hospital North Lakes",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Nambour",
		name:"Nambour Day Surgery",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Nambour",
		name:"Nambour Selangor Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"New Farm",
		name:"New Farm Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Noosaville",
		name:"Noosa Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"North Mackay",
		name:"North Mackay Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Pimlico",
		name:"North Queensland Day Surgery",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Everton Park",
		name:"North West Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Southport",
		name:"Pacific Private Day Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Kippa-Ring",
		name:"Peninsula Private Hospital Queensland",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Benowa",
		name:"Pindara Day Procedure Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Benowa",
		name:"Pindara Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Strathpine",
		name:"Pine Rivers Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Spring Hill",
		name:"Queensland Eye Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Auchenflower",
		name:"River City Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Robina",
		name:"Robina Procedure Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Southport",
		name:"Short Street Day Surgery",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Kingaroy",
		name:"South Burnett Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Southport",
		name:"Southport Day Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Loganholme",
		name:"Southside Endoscopy Centre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Southport",
		name:"Spendelove Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Ipswich",
		name:"St Andrew's Ipswich Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Toowoomba",
		name:"St Andrew's Toowoomba Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Spring Hill",
		name:"St Andrew's War Memorial Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Hervey Bay",
		name:"St Stephen's Hospital Hervey Bay",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Maryborough",
		name:"St Stephen's Hospital Maryborough",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Chermside",
		name:"St Vincents Private Hospital Northside",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Kangaroo Point",
		name:"St Vincent's Private Hospital Brisbane",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Toowoomba",
		name:"St Vincent's Private Hospital Toowoomba",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Sunnybank",
		name:"Sunnybank Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Cotton Tree",
		name:"Sunshine Coast Haematology and Oncology Clinic",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Buderim",
		name:"The Sunshine Coast Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Birtinya",
		name:"Sunshine Coast University Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Toowong",
		name:"Toowong Private Hospital",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Toowoomba",
		name:"Toowoomba Surgicentre",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"West End",
		name:"Townsville Day Surgery",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Auchenflower",
		name:"Vision Day Surgery Rivercity",
	},
	{
		state:"Northern Territory",
		sector:Sector.Private,
		location:"Auchenflower",
		name:"Wesley Hospital",
	},
];

export const saPublic : hospital[] = [
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Andamooka",
		name:"Andamooka Outpost Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Angaston",
		name:"Angaston District Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Balaklava",
		name:"Balaklava Soldiers Memorial District Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Barmera",
		name:"Barmera Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Booleroo Centre",
		name:"Booleroo Centre District Hospital and Health Services",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Bordertown",
		name:"Bordertown Memorial Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Burra",
		name:"Burra Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Ceduna",
		name:"Ceduna District Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Maitland",
		name:"Central Yorke Peninsula Hospital (Maitland)",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Clare",
		name:"Clare Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Cleve",
		name:"Cleve District Hospital and Aged Care",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Coober Pedy",
		name:"Coober Pedy Hospital and Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Cowell",
		name:"Cowell District Hospital and Aged Care",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Crystal Brook",
		name:"Crystal Brook and District Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Cummins",
		name:"Cummins and District Memorial Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Elliston",
		name:"Elliston Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Eudunda",
		name:"Eudunda Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Bedford Park",
		name:"Flinders Medical Centre",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Gawler East",
		name:"Gawler Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Glenside",
		name:"Glenside Campus Mental Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Gumeracha",
		name:"Gumeracha District Soldiers Memorial Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Northfield",
		name:"Hampstead Rehabilitation Centre",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Hawker",
		name:"Hawker Memorial Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Jamestown",
		name:"Jamestown Hospital and Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Kingscote",
		name:"Kangaroo Island Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Kapunda",
		name:"Kapunda Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Karoonda",
		name:"Karoonda and District Soldiers' Memorial Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Kimba",
		name:"Kimba District Hospital and Aged Care",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Kingston SE",
		name:"Kingston Soldiers' Memorial Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Lameroo",
		name:"Lameroo District Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Laura",
		name:"Laura and District Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Leigh Creek",
		name:"Leigh Creek Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Loxton",
		name:"Loxton Hospital Complex",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Elizabeth Vale",
		name:"Lyell McEwin Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Mannum",
		name:"Mannum District Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Marree",
		name:"Marree Health Services",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Meningie",
		name:"Meningie & Districts Memorial Hospital & Health Services",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Millicent",
		name:"Millicent & Districts Hospital & Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Modbury",
		name:"Modbury Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Mount Barker",
		name:"Mount Barker District Soldiers' Memorial Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Mount Gambier",
		name:"Mount Gambier and Districts Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Mount Pleasant",
		name:"Mount Pleasant District Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Murray Bridge",
		name:"Murray Bridge Soldiers' Memorial Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Naracoorte",
		name:"Naracoorte Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Noarlunga Centre",
		name:"Noarlunga Public Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Wallaroo",
		name:"Northern Yorke Peninsula Health Service (Wallaroo)",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Oakden",
		name:"Oakden Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Oodnadatta",
		name:"Oodnadatta Clinic",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Orroroo",
		name:"Orroroo & District Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Penola",
		name:"Penola War Memorial Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Peterborough",
		name:"Peterborough Soldiers' Memorial Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Pinnaroo",
		name:"Pinnaroo Soldiers' Memorial Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Port Augusta",
		name:"Port Augusta Hospital & Regional Health Services",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Port Broughton",
		name:"Port Broughton & District Hospital & Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Port Lincoln",
		name:"Port Lincoln Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Port Pirie",
		name:"Port Pirie Regional Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Woodville Park",
		name:"Pregnancy Advisory Centre",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Quorn",
		name:"Quorn Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Renmark",
		name:"Renmark Paringa District Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Berri",
		name:"Riverland General Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Riverton",
		name:"Riverton District Soldiers Memorial Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Roxby Downs",
		name:"Roxby Downs Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Adelaide",
		name:"Royal Adelaide Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Snowtown",
		name:"Snowtown Hospital and Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Victor Harbor",
		name:"South Coast District Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"",
		name:"South East Regional CHS",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Yorketown",
		name:"Southern Yorke Peninsula Health Service (Yorketown)",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Semaphore",
		name:"St Margaret's Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Strathalbyn",
		name:"Strathalbyn and District Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Streaky Bay",
		name:"Streaky Bay Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Tailem Bend",
		name:"Tailem Bend District Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Tanunda",
		name:"Tanunda War Memorial Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Woodville",
		name:"Queen Elizabeth Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Tumby Bay",
		name:"Tumby Bay Hospital and Health Services",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Waikerie",
		name:"Waikerie Health Service",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Whyalla",
		name:"Whyalla Hospital and Health Services",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"North Adelaide",
		name:"Women's and Children's Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Woomera",
		name:"Woomera Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Public,
		location:"Wudinna",
		name:"Wudinna Hospital",
	},
];

export const saPrivate : hospital[] = [
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Gilberton",
		name:"Adelaide Clinic",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Adelaide",
		name:"Adelaide Day Surgery Pty Ltd",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Eastwood",
		name:"Adelaide Eye & Laser Centre",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Kent Town",
		name:"Adelaide Surgicentre",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Ashford",
		name:"Ashford Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Toorak Gardens",
		name:"Burnside War Memorial Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Adelaide",
		name:"Calvary Adelaide Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Elizabeth Vale",
		name:"Calvary Central Districts Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"North Adelaide",
		name:"Calvary North Adelaide Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Bedford Park",
		name:"Flinders Private Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Parkside",
		name:"Fullarton Private Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Glenelg South",
		name:"Glenelg Community Hospital Inc",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Hove",
		name:"Griffith Rehabilitation Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Magill",
		name:"Kahlyn Day Centre",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"McLaren Vale",
		name:"McLaren Vale & Districts War Memorial Hospital Inc",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"North Adelaide",
		name:"Memorial Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Mount Gambier",
		name:"Mount Gambier Private Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Unley",
		name:"Oxford Day Surgery Centre",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Parkside",
		name:"Parkside Cosmetic Surgery",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Adelaide",
		name:"Parkwynd Private Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Stepney",
		name:"SPORTSMED SA Hospital & Day Surgery",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Adelaide",
		name:"St Andrew's Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Stirling",
		name:"Stirling Hospital",
	},
	{
		state:"South Australia",
		sector:Sector.Private,
		location:"Henley Beach",
		name:"Western Hospital",
	},
];

export const tasPublic : hospital[] = [
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Beaconsfield",
		name:"Beaconsfield District Health Service",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Campbell Town",
		name:"Campbell Town Multi Purpose Service",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Deloraine",
		name:"Deloraine District Hospital",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Dover",
		name:"Esperance Multi Purpose Centre",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Whitemark",
		name:"Flinders Island Multi Purpose Centre",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"George Town",
		name:"George Town Hospital and Community Health Centre",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Queenstown",
		name:"HealthWest (West Coast District Hospital at Queenstown)",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Franklin",
		name:"Huon Eldercare",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Currie",
		name:"King Island Multi Purpose Centre",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Launceston",
		name:"Launceston General Hospital",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Swansea",
		name:"May Shaw District Nursing Centre",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Latrobe",
		name:"Mersey Community Hospital",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Oatlands",
		name:"Midlands Multi Purpose Centre",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"New Norfolk",
		name:"New Norfolk District Hospital",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Scottsdale",
		name:"North East Soldiers' Memorial Hospital and Community Service Centre (Scottsdale Hospital)",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Burnie",
		name:"North West Regional Hospital",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Hobart",
		name:"Royal Hobart Hospital",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Smithton",
		name:"Smithton District Hospital",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"St Helens",
		name:"St Helens District Hospital",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"St Marys",
		name:"St Marys Community Health Centre",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Nubeena",
		name:"Tasman Health and Community Service",
	},
	{
		state:"Tasmania",
		sector:Sector.Public,
		location:"Longford",
		name:"Toosey Memorial Hospital (Longford)",
	},
];

export const tasPrivate : hospital[] = [
	{
		state:"Tasmania",
		sector:Sector.Private,
		location:"",
		name:"Calvary Health Care Tasmania",
	},
	{
		state:"Tasmania",
		sector:Sector.Private,
		location:"",
		name:"Calvary Health Care Tasmania",
	},
	{
		state:"Tasmania",
		sector:Sector.Private,
		location:"",
		name:"Calvary Health Care Tasmania",
	},
	{
		state:"Tasmania",
		sector:Sector.Private,
		location:"",
		name:"Calvary Health Care Tasmania",
	},
	{
		state:"Tasmania",
		sector:Sector.Private,
		location:"Hobart",
		name:"Hobart Private Hospital",
	},
	{
		state:"Tasmania",
		sector:Sector.Private,
		location:"Hobart",
		name:"St Helen's Private Hospital",
	},
	{
		state:"Tasmania",
		sector:Sector.Private,
		location:"Launceston",
		name:"The Eye Hospital",
	},
	{
		state:"Tasmania",
		sector:Sector.Private,
		location:"Rokeby",
		name:"The Hobart Clinic",
	},
];

export const vicPublic : hospital[] = [
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"",
		name:"Metropolitan Melbourne",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Upper Ferntree Gully",
		name:"Angliss Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Heidelberg",
		name:"Austin Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Box Hill",
		name:"Box Hill Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Broadmeadows",
		name:"Broadmeadows Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Bundoora",
		name:"Bundoora Extended Care Centre",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Caulfield South",
		name:"Calvary Health Care Bethlehem",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Kew",
		name:"Caritas Christi Hospice",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Berwick",
		name:"Casey Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Caulfield",
		name:"Caulfield Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Craigieburn",
		name:"Craigieburn Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Cranbourne",
		name:"Cranbourne Integrated Care Centre",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Dandenong",
		name:"Dandenong Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Footscray",
		name:"Footscray Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Frankston",
		name:"Frankston Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Healesville",
		name:"Healesville Hospital and Yarra Valley Health",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Ivanhoe",
		name:"Heidelberg Repatriation Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Clayton",
		name:"Jessie McPherson Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Cheltenham",
		name:"Kingston Centre",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Ringwood East",
		name:"Maroondah Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Heidelberg",
		name:"Mercy Hospital for Women",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Clayton",
		name:"Monash Children's Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Clayton",
		name:"Monash Medical Centre",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Bentleigh East",
		name:"Moorabbin Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Burwood East",
		name:"Peter James Centre",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Melbourne",
		name:"Peter MacCallum Cancer Institute",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Preston",
		name:"Panch Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Noble Park",
		name:"Queen Elizabeth Centre",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Capel Sound",
		name:"Rosebud Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Parkville",
		name:"Royal Children's Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Carlton",
		name:"Royal Dental Hospital of Melbourne",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Parkville",
		name:"Royal Melbourne Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Kew",
		name:"Royal Talbot Rehabilitation Centre",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"East Melbourne",
		name:"Royal Victorian Eye and Ear Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Parkville",
		name:"Royal Women's Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Sandringham",
		name:"Sandringham Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Kew",
		name:"St George's Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Fitzroy",
		name:"St Vincent's Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"St Albans",
		name:"Sunshine Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Melbourne",
		name:"The Alfred Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Mornington",
		name:"The Mornington Centre",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Epping",
		name:"The Northern Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Wantirna",
		name:"Wantirna Health",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Williamstown",
		name:"Williamstown Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Public,
		location:"Lilydale",
		name:"Yarra Ranges Health",
	},
];

export const vicPublicRulal : hospital[] = [
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Albury Wodonga Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Alexandra District Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Alpine Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Bairnsdale Regional Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Ballarat Health Services",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Barwon Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Bass Coast Regional Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Beaufort and Skipton Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Beechworth Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Benalla Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Bendigo Base Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Boort District Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Casterton Memorial Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Castlemaine Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Central Gippsland Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Cobram District Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Cohuna District Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Colac Area Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Djerriwarrh Health Services",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Dunmunkle Health Services",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"East Grampians Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"East Wimmera Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Echuca Regional Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Edenhope and District Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Gippsland Southern Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Goulburn Valley Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Heathcote Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Hepburn Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Hesse Rural Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Heywood Rural Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Inglewood and District Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Kerang District Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Kilmore and District Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Kooweerup Regional Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Kyabram and District Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Kyneton District Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Latrobe Regional Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Lorne Community Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Maldon Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Maryborough District Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Melton Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Mildura Base Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Moyne Health Services",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Nathalia District Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Northeast Health Wangaratta",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Numurkah District Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Omeo District Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Orbost Regional Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Otway Health and Community Services",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Portland District Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Robinvale District Health Services",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Rochester and Elmore District Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Rural Northwest Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Seymour Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"South Gippsland Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"South West Healthcare",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Stawell Regional Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Swan Hill District Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Tallangatta Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Terang and Mortlake Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Timboon and District Healthcare Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Upper Murray Health and Community Services",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Warrnambool Base Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"West Gippsland Healthcare Group",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"West Wimmera Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Western District Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Wimmera Health Care Group",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Yarram and District Health Service",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Yarrawonga Health",
	},
	{
		state:"Victoria",
		sector:Sector.PublicRural,
		location:"",
		name:"Yea and District Memorial Hospital",
	},

];

export const vicPrivate : hospital[] = [
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Melbourne",
		name:"Albert Road Clinic",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Windsor",
		name:"Avenue Plastic Surgery",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Mornington",
		name:"Beleura Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Blackburn South",
		name:"Bellbird Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Berwick",
		name:"Berwick Eye and Surgicentre",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Brighton",
		name:"Cabrini Brighton",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"",
		name:"Cabrini Health Elsternwick Rehabilitation",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"",
		name:"Cabrini Health Elsternwick Rehabilitation",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Malvern",
		name:"Cabrini Malvern",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Prahran",
		name:"Cabrini Prahran",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Cheltenham",
		name:"Chesterville Day Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Parkdale",
		name:"Como Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Dandenong",
		name:"Corymbia House",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Kew",
		name:"Cotham Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Donvale",
		name:"Donvale Rehabilitation Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Pascoe Vale",
		name:"Dorset Rehabilitation Centre",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"East Melbourne",
		name:"Epworth Cliveden",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Box Hill",
		name:"Epworth Eastern",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"East Melbourne",
		name:"Epworth Freemasons ",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"East Melbourne",
		name:"Epworth Freemasons ",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Hawthorn",
		name:"Epworth Hawthorn",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Brighton",
		name:"Epworth Rehabilitation Brighton",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Camberwell",
		name:"Epworth Rehabilitation Camberwell",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Richmond",
		name:"Epworth Rehabilitation Richmond",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Richmond",
		name:"Epworth Richmond",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Parkville",
		name:"Frances Perry House",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Caulfield South",
		name:"Glen Eira Day Surgery",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Hawthorn",
		name:"Glenferrie Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Moorabbin",
		name:"Healthscope Independence Services",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Altona",
		name:"Hobsons Bay Endoscopy Centre Altona",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Sydenham",
		name:"Hobsons Bay Endoscopy Centre Sydenham",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Werribee",
		name:"Hobsons Bay Endoscopy Centre Werribee",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Coburg",
		name:"John Fawkner Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"East Melbourne",
		name:"Jolimont Endoscopy",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Wantirna",
		name:"Knox Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Hampton",
		name:"Linacre Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Templestowe Lower",
		name:"Manningham Day Procedure Centre",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Morwell",
		name:"Maryvale Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"St Kilda",
		name:"Masada Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Caulfield North",
		name:"Melbourne MediBrain & MediSleep Centre",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Melbourne",
		name:"Melbourne Oral & Facial Surgery",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Parkville",
		name:"Melbourne Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Mildura",
		name:"Mildura Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Mitcham",
		name:"Mitcham Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"",
		name:"Mulgrave Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Wodonga",
		name:"Murray Valley Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Ivanhoe",
		name:"North Eastern Rehabilitation Centre",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Bundoora",
		name:"Northpark Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Frankston",
		name:"Peninsula Private Hospital Victoria",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Ringwood",
		name:"Ringwood Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Rosebud West",
		name:"Rosebud SurgiCentre",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Shepparton",
		name:"Shepparton Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Skin cancer specialty clinic",
		name:"Skin Only",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Noble Park",
		name:"South Eastern Private",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Ballarat",
		name:"St John of God Ballarat Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Bendigo",
		name:"St John of God Bendigo Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Berwick",
		name:"St John of God Berwick Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Frankston",
		name:"St John of God Frankston Rehabilitation Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Geelong",
		name:"St John of God Geelong Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Dandenong",
		name:"St John of God Pinelodge Clinic",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Warrnambool",
		name:"St John of God Warrnambool Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"East Melbourne",
		name:"St Vincent's Private Hospital East Melbourne",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Fitzroy",
		name:"St Vincent's Private Hospital Fitzroy",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Kew",
		name:"St Vincent's Private Hospital Kew",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Windsor",
		name:"The Avenue Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Mornington",
		name:"The Bays Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"St Albans Park",
		name:"The Geelong Clinic",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Richmond",
		name:"The Melbourne Clinic",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Prahran",
		name:"The Victoria Clinic",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Glen Waverley",
		name:"The Victorian Rehabilitation Centre",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"East Melbourne",
		name:"Victoria Parade Surgery Centre",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Camberwell",
		name:"Vision Day Surgery Camberwell",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Box Hill",
		name:"Vision Day Surgery Eastern",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Footscray",
		name:"Vision Day Surgery Footscray",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Melbourne",
		name:"Vision Eye Institute",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Wangaratta",
		name:"Wangaratta Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Heidelberg",
		name:"Warringal Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Mount Waverley",
		name:"Waverley Private Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Werribee",
		name:"Werribee Mercy Hospital",
	},
	{
		state:"Victoria",
		sector:Sector.Private,
		location:"Footscray",
		name:"Western Private Hospital",
	},
];

export const waPublic : hospital[] = [
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Albany",
		name:"Albany Health Campus",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Armadale",
		name:"Armadale-Kelmscott Memorial Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Augusta",
		name:"Augusta Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Bentley",
		name:"Bentley Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Beverley",
		name:"Beverley Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Boddington",
		name:"Boddington Health Service",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Boyup Brook",
		name:"Boyup Brook Soldiers Memorial Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Bridgetown",
		name:"Bridgetown Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Broome",
		name:"Broome Health Campus",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Bruce Rock",
		name:"Bruce Rock Memorial Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Busselton",
		name:"Busselton Health Campus",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Carnarvon",
		name:"Carnarvon Health Campusl",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Collie",
		name:"Collie Health Service",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Corrigin",
		name:"Corrigin District Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Cunderdin",
		name:"Cunderdin Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Dalwallinu",
		name:"Dalwallinu Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Denmark",
		name:"Denmark Hospital and Health Service",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Derby",
		name:"Derby Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Dongara",
		name:"Dongara Eneabba Mingenew Health Service",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Donnybrook",
		name:"Donnybrook Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Dumbleyung",
		name:"Dumbleyung Memorial Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Esperance",
		name:"Esperance Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Exmouth",
		name:"Exmouth Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Murdoch",
		name:"Fiona Stanley Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Fitzroy Crossing",
		name:"Fitzroy Crossing Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Fremantle",
		name:"Fremantle Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Geraldton",
		name:"Geraldton Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Gnowangerup",
		name:"Gnowangerup Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Goomalling",
		name:"Goomalling Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Mount Claremont",
		name:"Graylands Selby-Lemnos and Special Care Health Service",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Halls Creek",
		name:"Halls Creek Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Harvey",
		name:"Harvey Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"South Hedland",
		name:"Hedland Health Campus",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Joondalup",
		name:"Joondalup Health Campus (Public)",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Kalamunda[598]",
		name:"Kalamunda Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Kalbarri",
		name:"Kalbarri Health Centre",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"East Fremantle[600]",
		name:"Kaleeya Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Kalgoorlie[601]",
		name:"Kalgoorlie Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Katanning[602]",
		name:"Katanning Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Kellerberrin[603]",
		name:"Kellerberrin Memorial Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Subiaco",
		name:"King Edward Memorial Hospital for Women",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Kojonup",
		name:"Kojonup Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Kondinin[605]",
		name:"Kondinin Districts Health Service",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Kununoppin[606]",
		name:"Kununoppin Health Service",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Kununurra[607]",
		name:"Kununurra Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Lake Grace[608]",
		name:"Lake Grace Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Laverton[609]",
		name:"Laverton Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Leonora[610]",
		name:"Leonora Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Margaret River",
		name:"Margaret River Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Meekatharra[611]",
		name:"Meekatharra Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Merredin[612]",
		name:"Merredin Health Service",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Moora[613]",
		name:"Moora Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Morawa[614]",
		name:"Morawa Health Service",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Mullewa[615]",
		name:"Mullewa Health Service",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Pinjarra[616]",
		name:"Murray Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Nannup[617]",
		name:"Nannup Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Narembeen[618]",
		name:"Narembeen Memorial Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Narrogin[619]",
		name:"Narrogin Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Newman",
		name:"Newman Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"East Perth[620]",
		name:"Next Step Drug And Alcohol Services, East Perth",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Karratha[621]",
		name:"Nickol Bay Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Norseman[622]",
		name:"Norseman Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Three Springs[623]",
		name:"North Midlands Health Service",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Northam[624]",
		name:"Northam Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Northampton[625]",
		name:"Northampton Kalbarri Health Service",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Onslow[626]",
		name:"Onslow Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Stirling",
		name:"Osborne Park Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Paraburdoo[628]",
		name:"Paraburdoo Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Mandurah[629]",
		name:"Peel Health Campus",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Pemberton[630]",
		name:"Pemberton Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Nedlands[631]",
		name:"Perth Children's Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Pingelly[632]",
		name:"Pingelly Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Mount Barker[633]",
		name:"Plantagenet Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Subiaco",
		name:"Princess Margaret Hospital for Children",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Quairading[635]",
		name:"Quairading Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Ravensthorpe[636]",
		name:"Ravensthorpe Health Centre",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Cooloongup[637]",
		name:"Rockingham General Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Roebourne[638]",
		name:"Roebourne Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Shenton Park",
		name:"Royal Perth Hospital Shenton Park Campus",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Perth",
		name:"Royal Perth Hospital Wellington Street Campus",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Shenton Park[640]",
		name:"Selby Authorised Lodge",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Nedlands",
		name:"Sir Charles Gairdner Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Bunbury[642]",
		name:"South West Health Campus",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Southern Cross[643]",
		name:"Southern Cross Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Mount Claremont[644]",
		name:"State Forensic Mental Health Service",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"",
		name:"St John of God Midland Public Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Tom Price[646]",
		name:"Tom Price Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Wagin[647]",
		name:"Wagin Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Manjimup[648]",
		name:"Warren Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Wongan Hills[649]",
		name:"Wongan Hills Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Wyalkatchem[650]",
		name:"Wyalkatchem-Koorda and Districts Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"Wyndham[651]",
		name:"Wyndham Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Public,
		location:"York[652]",
		name:"York Hospital",
	},
];

export const waPrivate : hospital[] = [
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"West Leederville",
		name:"Abbotsford Private Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"Attadale",
		name:"Attadale Private Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"Claremont",
		name:"Bethesda Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"West Perth",
		name:"Colin Street Day Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"Duncraig",
		name:"Glengarry Private Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"Nedlands",
		name:"Hollywood Private Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"Joondalup",
		name:"Joondalup Health Campus (Private)",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"West Perth[660]",
		name:"Mount Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"Mount Hawthorn",
		name:"Oxford Day Surgery and Dermatology",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"West Perth",
		name:"Perth Clinic",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"West Perth",
		name:"Sentiens Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"South Perth",
		name:"South Perth Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"Bunbury",
		name:"St John of God Bunbury Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"Geraldton",
		name:"St John of God Geraldton Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"Mount Lawley",
		name:"St John of God Mt Lawley Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"",
		name:"St John of God Midland Private Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"Murdoch",
		name:"St John of God Murdoch Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"Subiaco",
		name:"St John of God Subiaco Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"Subiaco[670]",
		name:"Subiaco Private Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"Waikiki",
		name:"Waikiki Private Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"Mount Lawley",
		name:"Walcott Street Surgical Centre",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"McCourt StreetWest Leederville",
		name:"West Leederville Private Hospital",
	},
	{
		state:"Western Australia",
		sector:Sector.Private,
		location:"Westminster",
		name:"Westminster Day Surgery",
	},
];

function getHosiptalsKey(state:string, sector:Sector) : string {
	return `${state}_${String(sector)}`;
}

const hospitalsByStateSector : Map<string, hospital[]> = new Map([
	[ getHosiptalsKey("Australian Capital Territory", Sector.Public), actPublic ],
	[ getHosiptalsKey("Australian Capital Territory", Sector.Private), actPrivate ],

	[ getHosiptalsKey("New South Wales", Sector.Public), nswPublic ],
	[ getHosiptalsKey("New South Wales", Sector.Private), nswPrivate ],

	[ getHosiptalsKey("Northern Territory", Sector.Public), ntPublic ],
	[ getHosiptalsKey("Northern Territory", Sector.Private), ntPrivate ],

	[ getHosiptalsKey("South Australia", Sector.Public), saPublic ],
	[ getHosiptalsKey("South Australia", Sector.Private), saPrivate ],

	[ getHosiptalsKey("Tasmania", Sector.Public), tasPublic ],
	[ getHosiptalsKey("Tasmania", Sector.Private), tasPrivate ],

	[ getHosiptalsKey("Victoria", Sector.Public), vicPublic ],
	[ getHosiptalsKey("Victoria", Sector.PublicRural), vicPublicRulal ],
	[ getHosiptalsKey("Victoria", Sector.Private), vicPrivate ],

	[ getHosiptalsKey("Western Australia", Sector.Public), waPublic ],
	[ getHosiptalsKey("Western Australia", Sector.Private), waPrivate ],
]);

export function getHospitalsByStateSector(state:string, sectorString:string) : string[] {
	const key = getHosiptalsKey(state, Sector[sectorString]);
	return (hospitalsByStateSector.get(key) ?? []).map((h) => h.name); 
}