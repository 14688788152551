import { API } from "aws-amplify";
import { UserWrapper } from "./userContext";
import {
    ErrorSessionLapsed,
    getErrorMessage,
    getErrorMessageFromApiResult,
    isUnauthorisedError,
} from "./errorUtils";
import {
    getUserById,
    getDocumentTypes,
    getDocumentsForUser,
} from "../graphql/queries";
import { User, Document, DocumentType, Application } from "../API";
import {
    GraphQLOptions,
    GraphQLResult,
    GRAPHQL_AUTH_MODE,
} from "@aws-amplify/api-graphql";

export const getApplicationTemplates = /* GraphQL */ `
    query GetApplicationTemplates {
        getApplicationTemplates {
            id
            name
            userId
            state
            sector
            hospital
            positionType
            level
            notes
            applicationSections {
                id
                applicationId
                name
                applicationDocuments {
                    id
                    applicationSectionId
                    documentTypeId
                }
            }
        }
    }
`;

export type ApiResult<TType> = {
    Result: TType | undefined;
    Error: Error | undefined;
};

async function doCallApi<TType>(
    user: UserWrapper,
    queryName: string,
    query: GraphQLOptions,
    retryOnUnauthorised: boolean
): Promise<ApiResult<TType>> {
    try {
        const okToProceed = await user.checkSessionActive();
        if (!okToProceed) {
            user.signOut();
            return {
                Result: undefined,
                Error: ErrorSessionLapsed,
            };
        }
        const queryWithPermissions = {
            ...query,
            authMode: GRAPHQL_AUTH_MODE.OPENID_CONNECT,
            authToken: user.idToken.__raw,
        };
        const response = (await API.graphql(
            queryWithPermissions
        )) as GraphQLResult<any>;
        if (response.data) {
            console.log(
                "API called successfully. Returned data: %o",
                response.data
            );
            const typedResult = response.data[queryName] as TType; // TODO REVISIT
            if (typedResult) return { Result: typedResult, Error: undefined };
            else
                return {
                    Result: undefined,
                    Error: new Error(
                        "The api result was not of the expected type"
                    ),
                };
        }
        console.log("Error calling API. Returned data: %o", response.errors);
        const errorMessage = getErrorMessageFromApiResult(response);
        return { Result: undefined, Error: new Error(errorMessage) };
    } catch (error) {
        const errorMessage = getErrorMessage("calling the api", error);
        if (isUnauthorisedError(errorMessage)) {
            if (retryOnUnauthorised) {
                const renewed = await user.renewSession();
                if (renewed) return doCallApi(user, queryName, query, false);
            }
            user.signOut();
        }
        console.log(errorMessage);
        return { Result: undefined, Error: new Error(errorMessage) };
    }
}

export async function callApi<TType>(
    user: UserWrapper,
    queryName: string,
    query: GraphQLOptions
): Promise<ApiResult<TType>> {
    return doCallApi(user, queryName, query, true);
}

export async function retrieveLedgeUser(
    user: UserWrapper
): Promise<ApiResult<User>> {
    return callApi<User>(user, "getUserById", {
        query: getUserById,
        variables: { pk: user.sub },
    });
}

export async function retrieveDocuments(
    user: UserWrapper
): Promise<ApiResult<Array<Document>>> {
    return callApi<Array<Document>>(user, "getDocumentsForUser", {
        query: getDocumentsForUser,
    });
}

export async function retrieveDocumentTypes(
    user: UserWrapper
): Promise<ApiResult<Array<DocumentType>>> {
    return callApi<Array<DocumentType>>(user, "getDocumentTypes", {
        query: getDocumentTypes,
    });
}

export async function retrieveTemplates(
    user: UserWrapper
): Promise<ApiResult<Array<Application>>> {
    return callApi<Array<Application>>(user, "getApplicationTemplates", {
        query: getApplicationTemplates,
    });
}
