import { useState } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    DialogActions,
    Typography,
} from "@mui/material";
import { PageHeader } from "../common/pageHeader";

export type ApplicationSectionAddProps = {
    open: boolean;
    availableSections: string[];
    callback: (sectionName: string | undefined) => void;
    handleClose: (event: any) => void;
};

export function ApplicationSectionAdd({
    availableSections,
    callback,
    handleClose,
}: ApplicationSectionAddProps) {
    const [sectionName, setSectionName] = useState<string | undefined>(
        availableSections[0]
    );

    const handleCancel = (e) => {
        handleClose(e);
    };

    const handleUpdate = () => {
        callback(sectionName);
    };

    if (availableSections.length === 0)
        return (
            <Box sx={{ minWidth: "400px", maxWidth: "400px" }}>
                <PageHeader title="Add Document Group" />
                <hr color="error" />
                <Paper>
                    <List dense>
                        <ListItemButton disabled>
                            <ListItemText>
                                You have already included all of the available
                                sections
                            </ListItemText>
                        </ListItemButton>
                    </List>
                </Paper>
                <Typography
                    sx={{
                        color: "text.secondary",
                        fontSize: "smaller",
                        mt: 1,
                    }}
                >
                    You have already included all of the available sections
                </Typography>
                <Box sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
                    <DialogActions sx={{ mt: 1 }}>
                        <Button variant="outlined" onClick={handleCancel}>
                            OK
                        </Button>
                    </DialogActions>
                </Box>
            </Box>
        );

    return (
        <Box sx={{ minWidth: "400px", maxWidth: "400px" }}>
            <PageHeader title="Add Document Group" />
            <hr color="error" />
            <Box sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
                <Paper style={{ maxHeight: 150, overflow: "auto" }}>
                    <List dense>
                        {availableSections.map((row, index) => (
                            <ListItemButton
                                key={index}
                                onClick={(e) => setSectionName(row)}
                                selected={sectionName === row}
                            >
                                <ListItemText
                                    primary={row}
                                    primaryTypographyProps={{
                                        display: "inline",
                                        ml: 1,
                                        fontWeight:
                                            sectionName === row
                                                ? "bold"
                                                : undefined,
                                    }}
                                />
                            </ListItemButton>
                        ))}
                    </List>
                </Paper>
                <DialogActions sx={{ mt: 1 }}>
                    <Button variant="outlined" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleUpdate}>
                        Add
                    </Button>
                </DialogActions>
            </Box>
        </Box>
    );
}

export function ApplicationSectionAddDialog(props: ApplicationSectionAddProps) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogContent>
                <ApplicationSectionAdd {...props} />
            </DialogContent>
        </Dialog>
    );
}
