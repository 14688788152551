import { useState, useContext } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    FormControlLabel,
    Paper,
    DialogActions,
    List,
    ListItemButton,
    ListItemText,
    RadioGroup,
    Radio,
    Typography,
} from "@mui/material";
import { UserContext } from "../../common/userContext";
import { PageHeader } from "../common/pageHeader";
import { PageInfo } from "../common/pageInfo";
import { Document, DocumentType } from "../../API";
import {
    getDocumentTypeName,
    getDocumentTypeNameFromId,
} from "./applicationCommon";

enum AddMode {
    New = "New",
    Existing = "Existing",
}

export type ApplicationSectionDocumentAddProps = {
    open: boolean;
    documentTypes: DocumentType[];
    documents: Document[];
    callback: (
        documentType: DocumentType | undefined,
        documentMaybe: Document | undefined
    ) => void;
    handleClose: (event: any) => void;
};

export function ApplicationSectionDocumentAdd({
    documentTypes,
    documents,
    callback,
    handleClose,
}: ApplicationSectionDocumentAddProps) {
    const contextData = useContext(UserContext);

    const [pageError, setPageError] = useState<string>("");

    const [mode, setMode] = useState(
        documents.length === 0 ? AddMode.New : AddMode.Existing
    );

    const [selectedDocumentType, setSelectedDocumentType] =
        useState<DocumentType>(documentTypes[0]);

    const [selectedDocument, setSelectedDocument] = useState<
        Document | undefined
    >(undefined);

    const handleCancel = (e) => {
        handleClose(e);
    };

    const handleUpdate = () => {
        if (mode === AddMode.New) {
            if (!selectedDocumentType) {
                setPageError("Please select a document type first!");
                return;
            }

            callback(selectedDocumentType, undefined);
            return;
        }
        if (!selectedDocument) {
            setPageError("Please select a document first!");
            return;
        }
        callback(undefined, selectedDocument);
    };

    return (
        <Box sx={{ minWidth: "400px", maxWidth: "400px" }}>
            <PageHeader title="Add Item" />
            <hr color="error" />
            <PageInfo message={pageError} color="error" />
            <RadioGroup
                value={mode}
                sx={{ width: "100%" }}
                onChange={(e) => setMode(e.target.value as AddMode)}
            >
                <FormControlLabel
                    control={<Radio />}
                    key={AddMode.New}
                    value={AddMode.New}
                    label={"I have not yet uploaded this file to My Documents"}
                />

                <FormControlLabel
                    control={<Radio />}
                    key={AddMode.Existing}
                    value={AddMode.Existing}
                    label={
                        "I have already sourced and uploaded this file to My Documents"
                    }
                />
            </RadioGroup>

            {mode === AddMode.Existing && documents.length > 0 && (
                <>
                    <p>Please choose from the available documents</p>
                    <Paper style={{ maxHeight: 150, overflow: "auto" }}>
                        <List dense>
                            {documents.map((row, index) => (
                                <ListItemButton
                                    key={index}
                                    onClick={() => {
                                        setPageError("");
                                        setSelectedDocument(row);
                                    }}
                                    selected={selectedDocument?.id === row.id}
                                >
                                    <ListItemText
                                        primary={row.documentName}
                                        secondary={getDocumentTypeNameFromId(
                                            row.documentTypeId,
                                            contextData
                                        )}
                                        primaryTypographyProps={{
                                            display: "inline",
                                            ml: 1,
                                            fontWeight:
                                                selectedDocument?.id === row.id
                                                    ? "bold"
                                                    : undefined,
                                        }}
                                        secondaryTypographyProps={{
                                            display: "inline",
                                            //fontStyle: "italic",
                                            ml: 1,
                                        }}
                                    />
                                </ListItemButton>
                            ))}
                        </List>
                    </Paper>
                </>
            )}

            {mode === AddMode.Existing && documents.length === 0 && (
                <Typography
                    sx={{
                        color: "text.secondary",
                        fontSize: "smaller",
                        mt: 1,
                    }}
                >
                    There are no documents available. You can add some via the
                    My Documents page.
                </Typography>
            )}

            {mode === AddMode.New && (
                <>
                    <p>
                        Please choose a document type that you will later source
                        and upload
                    </p>
                    <Paper style={{ maxHeight: 150, overflow: "auto" }}>
                        <List
                            dense
                            sx={{
                                "&& .Mui-selected, && .Mui-selected:hover": {
                                    fontWeight: "bold",
                                },
                            }}
                        >
                            {documentTypes.map((row, index) => (
                                <ListItemButton
                                    key={index}
                                    onClick={() => {
                                        setPageError("");
                                        setSelectedDocumentType(row);
                                    }}
                                    selected={
                                        selectedDocumentType?.id === row.id
                                    }
                                >
                                    <ListItemText
                                        primary={getDocumentTypeName(row)}
                                        primaryTypographyProps={{
                                            display: "inline",
                                            ml: 1,
                                            fontWeight:
                                                selectedDocumentType?.id ===
                                                row.id
                                                    ? "bold"
                                                    : undefined,
                                        }}
                                    />
                                </ListItemButton>
                            ))}
                        </List>
                    </Paper>
                </>
            )}

            <DialogActions sx={{ mt: 1 }}>
                <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleUpdate}>
                    {mode === AddMode.New ? "Create" : "Add"}
                </Button>
            </DialogActions>
        </Box>
    );
}

export function ApplicationSectionDocumentAddDialog(
    props: ApplicationSectionDocumentAddProps
) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogContent>
                <ApplicationSectionDocumentAdd {...props} />
            </DialogContent>
        </Dialog>
    );
}
