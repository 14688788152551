import { Storage } from "aws-amplify";
import { Document, DocumentType } from "../API";
import { deleteDocument } from "../graphql/mutations";
import { UserWrapper } from "./userContext";
import { ErrorSessionLapsed, getErrorMessage } from "./errorUtils";
import { callApi } from "./apiUtils";
import JsZip from "jszip";
import FileSaver from "file-saver";
import { Promise } from "bluebird";
import { parsePathName } from "./typeUtils";

export function removeExtension(filename: string): string {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
}

export function getExtension(filename: string): string {
    return filename.slice(filename.lastIndexOf(".")) || "";
    //const match = filename.match(/(\..*)$/);
    //return  match && match.length === 2 ? match[1] : "";
}

export function getFileName(document: Document) {
    const origExtension = getExtension(document.originalFileName);
    const currentExtension = getExtension(document.documentName);
    if (currentExtension === origExtension) return document.documentName;
    return `${document.documentName}${origExtension}`;
}

export function getDocumentS3Key(documentId: string): string {
    // return `${userId}_${documentId}`;
    return `${documentId}`;
}

export async function uploadDocument(
    user: UserWrapper,
    key: string,
    file: File
): Promise<Error | undefined> {
    try {
        const okToProceed = await user.checkSessionActive();
        if (!okToProceed) return ErrorSessionLapsed;

        const result = await Storage.put(key, file, {
            level: "private",
            contentType: file.type, // contentType is optional
        });
        console.log("Successfully uploaded document: %o", result);
        return undefined;
    } catch (error) {
        const errorMessage = getErrorMessage("uploading the document", error);
        console.log(errorMessage);
        return new Error(errorMessage);
    }
}

export function downloadBlob(blob: any, filename: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener("click", clickHandler);
        }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
}

export type DownLoad = {
    fileName: string;
    contentType: string;
    blob: any;
};

function getZipDownloadFileName(
    document: Document,
    documentTypes: DocumentType[]
): string {
    const fileName = getFileName(document);
    const documentType = documentTypes.find(
        (d) => d.id === document.documentTypeId
    );
    const folderPath = documentType
        ? documentType.hasMany
            ? documentType.pathName
            : parsePathName(documentType.pathName).path
        : "";

    return `${folderPath}/${fileName}`;
}

async function retrieveDocument(
    document: Document,
    fileNameGetter: (Document) => string
): Promise<DownLoad> {
    try {
        const downloadFileName = fileNameGetter(document);
        if (!document.uploaded)
            return {
                fileName: downloadFileName,
                contentType: document.contentType,
                blob: undefined,
            };
        const key = getDocumentS3Key(document.id);
        const result = await Storage.get(key, {
            level: "private",
            download: true,
        });
        return {
            fileName: downloadFileName,
            contentType: document.contentType,
            blob: result?.Body,
        };
    } catch (error) {
        console.log(error);
        return {
            fileName: document.documentName,
            contentType: document.contentType,
            blob: undefined,
        };
    }
}

async function downloadDocumentsForZip(
    documents: Document[],
    documentTypes: DocumentType[]
): Promise<DownLoad[]> {
    return Promise.map<Document, DownLoad>(
        documents,
        async (document: Document) =>
            retrieveDocument(document, (d) =>
                getZipDownloadFileName(d, documentTypes)
            ),
        { concurrency: 4 }
    );
}

function exportZip(downloads: any) {
    const zip = JsZip();
    downloads.forEach((download: any, i: any) => {
        if (!download.blob) return;
        const path = download.fileName.split("/");
        var downloadZip = zip;
        for (var j = 0; j < path.length; ++j) {
            const nextPath = path[j];
            if (!nextPath) continue;
            if (j < path.length - 1)
                downloadZip = downloadZip.folder(nextPath) ?? zip;
            else downloadZip = downloadZip.file(nextPath, download.blob);
        }
    });
    zip.generateAsync({ type: "blob" }).then((zipFile) => {
        const currentDate = new Date().getTime();
        const zipFileName = `LedgeMed-${currentDate}.zip`;
        return FileSaver.saveAs(zipFile, zipFileName);
    });
}

/* 
    https://huynvk.dev/blog/download-files-and-zip-them-in-your-browsers-using-javascript
    
    REVISIT Server side option were - but requires privileges for private docs in lambda
    
    https://dev.to/ldsrogan/aws-sdk-with-javascript-multi-files-download-from-s3-5118
    https://amiantos.net/zip-multiple-files-on-aws-s3/
 */
export async function downloadAndZip(
    user: UserWrapper,
    documents: Document[],
    documentTypes: DocumentType[]
): Promise<Error | undefined> {
    try {
        const okToProceed = await user.checkSessionActive();
        if (!okToProceed) return ErrorSessionLapsed;

        await downloadDocumentsForZip(
            documents.filter((d) => d.uploaded),
            documentTypes
        ).then(exportZip);

        return undefined;
    } catch (error) {
        const errorMessage = getErrorMessage("calling the api", error);
        console.log(errorMessage);
        return new Error(errorMessage);
    }
}

async function retrieveSingle(
    user: UserWrapper,
    document: Document
): Promise<[Error | undefined, DownLoad | undefined]> {
    try {
        const okToProceed = await user.checkSessionActive();
        if (!okToProceed) return [ErrorSessionLapsed, undefined];

        const download = await retrieveDocument(document, getFileName);
        if (!download.blob)
            return [
                new Error("There was an error dwnloading the file!"),
                undefined,
            ];
        //FileSaver.saveAs(fileData.blob, fileData.fileName);
        return [undefined, download];
    } catch (error) {
        const errorMessage = getErrorMessage("calling the api", error);
        console.log(errorMessage);
        return [new Error(errorMessage), undefined];
    }
}

export async function deleteSingle(
    user: UserWrapper,
    documentId: string
): Promise<Error | undefined> {
    try {
        const okToProceed = await user.checkSessionActive();
        if (!okToProceed) return ErrorSessionLapsed;

        const key = getDocumentS3Key(documentId);
        const removeResult = await Storage.remove(key, {
            level: "private",
        });
        if (!removeResult) {
            const errorMsg = "Error deleting document: " + documentId;
            console.log(errorMsg);
            return new Error(errorMsg);
        }
        const deletedDocument = await callApi<Document>(
            user,
            "deleteDocument",
            {
                query: deleteDocument,
                variables: { pk: documentId },
            }
        );
        if (!deletedDocument.Result) {
            const errorMsg =
                "Error deleting document record: " + deletedDocument.Error;
            console.log(errorMsg);
            return new Error(errorMsg);
        }
        return undefined;
    } catch (error) {
        const errorMsg = "Error deleting document record: " + error;
        console.log(errorMsg);
        return new Error(errorMsg);
    }
}

export async function deleteMany(
    user: UserWrapper,
    documentIds: [string]
): Promise<Error | undefined> {
    const okToProceed = await user.checkSessionActive();
    if (!okToProceed) return ErrorSessionLapsed;

    const results = await Promise.map<string, Error | undefined>(
        documentIds,
        async (documentId: string) => {
            return deleteSingle(user, documentId);
        },
        { concurrency: 4 }
    );
    return results.find((r) => r !== undefined);
}

export async function getPresignedUrl(
    user: UserWrapper,
    document: Document
): Promise<[Error | undefined, string, string | undefined]> {
    try {
        const fileName = getFileName(document);
        const okToProceed = await user.checkSessionActive();
        if (!okToProceed) return [ErrorSessionLapsed, fileName, undefined];
        const key = getDocumentS3Key(document.id);
        const signedURL = await Storage.get(key, {
            level: "private",
            contentType: `${document.contentType}`,
            expires: 180,
            //contentDisposition: "inline"
        });
        return [undefined, fileName, signedURL];
    } catch (error) {
        const errorMessage = `Unable to view document! ${error}`;
        return [new Error(errorMessage), "", undefined];
    }
}

export async function userDocumentExists(
    user: UserWrapper,
    document: Document
): Promise<[Error | undefined, boolean]> {
    try {
        const okToProceed = await user.checkSessionActive();
        if (!okToProceed) return [ErrorSessionLapsed, false];
        const key = getDocumentS3Key(document.id);
        const existing = await Storage.list(key, {
            level: "private",
        });
        const exists = existing.length === 1;
        return [undefined, exists];
    } catch (error) {
        const errorMessage = `Unable to view document! ${error}`;
        return [new Error(errorMessage), false];
    }
}

function showDownload(download: DownLoad) {
    const file = new File([download.blob], download.fileName, {
        type: download.contentType,
    });
    const url = window.URL.createObjectURL(file);
    window.open(url, download.fileName);
    // var link = document.createElement("a");
    // link.href = url;
    // link.target= "_new";
    // //link.download = download.fileName;
    // link.click();
    // setTimeout(function () {
    //     // For Firefox it is necessary to delay revoking the ObjectURL
    //     window.URL.revokeObjectURL(data);
    // }, 100);
}

export async function viewDocument(
    user: UserWrapper,
    document: Document
): Promise<Error | undefined> {
    try {
        const [error, filename, signedUrl] = await getPresignedUrl(
            user,
            document
        );
        if (error) return error;
        window.open(signedUrl, filename);
        return undefined;
        // const [result, download] = await downloadSingle(user, document);
        // if (result.error) {
        //     return new Error(result.error);
        // }
        // showDownload(download!);
    } catch (error) {
        return new Error(getErrorMessage("openDocumentInNewTab", error));
    }
}

export async function downloadDocument(
    user: UserWrapper,
    document: Document
): Promise<Error | undefined> {
    try {
        const [error, download] = await retrieveSingle(user, document);
        if (error) return error;
        const fileName = getFileName(document);
        FileSaver.saveAs(download?.blob, fileName);
        return undefined;
    } catch (error) {
        return new Error(getErrorMessage("openDocumentInNewTab", error));
    }
}
