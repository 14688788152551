import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Tooltip,
} from "@mui/material";
import {
    useGridApiRef,
    DataGridPro,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowId,
    GridFilterOperator,
    GridFilterItem,
    GridColDef,
    GridCellParams,
    GridFilterModel,
    GridLinkOperator,
    GridRowParams,
    GridCallbackDetails,
    GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import { downloadAndZip } from "../../common/documentUtils";
import {
    ApplicationEditMode,
    getApplicationPercentComplete,
} from "./applicationCommon";
import { GridToolbarSearch } from "../common/gridToolbarSearch";
import { ApplicationAddDialog } from "./applicationAdd";
import { UserContext } from "../../common/userContext";
import { Application, Document } from "../../API";
import { callApi } from "../../common/apiUtils";
import {
    ConfirmDialog,
    ConfirmDialogProps,
    ConfirmDialogPropsClosed,
} from "../common/confirmDialog";
import { PageHeader } from "../common/pageHeader";
import { deleteApplication } from "../../graphql/mutations";
import * as utils from "../../common/typeUtils";

export const getApplicationsForUser = /* GraphQL */ `
    query GetApplicationsForUser {
        getApplicationsForUser {
            id
            name
            userId
            state
            sector
            hospital
            positionType
            notes
            level
            dueDate
            created
            updated
            applicationSections {
                id
                applicationId
                name
                created
                updated
                applicationDocuments {
                    id
                    applicationSectionId
                    documentTypeId
                    documentId
                    created
                    updated
                }
            }
        }
    }
`;

type EditToolbarProps = {
    addRow: () => void;
    // toggleSelection: () => void;
    search: (text: String) => void;
};

function EditToolbar({ addRow, search }: EditToolbarProps) {
    const handleAddClick = async () => {
        addRow();
    };

    return (
        <GridToolbarContainer>
            <GridToolbarSearch search={search} />
            <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddClick}
            >
                Create New
            </Button>
        </GridToolbarContainer>
    );
}

EditToolbar.propTypes = {
    // apiRef: PropTypes.shape({
    //   current: PropTypes.object.isRequired,
    // }).isRequired,
    addRow: PropTypes.func.isRequired,
    toggleSelection: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
};

export default function MyApplications() {
    const navigate = useNavigate();
    const contextData = useContext(UserContext);
    const apiRef = useGridApiRef();
    const filterText = useRef("");
    const [rows, setRows] = useState<Application[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([]);
    const [checkboxSelection, setCheckboxSelection] = useState(false);
    const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(
        undefined
    );
    const [processing, setProcessing] = useState<boolean>(true);
    const [backdropOpen, setBackdropOpen] = useState<boolean>(false);
    const [confirmDialogProps, setConfirmDialogProps] =
        useState<ConfirmDialogProps>(ConfirmDialogPropsClosed);
    const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);

    const addRow = () => {
        setAddDialogOpen(true);
    };

    const toggleSelection = () => {
        const nextCheckboxSelection = !checkboxSelection;
        setCheckboxSelection(nextCheckboxSelection);
        if (!nextCheckboxSelection) setRowsSelected([]);
        applyFiltering(nextCheckboxSelection);
    };

    const rowDoubleClick = (
        params: GridRowParams,
        event: any,
        details: GridCallbackDetails
    ) => {
        event.defaultMuiPrevented = true;
        navigate(
            `/applicationEdit/${params.row.id}/${
                ApplicationEditMode[ApplicationEditMode.Edit]
            }`
        );
    };

    const updateRow = (id: string) => (event: any) => {
        event.stopPropagation();
        navigate(
            `/applicationEdit/${id}/${
                ApplicationEditMode[ApplicationEditMode.Edit]
            }`
        );
    };

    const copyRow = (id: string) => (event: any) => {
        event.stopPropagation();
        navigate(
            `/applicationEdit/${id}/${
                ApplicationEditMode[ApplicationEditMode.AddClone]
            }`
        );
    };

    const getRowDocuments = (id: string) => {
        const row = apiRef.current.getRow(id) as Application;
        let documents: Document[] = [];
        for (let section of row.applicationSections) {
            for (let applicationDocument of section.applicationDocuments) {
                const document = contextData.documents.find(
                    (d) => d.id === applicationDocument.documentId
                );
                if (document) documents = [...documents, document];
            }
        }
        return documents;
    };

    const downloadRow = (id: string) => async (event: any) => {
        setBackdropOpen(true);
        const documents = getRowDocuments(id);
        const error = await downloadAndZip(
            contextData.user,
            documents,
            contextData.documentTypes
        );
        if (error) {
            console.log(error.message);
        }
        setBackdropOpen(false);
    };

    const deleteRow = (id: string) => async (event: any) => {
        event.stopPropagation();
        const row = apiRef.current.getRow(id);
        if (!row) {
            console.log(`Edit row for ${id} was null!`);
            return;
        }
        let props: ConfirmDialogProps = {
            open: true,
            title: "Delete Application Checklist",
            description: `Are you sure that you would like to delete '${row.name}'?`,
            action: "Delete",
            data: id,
            callback: handleConfirmDialogResult,
        };
        setConfirmDialogProps(props);
    };

    const getRowClassName = (params: any) => {
        const isComplete =
            getApplicationPercentComplete(params.row, contextData) >= 100;
        if (isComplete) return "";
        const isExpired = utils.hasExpired(params.row.dueDate);
        return isExpired ? "super-app-theme--expired" : "";
    };

    const handleConfirmDialogResult = async (
        action: string,
        data: any,
        result: boolean
    ) => {
        setConfirmDialogProps(ConfirmDialogPropsClosed);

        if (!result || action !== "Delete") return;

        const row = apiRef.current.getRow(data as string);
        if (!row) {
            console.log(`Edit row for ${data} was null!`);
            return;
        }

        try {
            setProcessing(true);
            const deletedApplication = await callApi<Application>(
                contextData.user,
                "deleteApplication",
                {
                    query: deleteApplication,
                    variables: { pk: row.id },
                }
            );
            if (!deletedApplication.Result) {
                console.log(
                    "Failed to delete application record: " +
                        deletedApplication.Error
                );
            }
            apiRef.current.updateRows([{ id: row.id, _action: "delete" }]);
        } catch (error) {
            console.log(error);
        } finally {
            setProcessing(false);
        }
    };

    const selectedOperator: GridFilterOperator = {
        label: "Selected",
        value: "selected",
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
            if (
                !filterItem.columnField ||
                !filterItem.value ||
                !filterItem.operatorValue
            ) {
                return null;
            }

            return (params: GridCellParams): boolean => {
                const id = params.value as string;
                return (
                    checkboxSelection === false ||
                    rowsSelected.find((selected) => selected === id) !==
                        undefined
                );
            };
        },
        InputComponentProps: { type: "string" },
    };

    const search = (value: string) => {
        filterText.current = value;
        applyFiltering(checkboxSelection);
    };

    const applyFiltering = (includeSelected: boolean) => {
        const value = filterText.current;
        if (!value) {
            setFilterModel({ items: [] });
            return;
        }
        var filterItems = [
            {
                id: 1,
                columnField: "name",
                operatorValue: "contains",
                value: value,
            },
            {
                id: 2,
                columnField: "hospital",
                operatorValue: "contains",
                value: value,
            },
            {
                id: 3,
                columnField: "type",
                operatorValue: "contains",
                value: value,
            },
        ];
        if (includeSelected)
            filterItems = [
                ...filterItems,
                {
                    id: 4,
                    columnField: "id",
                    operatorValue: "selected",
                    value: value,
                },
            ];

        const filter: GridFilterModel = {
            items: filterItems,
            linkOperator: GridLinkOperator.Or,
        };
        setFilterModel(filter);
    };

    useEffect(() => {
        let isCancelled = false;
        const getRows = async () => {
            callApi<Application[]>(
                contextData.user,
                "getApplicationsForUser",
                {
                    query: getApplicationsForUser,
                }
            ).then((applications) => {
                if (isCancelled) return;
                if (applications.Result) {
                    setRows(applications.Result);
                }
                setProcessing(false);
            });
        };
        getRows();
        return () => {
            isCancelled = true;
        };
    }, [contextData.user]);

    const columns = [
        {
            field: "id",
            headerName: "Id",
            hide: true,
            type: "string",
            filterOperators: [selectedOperator],
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "hospital",
            headerName: "Hospital/Centre",
            flex: 1,
        },
        {
            field: "level",
            headerName: "Level",
            flex: 1,
        },
        {
            field: "created", // just using a dummy unused field here so that the header is rendered ok, revisit
            headerName: "Complete",
            width: 100,
            resizable: false,
            renderCell: (params: GridRenderCellParams<string>) => {
                const percentComplete = getApplicationPercentComplete(
                    params.row,
                    contextData
                );
                return percentComplete >= 100 ? (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <p>{percentComplete}%</p>
                        <CheckCircleIcon
                            color="success"
                            sx={{ ml: "3px", fontSize: "medium" }}
                        />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <p>{percentComplete}%</p>
                        <WarningOutlinedIcon
                            color="warning"
                            sx={{ ml: "3px", fontSize: "medium" }}
                        />
                    </Box>
                );
            },
        },
        {
            field: "dueDate",
            headerName: "Due Date",
            width: 100,
            resizable: false,
            type: "date",
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 150,
            resizable: false,
            cellClassName: "actions",
            getActions: ({ id }: any) => {
                const nothingToDownload = getRowDocuments(id).length === 0;

                return [
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Edit">
                                <EditIcon />
                            </Tooltip>
                        }
                        label="Edit"
                        className="textPrimary"
                        onClick={updateRow(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Clone">
                                <ContentCopyIcon />
                            </Tooltip>
                        }
                        label="Copy"
                        className="textPrimary"
                        onClick={copyRow(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Download">
                                <DownloadIcon />
                            </Tooltip>
                        }
                        label="Download"
                        className="textPrimary"
                        onClick={downloadRow(id)}
                        color="inherit"
                        disabled={nothingToDownload}
                        //readOnly={nothingToDownload}
                    />,
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Delete">
                                <DeleteIcon />
                            </Tooltip>
                        }
                        label="Delete"
                        onClick={deleteRow(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: 1,
                width: 1,
                "& .actions": {
                    color: "text.secondary",
                },
                "& .textPrimary": {
                    color: "text.primary",
                },
                "& .super-app-theme--expired": {
                    bgcolor: "mistyrose",
                },
            }}
        >
            <ConfirmDialog {...confirmDialogProps} />
            <ApplicationAddDialog
                handleClose={(e) => setAddDialogOpen(false)}
                open={addDialogOpen}
            />
            <PageHeader title="My Applications" />
            <p>
                Here you can easily create and track your job application
                checklists. Each time you create a new job application you’ll
                also create a checklist for that application so you can keep on
                top of the documents and material required for that application.
                We have already made some templates for you to choose from that
                you will see once you create a new application.{" "}
            </p>
            <DataGridPro
                autoPageSize
                pagination
                disableColumnReorder={true}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                loading={processing}
                checkboxSelection={checkboxSelection}
                onSelectionModelChange={(selected) => {
                    setRowsSelected(selected);
                    applyFiltering(checkboxSelection);
                }}
                selectionModel={rowsSelected}
                filterModel={filterModel}
                editMode="row"
                getRowClassName={(params) => `${getRowClassName(params)}`}
                onRowDoubleClick={rowDoubleClick}
                components={{
                    Toolbar: EditToolbar,
                }}
                componentsProps={{
                    toolbar: { addRow, toggleSelection, search },
                }}
                initialState={{
                    // pinnedColumns: { right: ["actions"] },
                    sorting: {
                        sortModel: [{ field: "dueDate", sort: "desc" }],
                    },
                }}
            />
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}
