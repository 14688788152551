import { Box, Grid, TextField, Typography } from "@mui/material";
import { UserInput } from "../../API";
import * as common from "./profileCommon";

export type ApplicationSectionProps = {
    userInput: UserInput;
};

export function ProfileFinancialDetails({
    userInput,
    setValue,
    gridItemWidth,
}: common.ProfileSectionProps) {
    return (
        <Box
            sx={{
                boxSizing: "border-box",
                p: 1,
                borderRadius: "5px",
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">Financial Details</Typography>
                    <p>
                        This is not required, but it’s a useful way to
                        centralise all your details and make your applications
                        easier.
                    </p>
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                        inputProps={{ maxLength: 256 }}
                        name="bankName"
                        label="Bank Name"
                        value={userInput.bankName ?? ""}
                        onChange={(e) =>
                            setValue(e.target.name, e.target.value)
                        }
                        sx={{ backgroundColor: "white" }}
                    />
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                        inputProps={{ maxLength: 256 }}
                        name="bankBsb"
                        label="BSB"
                        value={userInput.bankBsb ?? ""}
                        onChange={(e) =>
                            setValue(e.target.name, e.target.value)
                        }
                        sx={{ backgroundColor: "white" }}
                    />
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                        inputProps={{ maxLength: 256 }}
                        name="bankAccountNumber"
                        label="Account Number"
                        value={userInput.bankAccountNumber ?? ""}
                        onChange={(e) =>
                            setValue(e.target.name, e.target.value)
                        }
                        sx={{ backgroundColor: "white" }}
                    />
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                        inputProps={{ maxLength: 256 }}
                        name="superannuationNumber"
                        label="Superannuation"
                        value={userInput.superannuationNumber ?? ""}
                        onChange={(e) =>
                            setValue(e.target.name, e.target.value)
                        }
                        sx={{ backgroundColor: "white" }}
                    />
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                        inputProps={{ maxLength: 256 }}
                        name="abnNumber"
                        label="ABN"
                        value={userInput.abnNumber ?? ""}
                        onChange={(e) =>
                            setValue(e.target.name, e.target.value)
                        }
                        sx={{ backgroundColor: "white" }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
