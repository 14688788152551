import { useState, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Grid, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Document } from "../../API";
import { EditResult, EditProps, EditPropsClosed } from "../../common/types";
import { DocumentEditDialog } from "../documents/documentEdit";
import { ContextDataInterface, UserContext } from "../../common/userContext";
import { DocumentsCard } from "./documentsCard";
import {
    pathSeparator,
    groupBy,
    getDocumentGroups,
} from "../../common/typeUtils";
import * as utils from "../../common/typeUtils";
import { uniq } from "lodash";

type cardInfo = {
    name: string;
    info: string | null | undefined;
    documents: Document[];
};

function getCards(contextData: ContextDataInterface): cardInfo[] {
    let groups = getDocumentGroups(contextData.documentTypes);
    const documentGroupsMap = groupBy(contextData.documents, (d) =>
        utils.getDocumentGroup(d, contextData.documentTypes)
    );
    groups = uniq([...documentGroupsMap.keys(), ...groups]).sort();
    return groups.map((g) => {
        const documentType = contextData.documentTypes.find(
            (d) => d.pathName === pathSeparator + g
        );
        // console.log(
        //     "Group lookup:" + g + ", found: " + documentType?.pathName ??
        //         "Missing"
        // );
        const documents = documentGroupsMap.get(g);
        return {
            name: g,
            info: documentType?.info,
            documents: documents ?? [],
        };
    });
}

export function DocumentsCardView() {
    const contextData = useContext(UserContext);
    const theme = useTheme();
    const useTwoColumns = useMediaQuery(theme.breakpoints.up("md"));

    const cardDatas = getCards(contextData);

    const [editDialogProps, setEditDialogProps] =
        useState<EditProps>(EditPropsClosed);

    const addRow = () => {
        let editProps: EditProps = {
            open: true,
            id: "add",
            data: undefined,
            callback: handlAddResult,
            handleClose: (e) => setEditDialogProps(EditPropsClosed),
        };
        setEditDialogProps(editProps);
    };

    const handlAddResult = (editResult: EditResult, document: any) => {
        setEditDialogProps(EditPropsClosed);
        switch (editResult) {
            case EditResult.Added:
                if (document) {
                    contextData.setDocuments([
                        ...contextData.documents,
                        document,
                    ]);
                }
                break;
            default:
                break;
        }
    };

    if (useTwoColumns && cardDatas.length > 1)
        return (
            <Box>
                <DocumentEditDialog {...editDialogProps} />

                <Button variant="contained" onClick={addRow}>
                    + UPLOAD DOCUMENT
                </Button>

                <Grid container spacing={1} sx={{ mt: 1 }}>
                    <Grid key="col1" item xs={6}>
                        {cardDatas
                            .filter((card, index) => index % 2 === 0)
                            .map((card) => (
                                <Box key={card.name} sx={{ mt: 1 }}>
                                    <DocumentsCard
                                        name={card.name}
                                        info={card.info}
                                        documents={card.documents}
                                    />
                                </Box>
                            ))}
                    </Grid>

                    <Grid key="col2" item xs={6}>
                        {cardDatas
                            .filter((card, index) => index % 2 === 1)
                            .map((card) => (
                                <Box key={card.name} sx={{ mt: 1 }}>
                                    <DocumentsCard
                                        name={card.name}
                                        info={card.info}
                                        documents={card.documents}
                                    />
                                </Box>
                            ))}
                    </Grid>
                </Grid>
            </Box>
        );

    return (
        <Box>
            <DocumentEditDialog {...editDialogProps} />

            <Button
                startIcon={<AddIcon />}
                variant="contained"
                onClick={addRow}
            >
                UPLOAD DOCUMENT
            </Button>

            {cardDatas.map((card) => (
                <Box key={card.name} sx={{ mt: 1 }}>
                    <DocumentsCard
                        name={card.name}
                        info={card.info}
                        documents={card.documents}
                    />
                </Box>
            ))}
        </Box>
    );
}
