import { createContext } from "react";
import {
    IdToken,
    User as UserOidc,
    LogoutOptions,
    GetTokenSilentlyOptions,
    GetIdTokenClaimsOptions,
} from "@auth0/auth0-spa-js";
import * as auth0 from "@auth0/auth0-react";
import { Auth, Amplify } from "aws-amplify";
import { ICredentials, NonRetryableError } from "@aws-amplify/core";
import { getErrorMessage } from "./errorUtils";
import { User, Document, DocumentType, Application } from "../API";
import { MemoryStorage } from "@aws-amplify/core";
// import { LedgeStorage } from "./storage";

import aws_exports from "../aws-exports";

function amplifyConfigure(
    getIdToken: (
        options?: GetIdTokenClaimsOptions | undefined
    ) => Promise<IdToken | undefined>
): void {
    const oauthDomain = aws_exports.openid_domain;

    const oauth = {
        domain: oauthDomain,
        scope: ["openid", "email", "profile", "phone"],
        //redirectSignIn : TODO,
        //redirectSignOut : TODO,
        responseType: "code",
    };

    const oauthRefreshHandler = () => {
        return new Promise((res: any, rej) => {
            console.log("amplifyRefreshHandler");
            getIdToken()
                .then((token) => {
                    if (token) {
                        const data = {
                            token: token.__raw, // the token from the provider
                            expires_at: token.exp! * 1000, // the timestamp when the token expires (in milliseconds)
                            //identity_id: identityId, // optional, the identityId for the credentials
                        };
                        res(data);
                    } else {
                        rej(
                            "oauthRefreshHandler: error retrieving the the new session token"
                        );
                    }
                })
                .catch((err) => {
                    rej(
                        new NonRetryableError(
                            "oauthRefreshHandler: failed to refresh to oauth token! " +
                                err
                        )
                    );
                });
        });
    };

    Amplify.configure({
        ...aws_exports,
        storage: MemoryStorage, // LedgeStorage,
        Auth: {
            oauth: oauth,
        },
        refreshHandlers: {
            [oauthDomain]: oauthRefreshHandler,
        },
    });
}

async function amplifySignIn(token: IdToken): Promise<ICredentials> {
    // LedgeStorage.setFederatedInfo(JSON.stringify(token));
    return await Auth.federatedSignIn(
        aws_exports.openid_domain,
        {
            token: token?.__raw ?? "",
            expires_at: token?.exp! * 1000, // the expiration timestamp
        },
        {
            name: token?.sub ?? "", // the user name
            email: token?.email ?? "", // Optional, the email address
        }
    );
}

export const roleClaimsKey = "https://ledgemed.com.au/roles";
export const roleAdmin = "Admin";

// https://github.com/aws-amplify/amplify-js/blob/main/packages/core/src/OAuthHelper/GoogleOAuth.ts#L25

export class UserWrapper {
    private initialised: boolean = false;
    private lastSessionCheckSeconds: number = 0;

    oidcUser: UserOidc;
    email: string;
    sub: string;
    identityId: string = "";
    idToken: IdToken = { __raw: "" };
    admin: boolean = false;

    getAccessTokenSilently: (
        options?: GetTokenSilentlyOptions | undefined
    ) => Promise<string>;
    getIdTokenClaims: (
        options?: GetIdTokenClaimsOptions | undefined
    ) => Promise<IdToken | undefined>;
    logOut: (options?: LogoutOptions) => void;

    constructor(
        oidcUser: UserOidc,
        getAccessTokenSilently: (
            options?: GetTokenSilentlyOptions | undefined
        ) => Promise<string>,
        getIdTokenClaims: (
            options?: GetIdTokenClaimsOptions | undefined
        ) => Promise<IdToken | undefined>,
        logOut: (options?: LogoutOptions) => void
    ) {
        this.oidcUser = oidcUser;
        this.email = oidcUser.email ?? "";
        this.sub = oidcUser.sub ?? "";
        this.getAccessTokenSilently = getAccessTokenSilently;
        this.getIdTokenClaims = getIdTokenClaims;
        this.logOut = logOut;
    }

    async initialise(): Promise<Error | undefined> {
        try {
            const idToken = await this.getIdTokenClaims();
            if (!idToken)
                return new Error("The id token could not be retrieved!");
            //console.log(idToken.__raw);
            amplifyConfigure(this.getIdTokenClaims);
            const cred = await amplifySignIn(idToken);
            this.identityId = cred.identityId;
            this.idToken = idToken;
            this.lastSessionCheckSeconds = Math.round(Date.now() / 1000);
            const rolesArray = idToken[roleClaimsKey] as [string];
            this.admin = rolesArray && rolesArray.includes(roleAdmin);
            this.initialised = true;
        } catch (e) {
            return new Error(getErrorMessage("", e));
        }
    }

    async renewSession(): Promise<IdToken | undefined> {
        try {
            //console.log(this.idToken.exp);
            await this.getAccessTokenSilently();
            const idToken = await this.getIdTokenClaims();
            if (!idToken)
                throw new Error("The id token could not be retrieved!");
            this.idToken = idToken;
            //console.log(this.idToken.exp);
            return idToken;
        } catch (e) {
            const message = getErrorMessage("renewSession", e);
            console.log(message);
            if ((e as any)?.error === "login_required") {
                console.log("Signing out - login required");
                this.signOut();
            }
            //
            return undefined;
        }
    }

    async checkSessionActive(): Promise<boolean> {
        // TODO Synchronize? Lock?
        if (this.initialised === false)
            throw "The user session has not been initialised!";
        const nowSeconds = Math.round(Date.now() / 1000);
        const elapsedSinceLastCheck = nowSeconds - this.lastSessionCheckSeconds;
        const expiresInSeconds = this.idToken.exp ?? nowSeconds;
        const expires = expiresInSeconds - nowSeconds;
        // Don't need to check if more than fifteen minutes to expiry
        if (elapsedSinceLastCheck < 900 || expires > 900) return true;
        this.lastSessionCheckSeconds = nowSeconds;
        console.log(`Session expiring in ${expires} seconds, renewing token!`);
        const renewResult = await this.renewSession();
        return renewResult !== undefined;
    }

    signOut(): void {
        try {
            Auth.signOut();
        } catch (e) {
            console.log(e);
        }
        this.logOut();
    }
}

export interface ContextDataInterface {
    user: UserWrapper;
    ledgeUser: User | undefined;
    documents: Document[];
    documentTypes: DocumentType[];
    templates: Application[];
    setLedgeUser: (user?: User | undefined) => void;
    setDocuments: (documents: Document[]) => void;
    setDocumentTypes: (documentTypes: DocumentType[]) => void;
    setTemplates: (templates: Application[]) => void;
}

export function getDocumentTypeFromContext(
    contextData: ContextDataInterface,
    id: string
): DocumentType | undefined {
    const documentType = contextData.documentTypes.find(
        (next) => next.id === id
    );
    if (documentType === null) return undefined;
    return documentType;
}

export function getDocumentTypeInfoFromContext(
    contextData: ContextDataInterface,
    id: string
): string | undefined {
    const documentType = getDocumentTypeFromContext(contextData, id);
    if (documentType?.info === null) return undefined;
    return documentType?.info;
}

export function getInitials(firstName: string, lastName: string): string {
    const initials = `${firstName[0] ?? ""}${lastName[0] ?? ""}`;
    return initials;
}

export const UserContext = createContext<ContextDataInterface>(
    {} as ContextDataInterface
);
