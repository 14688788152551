import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Backdrop,
    Box,
    Button,
    Divider,
    Grid,
    CircularProgress,
    LinearProgress,
    Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { callApi } from "../../common/apiUtils";
import { ConfirmDialogUnsavedChanges } from "../common/confirmDialogUnsavedChanges";
import { UserContext } from "../../common/userContext";
import { useCallbackPrompt } from "../../common/useCallbackPrompt";
import { getErrorMessage } from "../../common/errorUtils";
import { PageHeader } from "../common/pageHeader";
import { PageInfo } from "../common/pageInfo";
import { ApplicationDetails } from "./applicationDetails";
import { ConfirmDialog, ConfirmDialogProps } from "../common/confirmDialog";
import { ApplicationSectionEdit } from "./applicationSection";
import {
    ApplicationSectionAddDialog,
    ApplicationSectionAddProps,
} from "./applicationSectionAddDialog";
import {
    Application,
    ApplicationInput,
    ApplicationSectionInput,
    Document,
    DocumentType,
} from "../../API";
import { createApplication, updateApplication } from "../../graphql/mutations";
import { getDocumentGroups } from "../../common/typeUtils";
import * as common from "./applicationCommon";

const { v4: uuid } = require("uuid");

function getSectionOrNew(
    applicationId: string,
    name: string,
    sections: common.ApplicationSectionData[]
): common.ApplicationSectionData {
    const existing = sections.find((s) => s.applicationSection.name === name);
    if (existing) return existing;
    const newSection: ApplicationSectionInput = {
        isNew: true,
        id: uuid(),
        applicationId: applicationId,
        name: name,
        applicationDocuments: [],
    };
    const newData: common.ApplicationSectionData = {
        applicationSection: newSection,
        documents: [],
    };
    return newData;
}

function getSectionsExcept(
    name: string,
    sections: common.ApplicationSectionData[]
): common.ApplicationSectionData[] {
    return [...sections.filter((s) => s.applicationSection.name !== name)];
}

export default function ApplicationEdit() {
    const { id, mode } = useParams();
    const editMode = common.ApplicationEditMode[mode ?? "AddBlank"];
    const isNew =
        editMode === common.ApplicationEditMode.AddBlank ||
        editMode === common.ApplicationEditMode.AddClone ||
        editMode === common.ApplicationEditMode.AddFromTemplate;
    const navigate = useNavigate();
    const navigateAway = useRef(false);
    const validateApplicationDetailsRef = useRef<() => boolean>(() => false);
    const contextData = useContext(UserContext);
    const [applicationOrig, setApplicationOrig] = useState<ApplicationInput>(
        {} as ApplicationInput
    );
    const [applicationDetails, setApplicationDetails] =
        useState<ApplicationInput>();
    const [pageError, setPageError] = useState<string>("");
    const [basedOn, setBasedOn] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [backdropOpen, setBackdropOpen] = useState<boolean>(true);
    const [sections, setSections] = useState<common.ApplicationSectionData[]>(
        []
    );
    const [percentComplete, setPercentComplete] = useState(0);
    // https://www.linkedin.com/pulse/provide-callback-usestate-hook-like-setstate-saransh-kataria
    const [navigateRequest, setNavigateRequest] = useState(new Date());
    const [unsavedChanges, setUnsavedChanges] = useState(isNew);
    const [sectionAddProps, setSectionAddProps] =
        useState<ApplicationSectionAddProps>({
            open: false,
            availableSections: [],
            callback: (s) => {},
            handleClose: (e) => {},
        });
    const [showUnsavedChangesPrompt, confirmNavigation, cancelNavigation] =
        useCallbackPrompt(unsavedChanges);
    const [confirmDialogProps, setConfirmDialogProps] =
        useState<ConfirmDialogProps>({
            open: false,
            title: "",
            description: "",
            action: "",
            data: "",
            callback: () => {},
        });

    const getUpdatedApplication = () => {
        const valid = validateApplicationDetailsRef.current();
        if (!applicationDetails || !valid) return undefined;
        const sectionInputs = common.getSectionInputs(
            applicationDetails.id,
            sections
        );
        return {
            ...applicationDetails,
            applicationSections: sectionInputs,
        };
    };

    function updateUnsavedChanges(
        details: ApplicationInput | undefined,
        sections: common.ApplicationSectionData[]
    ) {
        if (!details) return;
        if (isNew) return;
        const hasUnsavedChanges = common.hasChanges(
            applicationOrig,
            details,
            sections
        );
        setUnsavedChanges(hasUnsavedChanges);
    }

    const confirmNavigationWithSave = async (saveFirst: boolean) => {
        if (saveFirst) await updateChecklist(false); // THis will navigate after the save
        confirmNavigation();
    };

    const onApplicationDetailsChanged = (details: ApplicationInput) => {
        setApplicationDetails(details);
        updateUnsavedChanges(details, sections);
    };

    useEffect(() => {
        const getApplication = async () => {
            const result = await common.getApplicationInput(
                contextData.user,
                id ?? "",
                editMode
            );
            if (!result.applicationInput) {
                setBackdropOpen(false);
                setPageError(
                    getErrorMessage("retrieving the application", result.error)
                );
                return;
            }
            setApplicationOrig(result.applicationInput);
            setApplicationDetails(result.applicationInput);
            setBasedOn(result.basedOn);
            const sectionDatas =
                result.applicationInput.applicationSections.map((s) =>
                    common.initialiseSectionData(isNew, s, contextData)
                );
            setSections(sectionDatas);
            setPercentComplete(common.getPercentComplete(sectionDatas));
            setBackdropOpen(false);
        };
        if (loading) {
            setLoading(false);
            getApplication();
        }
    }, [
        contextData,
        id,
        isNew,
        loading,
        mode,
        editMode,
        sections,
        setSections,
        percentComplete,
        setPercentComplete,
    ]);

    useEffect(() => {
        if (navigateAway.current && unsavedChanges === false) {
            navigate("/myApplications");
        }
    }, [unsavedChanges, navigateRequest, navigate]);

    const navigateToList = () => {
        // Use effect above waits for unsavedChanges to be set to turn off the confirm dialog before navigating
        navigateAway.current = true;
        setUnsavedChanges(false);
        setNavigateRequest(new Date());
    };

    const updateChecklist = async (navigateAfterSave: boolean) => {
        const updated = getUpdatedApplication();
        if (!updated) return;
        setBackdropOpen(true);
        const [operation, operationName] = isNew
            ? [createApplication, "createApplication"]
            : [updateApplication, "updateApplication"];
        const updatedApplication = await callApi<Application>(
            contextData.user,
            operationName,
            {
                query: operation,
                variables: { item: updated },
            }
        );
        if (!updatedApplication.Result) {
            setPageError(
                getErrorMessage(
                    "updating the application",
                    updatedApplication.Error
                )
            );
            setBackdropOpen(false);
            return;
        }
        setBackdropOpen(false);
        if (navigateAfterSave) navigateToList();
    };

    const handleAddSection = () => {
        const taken = sections.map((s) => s.applicationSection.name);
        const available = getDocumentGroups(contextData.documentTypes).filter(
            (g) => taken.includes(g) === false
        );

        let editProps: ApplicationSectionAddProps = {
            open: true,
            availableSections: available,
            callback: handleAddSectionResult,
            handleClose: (e) =>
                setSectionAddProps({
                    ...sectionAddProps,
                    open: false,
                }),
        };
        setSectionAddProps(editProps);
    };

    const handleAddSectionResult = (sectionName: string | undefined) => {
        setSectionAddProps({
            ...sectionAddProps,
            open: false,
        });
        if (!sectionName) return;

        const newSection = getSectionOrNew(
            applicationOrig.id,
            sectionName,
            sections
        );
        const except = getSectionsExcept(sectionName, sections);
        const updatedSections = [...except, newSection].sort((a, b) =>
            a.applicationSection.name.localeCompare(b.applicationSection.name)
        );
        setSections(updatedSections);
        setPercentComplete(common.getPercentComplete(updatedSections));
        updateUnsavedChanges(applicationDetails, updatedSections);
    };

    const handleDeleteSection = (section: common.ApplicationSectionData) => {
        setConfirmDialogProps({
            ...confirmDialogProps,
            open: true,
            title: "Delete Document Group",
            action: "Delete",
            data: section.applicationSection.name,
            description: `Are you sure that you would like to delete the document group ${section.applicationSection.name}?`,
            callback: handleDeleteSectionResult,
        });
    };

    const handleDeleteSectionResult = (
        action: string,
        data: any,
        result: boolean
    ) => {
        setConfirmDialogProps({
            ...confirmDialogProps,
            open: false,
            data: "",
            description: "",
        });

        if (!result || action !== "Delete" || !data) return;

        deleteSection(data as string);
    };

    const deleteSection = (section: string) => {
        const sectionsUpdate = getSectionsExcept(section, sections);
        setSections(sectionsUpdate);
        setPercentComplete(common.getPercentComplete(sectionsUpdate));
        updateUnsavedChanges(applicationDetails, sectionsUpdate);
    };

    const handleAddSectionDocument = (
        section: common.ApplicationSectionData,
        documentType: DocumentType | undefined,
        documentMaybe: Document | undefined
    ): void => {
        if (!documentType && documentMaybe) {
            documentType = contextData.documentTypes.find(
                (t) => t.id === documentMaybe.documentTypeId
            );
        }
        if (!documentType) {
            return; // Logic error, should be there
        }
        const updatedSection = common.addDocument(
            documentType,
            documentMaybe,
            section
        );
        const except = getSectionsExcept(
            section.applicationSection.name,
            sections
        );
        const updatedSections = [...except, updatedSection].sort((a, b) =>
            a.applicationSection.name.localeCompare(b.applicationSection.name)
        );
        setSections(updatedSections);
        setPercentComplete(common.getPercentComplete(updatedSections));
        updateUnsavedChanges(applicationDetails, updatedSections);
    };

    const handleEditSectionDocument = (
        section: common.ApplicationSectionData,
        applicationDocumentId: string,
        document: Document | undefined
    ) => {
        const updatedSection = common.updatedSectionDocument(
            section,
            applicationDocumentId,
            document
        );
        const except = getSectionsExcept(
            section.applicationSection.name,
            sections
        );
        const updatedSections = [...except, updatedSection].sort((a, b) =>
            a.applicationSection.name.localeCompare(b.applicationSection.name)
        );
        setSections(updatedSections);
        setPercentComplete(common.getPercentComplete(updatedSections));
        updateUnsavedChanges(applicationDetails, updatedSections);
    };

    const handleRemoveSectionDocument = (
        section: common.ApplicationSectionData,
        applicationDocumentId: string
    ) => {
        const exceptDocument = section.documents.filter(
            (d) => d.applicationDocument.id !== applicationDocumentId
        );
        const updatedSection = { ...section, documents: exceptDocument };
        const except = getSectionsExcept(
            section.applicationSection.name,
            sections
        );
        const updatedSections = [...except, updatedSection].sort((a, b) =>
            a.applicationSection.name.localeCompare(b.applicationSection.name)
        );
        setSections(updatedSections);
        setPercentComplete(common.getPercentComplete(updatedSections));
        updateUnsavedChanges(applicationDetails, updatedSections);
    };

    if (!id)
        return (
            <Typography variant="h6" gutterBottom component="div" color="error">
                The requested application checklist could not be found
            </Typography>
        );

    return (
        <Grid
            container
            spacing={0}
            sx={{ mt: 0, height: "100%", width: "100%" }}
        >
            <ConfirmDialogUnsavedChanges
                showDialog={showUnsavedChangesPrompt}
                confirmNavigation={confirmNavigationWithSave}
                cancelNavigation={cancelNavigation}
            />
            <ConfirmDialog {...confirmDialogProps} />
            <ApplicationSectionAddDialog {...sectionAddProps} />
            <Grid item xs={8}>
                {applicationDetails && (
                    <PageHeader
                        title={
                            isNew
                                ? `New application ${
                                      basedOn !== ""
                                          ? " based on " + basedOn
                                          : ""
                                  }`
                                : `${applicationDetails.level ?? ""} ${
                                      applicationDetails.positionType ?? ""
                                  } Role${unsavedChanges ? "*" : ""}`
                        }
                    />
                )}
                <PageInfo message={pageError} color="error" />
                <p>
                    Your application checklist can be customised using the
                    configuration table below. We’ve broken down the checklist
                    into Document Types such as ‘Application’, ‘Identity’, and
                    ‘Qualifications’ that have the relevant individual documents
                    beneath them. You can add new Document Types by clicking
                    ‘Add a new document type grouping to the checklist’ at the
                    bottom and remove Document Types by clicking on the red bin
                    that appears next to each Document Type title in the
                    checklist. If you want to add or remove specific
                    documents/material from each Document Type name within your
                    checklist, just click on the pencil that appears next to
                    each Document Type name within your checklist. When you’re
                    done, make sure to click Save at the bottom of the page.
                </p>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        width: "100%",
                        mb: 1,
                    }}
                >
                    <LinearProgress
                        variant="determinate"
                        value={percentComplete}
                        sx={{
                            flexGrow: 1,
                            height: "15px",
                            borderRadius: "5px",
                        }}
                    />
                    <Typography
                        sx={{
                            color: "text.secondary",
                            fontSize: "smaller",
                            alignSelf: "center",
                            ml: 1,
                            mr: 1,
                        }}
                    >
                        {percentComplete}% Complete
                    </Typography>
                </Box>
                <Divider
                    orientation="horizontal"
                    flexItem
                    sx={{ ml: "3px", mr: "3px", mt: 1 }}
                />
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleAddSection}
                    sx={{ mt: 1 }}
                >
                    ADD NEW DOCUMENT GROUP
                </Button>
                {sections.length === 0 ? (
                    <Typography
                        sx={{
                            color: "text.secondary",
                            fontSize: "smaller",
                            mt: 1,
                            ml: 1,
                        }}
                    >
                        It seems there is nothing here at the moment. Add a new
                        document grouping to begin building a new checklist.
                    </Typography>
                ) : (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {sections.map((value, index) => (
                            <ApplicationSectionEdit
                                key={index}
                                data={value}
                                deleteSection={() => handleDeleteSection(value)}
                                addSectionDocument={(
                                    documentType,
                                    documentMaybe
                                ) =>
                                    handleAddSectionDocument(
                                        value,
                                        documentType,
                                        documentMaybe
                                    )
                                }
                                editSectionDocument={(id, doc) =>
                                    handleEditSectionDocument(value, id, doc)
                                }
                                removeSectionDocument={(id) =>
                                    handleRemoveSectionDocument(value, id)
                                }
                            />
                        ))}
                    </Box>
                )}
            </Grid>
            <Grid item xs={4}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                    }}
                >
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ ml: "5px", mr: "5px" }}
                    />
                    {applicationDetails && (
                        <ApplicationDetails
                            application={applicationDetails}
                            onChanged={onApplicationDetailsChanged}
                            isValid={validateApplicationDetailsRef}
                        />
                    )}
                </Box>
            </Grid>
            <Grid item xs={12} style={{ padding: "2px 16px" }}>
                <Box
                    sx={{
                        mt: 0,
                        with: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button variant="outlined" onClick={navigateToList}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => updateChecklist(true)}
                        sx={{ ml: 1 }}
                    >
                        Save
                    </Button>
                </Box>
            </Grid>

            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    );
}
