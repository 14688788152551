import { useState, useContext } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    FormLabel,
    IconButton,
    MenuItem,
    List,
    ListItem,
    ListItemText,
    Select,
    SelectChangeEvent,
    DialogActions,
    Typography,
} from "@mui/material";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import AddCircleRounded from "@mui/icons-material/AddCircleRounded";
import { without } from "lodash";
import { getDocumentGroups, pathSeparator } from "../../common/typeUtils";
import { UserContext } from "../../common/userContext";
import { PageHeader } from "../common/pageHeader";
import { DocumentType } from "../../API";

function formatGroupDocumentName(
    documentType: DocumentType,
    groupName: string
): string {
    const parts = documentType.pathName
        .split(pathSeparator)
        .filter((v) => v !== "");
    const suffix = "";
    return parts.length === 1
        ? parts[0] + suffix
        : parts.filter((v) => v !== groupName).join(" - ") + suffix;
}

function getDocumentsForGroup(
    documentGroupName: string,
    documentTypes: DocumentType[]
) {
    if (!documentGroupName) return [];
    const pathPrefix = pathSeparator + documentGroupName;
    return documentTypes.filter((d) => d.pathName.startsWith(pathPrefix));
}

function getSelectedAvailable(
    isNew: boolean,
    documentGroupName: string,
    documentTypes: DocumentType[],
    selected: DocumentType[]
) {
    var available = getDocumentsForGroup(documentGroupName, documentTypes);
    if (isNew === false) {
        const selectedFiltered = selected.filter((d) => available.includes(d));
        for (let next of selectedFiltered) {
            if (next.hasMany === false) available = without(available, next);
        }
        return [selectedFiltered, available];
    }
    // If new pre-select all of the specific document types in the category
    const specificDocuments = available.filter((d) => d.hasMany === false);
    if (specificDocuments.length > 0) {
        return [
            specificDocuments,
            available.filter((d) => specificDocuments.includes(d) === false),
        ];
    }
    return [selected, available];
}

export type TemplateSectionProps = {
    open: boolean;
    sectionName: string | undefined;
    selected: DocumentType[];
    takenSections: string[];
    callback: (
        updated: boolean,
        sectionName: string,
        selection: DocumentType[]
    ) => void;
    handleClose: (event: any) => void;
};

export function TemplateSection(
    props: TemplateSectionProps
) {
    const contextData = useContext(UserContext);
    const isNew = !props.sectionName;
    const documentGroups = getDocumentGroups(contextData.documentTypes)
        .filter((g) => props.takenSections.includes(g) === false)
        .sort((a, b) => a.localeCompare(b));
    const initialName =
        props.sectionName ??
        (documentGroups.length > 0 ? documentGroups[0] : "");
    const [selected, available] = getSelectedAvailable(
        isNew,
        initialName,
        contextData.documentTypes,
        props.selected
    );
    const [sectionName, setSectionName] = useState<string>(initialName);
    const [availableDocumentTypes, setAvailableDocumentTypes] =
        useState<DocumentType[]>(available);
    const [selectedDocumentTypes, setSelectedDocumentTypes] =
        useState<DocumentType[]>(selected);

    const nameChanged = (event: SelectChangeEvent<any>) => {
        const groupName = event.target.value;
        const [selected, available] = getSelectedAvailable(
            true,
            groupName,
            contextData.documentTypes,
            []
        );
        setSectionName(groupName);
        setSelectedDocumentTypes(selected);
        setAvailableDocumentTypes(available);
    };

    //const addDocumentType = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const addDocumentType = (documentType: DocumentType, index: number) => {
        setSelectedDocumentTypes([...selectedDocumentTypes, documentType]);
        if (documentType.hasMany === false) {
            setAvailableDocumentTypes(
                without(availableDocumentTypes, documentType)
            );
        }
    };

    const removedDocumentType = (documentType: DocumentType, index: number) => {
        if (documentType.hasMany === false) {
            setSelectedDocumentTypes(
                without(selectedDocumentTypes, documentType)
            );
            setAvailableDocumentTypes([
                ...availableDocumentTypes,
                documentType,
            ]);
            return;
        }
        // const removed = selectedDocumentTypes.splice(index, 1); Just need to remove one of the potentially many required of same type but splice is not working, probably because the array contains duplicate references and it removes all of the same reference?
        let withOneRemoved: DocumentType[] = [];
        let removed = false;
        for (let next of selectedDocumentTypes) {
            if (removed === false && next === documentType) {
                removed = true;
            } else {
                withOneRemoved = [...withOneRemoved, next];
            }
        }
        setSelectedDocumentTypes(withOneRemoved);
    };

    const handleCancel = () => {
        props.callback(false, "", []);
    };

    const handleUpdate = () => {
        props.callback(true, sectionName, selectedDocumentTypes);
    };

    return (
        <Box sx={{ minWidth: "400px" }}>
            <PageHeader
                title={
                    isNew
                        ? "Add Template Group"
                        : sectionName.toUpperCase()
                }
            />
            <hr color="error" />
            <Box>
                {isNew && (
                    <Box
                        sx={{ mt: 1, display: "flex", flexDirection: "column" }}
                    >
                        <Select
                            name="documentGroup"
                            value={sectionName}
                            onChange={nameChanged}
                            size="small"
                            MenuProps={{
                                PaperProps: { sx: { maxHeight: 200 } },
                            }}
                            inputProps={{ readOnly: !isNew }}
                            className={!isNew ? "Mui-disabled" : undefined}
                        >
                            {documentGroups.map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                )}

                {sectionName && availableDocumentTypes.length > 0 && (
                    <Box
                        sx={{ mt: 1, display: "flex", flexDirection: "column" }}
                    >
                        <FormLabel>Available Document Types</FormLabel>
                        <List
                            sx={{
                                width: "100%",
                                padding: "0px 0px",
                                bgcolor: "green",
                            }}
                        >
                            {availableDocumentTypes.map((value, index) => (
                                <ListItem
                                    key={index}
                                    disableGutters
                                    secondaryAction={
                                        <IconButton
                                            onClick={() =>
                                                addDocumentType(value, index)
                                            }
                                        >
                                            <AddCircleRounded color="success" />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText
                                        primary={`${formatGroupDocumentName(
                                            value,
                                            sectionName
                                        )} ${value.hasMany ? "*" : ""}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )}

                <Box sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
                    <FormLabel>Required Documents</FormLabel>
                    {selectedDocumentTypes.length > 0 ? (
                        <List
                            sx={{
                                width: "100%",
                                m: 0,
                                padding: "0px 0px",
                                bgcolor: "paper",
                            }}
                        >
                            {selectedDocumentTypes.map((value, index) => (
                                <ListItem
                                    key={index}
                                    disableGutters
                                    secondaryAction={
                                        <IconButton
                                            onClick={(e) =>
                                                removedDocumentType(
                                                    value,
                                                    index
                                                )
                                            }
                                        >
                                            <RemoveCircleRoundedIcon color="error" />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText
                                        primary={`${
                                            index + 1
                                        } ${formatGroupDocumentName(
                                            value,
                                            sectionName
                                        )}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Typography
                            sx={{
                                color: "text.secondary",
                                fontSize: "smaller",
                                mt: 1,
                            }}
                        >
                            Select some documents to include
                        </Typography>
                    )}
                </Box>

                <DialogActions sx={{ mt: 1 }}>
                    <Button variant="outlined" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleUpdate} disabled={isNew && selectedDocumentTypes.length === 0}>
                        Update
                    </Button>
                </DialogActions>
            </Box>
        </Box>
    );
}

export function TemplateSectionDialog(
    props: TemplateSectionProps
) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogContent>
                <TemplateSection {...props} />
            </DialogContent>
        </Dialog>
    );
}
