import { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
    useGridApiRef,
    DataGridPro,
    GridToolbarContainer,
    GridActionsCellItem,
    GridFilterModel,
    GridLinkOperator,
} from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import { GridToolbarSearch } from "../common/gridToolbarSearch";
import { UserContext } from "../../common/userContext";
import { callApi } from "../../common/apiUtils";
import {
    ConfirmDialog,
    ConfirmDialogProps,
    ConfirmDialogPropsClosed,
} from "../common/confirmDialog";
import { PageHeader } from "../common/pageHeader";
import { Application } from "../../API";
import { deleteApplicationTemplate } from "../../graphql/mutations";

export type EditToolbarProps = {
    addRow: () => void;
    search: (text: String) => void;
};

function EditToolbar({ addRow, search }: EditToolbarProps) {
    const handleAddClick = async () => {
        addRow();
    };

    return (
        <GridToolbarContainer>
            <GridToolbarSearch search={search} />
            <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddClick}
            >
                Add
            </Button>
        </GridToolbarContainer>
    );
}

export default function TemplateAdmin() {
    const contextData = useContext(UserContext);
    const navigate = useNavigate();
    const apiRef = useGridApiRef();
    const filterText = useRef("");
    const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(
        undefined
    );
    const [processing, setProcessing] = useState<boolean>(false);
    const [confirmDialogProps, setConfirmDialogProps] =
        useState<ConfirmDialogProps>(ConfirmDialogPropsClosed);

    const addRow = () => {
        navigate("/templateEdit/add/");
    };

    const updateRow = (id: string) => (event: any) => {
        event.stopPropagation();
        navigate(`/templateEdit/${id}`);
    };

    const deleteRow = (id: string) => async (event: any) => {
        event.stopPropagation();
        const row = apiRef.current.getRow(id);
        if (!row) {
            console.log(`Edit row for ${id} was null!`);
            return;
        }
        let props: ConfirmDialogProps = {
            open: true,
            title: "Delete Application Template",
            description: `Are you sure that you would like to delete '${row.name}'?`,
            action: "Delete",
            data: id,
            callback: handleConfirmDialogResult,
        };
        setConfirmDialogProps(props);
    };

    const handleConfirmDialogResult = async (
        action: string,
        data: any,
        result: boolean
    ) => {
        setConfirmDialogProps(ConfirmDialogPropsClosed);

        if (!result || action !== "Delete") return;

        const row = apiRef.current.getRow(data as string);
        if (!row) {
            console.log(`Edit row for ${data} was null!`);
            return;
        }

        try {
            setProcessing(true);
            const deletedTemplate = await callApi<Application>(
                contextData.user,
                "deleteApplicationTemplate",
                {
                    query: deleteApplicationTemplate,
                    variables: { pk: row.id },
                }
            );
            if (!deletedTemplate.Result) {
                console.log(
                    "Error deleting template record: " + deletedTemplate.Error
                );
            }
            apiRef.current.updateRows([{ id: row.id, _action: "delete" }]);
            const except = contextData.templates.filter((r) => r.id !== row.id);
            contextData.setTemplates(except);
        } catch (error) {
            console.log(error);
        } finally {
            setProcessing(false);
        }
    };

    const search = (value: string) => {
        filterText.current = value;
        applyFiltering();
    };

    const applyFiltering = () => {
        const value = filterText.current;
        if (!value) {
            setFilterModel({ items: [] });
            return;
        }
        var filterItems = [
            {
                id: 1,
                columnField: "name",
                operatorValue: "contains",
                value: value,
            },
            {
                id: 2,
                columnField: "positionType",
                operatorValue: "contains",
                value: value,
            },
            {
                id: 3,
                columnField: "level",
                operatorValue: "contains",
                value: value,
            },
        ];
        const filter: GridFilterModel = {
            items: filterItems,
            linkOperator: GridLinkOperator.Or,
        };
        setFilterModel(filter);
    };

    const columns = [
        {
            field: "id",
            headerName: "Id",
            hide: true,
            type: "string",
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "positionType",
            headerName: "Position Type",
            flex: 1,
        },
        {
            field: "level",
            headerName: "Level",
            flex: 1,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: ({ id }: any) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={updateRow(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={deleteRow(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    if (contextData.user.admin === false) return <div />;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
            }}
        >
            <ConfirmDialog {...confirmDialogProps} />
            <PageHeader title="Template Admin" />
            <DataGridPro
                autoPageSize
                pagination
                rows={contextData.templates}
                columns={columns}
                apiRef={apiRef}
                loading={processing}
                filterModel={filterModel}
                editMode="row"
                components={{
                    Toolbar: EditToolbar,
                }}
                componentsProps={{
                    toolbar: { addRow, search },
                }}
                initialState={{
                    sorting: {
                        sortModel: [
                            { field: "name", sort: "asc" },
                            { field: "agency", sort: "asc" },
                        ],
                    },
                }}
            />
        </Box>
    );
}
