/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// cp ../ledge-med-cdk/graphql/schema.graphql .
// amplify codegen

export const aws_exports_dev = {
    aws_project_region: 'ap-southeast-2',
    aws_appsync_authenticationType: 'OPENID_CONNECT', // "AMAZON_COGNITO_USER_POOLS", //"AMAZON_COGNITO_USER_POOLS", "AWS_IAM"
    openid_domain: 'lmd.au.auth0.com',
    openid_client_id: 'vBzwvYVLRtT2gCPRPY5Wdw2DeozFyCzN',
    aws_user_files_s3_bucket_region: 'ap-southeast-2',
    aws_user_files_s3_bucket: 'ledge-med-dev-ledgemeddevbucketfefab2db-1jwxrxic8k5ae',
    aws_appsync_region: 'ap-southeast-2',
    aws_cognito_region: 'ap-southeast-2',
    aws_cognito_identity_pool_id: 'ap-southeast-2:fc233ca9-0fb4-47ed-a4c8-7c6eba0600c1',
    aws_appsync_graphqlEndpoint: 'https://vrjifzlphfhsbca7fhhrmti6pm.appsync-api.ap-southeast-2.amazonaws.com/graphql',
}

export const aws_exports_test = {
    aws_project_region: 'ap-southeast-2',
    aws_appsync_authenticationType: 'OPENID_CONNECT',
    openid_domain: 'ledgemedstaging.au.auth0.com',
    openid_client_id: 'GWAKOcZPOrAUScKf4lCVH3bWw2dp2ert',
    aws_user_files_s3_bucket_region: 'ap-southeast-2',
    aws_user_files_s3_bucket: 'ledge-med-test-ledgemedtestbucket8ff6dac6-bz5e93lnpnug',
    aws_appsync_region: 'ap-southeast-2',
    aws_cognito_region: 'ap-southeast-2',
    aws_cognito_identity_pool_id: 'ap-southeast-2:25c0f191-0d69-449e-9055-c9ae714c37ab',
    aws_appsync_graphqlEndpoint: 'https://5t65xaukm5apdpp5lvq5aj52ma.appsync-api.ap-southeast-2.amazonaws.com/graphql',
}

export const aws_exports_prod = {
    aws_project_region: 'ap-southeast-2',
    aws_appsync_authenticationType: 'OPENID_CONNECT',
    openid_domain: 'ledgemed.au.auth0.com',
    openid_client_id: '3KbimWHdYknFhHg3oELUEiXk53Ad9SF2',
    aws_user_files_s3_bucket_region: 'ap-southeast-2',
    aws_user_files_s3_bucket: 'ledge-med-prod-ledgemedbucket219cc2d0-h19p827j9tzm',
    aws_appsync_region: 'ap-southeast-2',
    aws_cognito_region: 'ap-southeast-2',
    aws_cognito_identity_pool_id: 'ap-southeast-2:0c1f909d-23c5-41fa-a3a8-6c4c77be5139',
    aws_appsync_graphqlEndpoint: 'https://xus3oy2vtrf65oddwkx66fdfj4.appsync-api.ap-southeast-2.amazonaws.com/graphql',
}

const aws_exports = {
    aws_project_region: 'ap-southeast-2',
    aws_appsync_authenticationType: 'OPENID_CONNECT',
    openid_domain: 'ledgemed.au.auth0.com',
    openid_client_id: '3KbimWHdYknFhHg3oELUEiXk53Ad9SF2',
    aws_user_files_s3_bucket_region: 'ap-southeast-2',
    aws_user_files_s3_bucket: 'ledge-med-prod-ledgemedbucket219cc2d0-h19p827j9tzm',
    aws_appsync_region: 'ap-southeast-2',
    aws_cognito_region: 'ap-southeast-2',
    aws_cognito_identity_pool_id: 'ap-southeast-2:0c1f909d-23c5-41fa-a3a8-6c4c77be5139',
    aws_appsync_graphqlEndpoint: 'https://xus3oy2vtrf65oddwkx66fdfj4.appsync-api.ap-southeast-2.amazonaws.com/graphql',
}
// TODO 
export default aws_exports 
