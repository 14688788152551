import {
    ContextDataInterface, UserWrapper,

} from "../../common/userContext";
import {
    Application,
    ApplicationInput,
    ApplicationSectionInput,
    ApplicationDocumentInput,
    Document,
    DocumentType,
} from "../../API";
import {
    getNewApplicationAsInput,
    getExistingApplicationAsInput,
    ApplicationLookupResult,
} from "../../common/applicationUtils";
import {
    filterDefined,
    hasExpired,
    parsePathName,
    pathSeparator,
    sortDocumentsForMatching,
} from "../../common/typeUtils";
import { isEqual } from "lodash";

const { v4: uuid } = require("uuid");

export type ApplicationSectionData = {
    applicationSection: ApplicationSectionInput;
    documents: ApplicationDocumentData[];
};

export type ApplicationDocumentData = {
    applicationDocument: ApplicationDocumentInput;
    document: Document | undefined;
    documentType: DocumentType;
};

export enum ApplicationEditMode {
    Edit,
    AddClone,
    AddBlank,
    AddFromTemplate,
}

export async function getApplicationInput(
    user: UserWrapper,
    id: string,
    mode: ApplicationEditMode
): Promise<ApplicationLookupResult> {
    switch (mode) {
        case ApplicationEditMode.AddBlank:
            return getNewApplicationAsInput(false);
        case ApplicationEditMode.AddClone:
        case ApplicationEditMode.AddFromTemplate:
            return getExistingApplicationAsInput(user, id, true);
        case ApplicationEditMode.Edit:
            return getExistingApplicationAsInput(user, id, false);
        default:
            throw new Error("Invalid mode for creating a new application checklist!");
    }
}

export function updatedSectionDocument(
    section: ApplicationSectionData,
    applicationDocumentId: string,
    document: Document | undefined
): ApplicationSectionData {
    const existing = section.documents.find(
        (d) => d.applicationDocument.id === applicationDocumentId
    );
    if (!existing) return section;
    const except = section.documents.filter(
        (d) => d.applicationDocument.id !== applicationDocumentId
    );
    const updatedInput: ApplicationDocumentInput = {
        isNew: existing.applicationDocument.isNew,
        id: existing.applicationDocument.id,
        applicationSectionId: existing.applicationDocument.applicationSectionId,
        documentTypeId: existing.applicationDocument.documentTypeId,
        documentId: document?.id,
    };
    const updatedData: ApplicationDocumentData = {
        applicationDocument: updatedInput,
        documentType: existing.documentType,
        document: document,
    };
    const updatedDocuments = [...except, updatedData].sort((a, b) =>
        a.applicationDocument.id.localeCompare(b.applicationDocument.id)
    );
    return { ...section, documents: updatedDocuments };
}

export function getSectionInputs(
    applicationId: string,
    sectionDatas: ApplicationSectionData[]
) {
    // REVISIT Sorted for comparison for unsaved changes
    return sectionDatas
        .sort((a, b) =>
            a.applicationSection.name.localeCompare(b.applicationSection.name)
        )
        .map((s) => {
            const documentInputs = s.documents
                .map((d) => d.applicationDocument)
                .sort((a, b) => a.id.localeCompare(b.id));
            return {
                ...s.applicationSection,
                applicationId: applicationId,
                applicationDocuments: documentInputs,
            } as ApplicationSectionInput;
        });
}

export function initialiseSectionData(
    isNew: boolean,
    applicationSection: ApplicationSectionInput,
    contextData: ContextDataInterface
) {
    let documentDatas: ApplicationDocumentData[] = [];
    let takenDocuments = filterDefined(
        applicationSection.applicationDocuments.map((ad) => ad.documentId)
    );
    for (let d of applicationSection.applicationDocuments) {
        const documentType = contextData.documentTypes.find(
            (u) => u.id === d.documentTypeId
        );
        if (!documentType)
            // Was the document type deleted by admins TODO REVISIT
            continue;
        let document = d.documentId
            ? contextData.documents.find((u) => u.id === d.documentId)
            : undefined;
        if (isNew && !document) {
            const sortedDocuments = sortDocumentsForMatching(
                contextData.documents
            );
            const matching = tryFindMatchingDocument(
                documentType,
                sortedDocuments,
                takenDocuments
            );
            if (matching) {
                takenDocuments = [...takenDocuments, matching.id];
                document = matching;
            }
        }

        const documentData: ApplicationDocumentData = {
            applicationDocument: {
                isNew: isNew,
                applicationSectionId: applicationSection.id,
                id: d.id,
                documentTypeId: d.documentTypeId,
                documentId: document?.id,
            },
            document: document,
            documentType: documentType,
        };
        documentDatas = [...documentDatas, documentData];
    }
    const sectionData: ApplicationSectionData = {
        applicationSection: {
            id: applicationSection.id,
            applicationId: applicationSection.applicationId,
            isNew: isNew,
            name: applicationSection.name,
            applicationDocuments: [], // will be filled in later for any updates
        },
        documents: documentDatas,
    };

    return sectionData;
}

function tryFindMatchingDocument(
    documentType: DocumentType,
    userDocuments: Document[],
    taken: string[]
) {
    // try to lookup suitable document to fill the slot
    return userDocuments.find((d) => {
        if (documentType.applicationSpecific) return false;
        // if (hasExpired(d.expires)) return false; Expired documents have lower sort order, see sortDocumentsForMatching
        if (d.documentTypeId !== documentType.id) return false;
        if (taken.includes(d.id)) return false;
        return true;
    });
}

export function getPercentComplete(
    applicationSections: ApplicationSectionData[]
) {
    let count = 0;
    let matched = 0;
    for (let section of applicationSections) {
        for (let document of section.documents) {
            count = count + 1;
            if (document.document) {
                if (
                    !document.document.uploaded ||
                    hasExpired(document.document.expires)
                )
                    continue;
                matched = matched + 1;
            }
        }
    }
    if (count === 0) return 0;
    return Math.round((matched / count) * 100);
}

export function getApplicationPercentComplete(
    application: Application,
    contextData: ContextDataInterface
) {
    let count = 0;
    let matched = 0;
    for (let section of application.applicationSections) {
        for (let applicationDocument of section.applicationDocuments) {
            count = count + 1;
            if (!applicationDocument.documentId) continue;
            const document = contextData.documents.find(
                (d) => d.id === applicationDocument.documentId
            );
            if (!document) continue;
            if (!document.uploaded || hasExpired(document.expires)) continue;
            matched = matched + 1;
        }
    }
    if (count === 0) return 0;
    return Math.round((matched / count) * 100);
}

export function hasChanges(
    orig: ApplicationInput,
    updatedDetails: ApplicationInput,
    updatedSections: ApplicationSectionData[]
) {
    const updatedSectionInputs = getSectionInputs(
        updatedDetails.id,
        updatedSections
    );
    const updatedCombined = {
        ...updatedDetails,
        applicationSections: updatedSectionInputs,
    };
    const hasChanges = isEqual(orig, updatedCombined) === false;
    return hasChanges;
}

export function getDocumentName(document: Document | undefined): string {
    return document?.documentName ?? "*Missing";
}

export function getDocumentTypeName(
    documentType: DocumentType | undefined
): string {
    return documentType?.pathName
        ? parsePathName(documentType.pathName).name
        : "*Missing";
}

export function getDocumentTypeNameFromId(
    documentTypeId: string,
    contextData: ContextDataInterface
): string {
    //if (data.document) return data.document.documentName;
    const documentType = contextData.documentTypes.find(
        (dt) => dt.id === documentTypeId
    );
    return getDocumentTypeName(documentType);
}

function getDocumentsForGroup(
    documentGroupName: string,
    contextData: ContextDataInterface
) {
    if (!documentGroupName) return [];
    const pathPrefix = pathSeparator + documentGroupName;
    return contextData.documents.filter((d) => {
        const documentType = contextData.documentTypes.find(
            (t) => t.id === d.documentTypeId
        );
        return documentType && documentType.pathName.startsWith(pathPrefix);
    });
}

export function addDocument(
    documentType: DocumentType,
    document: Document | undefined,
    section: ApplicationSectionData
): ApplicationSectionData {
    const input: ApplicationDocumentInput = {
        isNew: true,
        id: uuid(),
        applicationSectionId: section.applicationSection.id,
        documentTypeId: documentType.id,
        documentId: document?.id,
    };

    const newDocument: ApplicationDocumentData = {
        applicationDocument: input,
        document: document,
        documentType: documentType,
    };

    return { ...section, documents: [...section.documents, newDocument] };
}

export function getSectionAvailableDocuments(
    section: ApplicationSectionData,
    contextData: ContextDataInterface
): Document[] {
    const userDocuments = getDocumentsForGroup(
        section.applicationSection.name,
        contextData
    );
    const available = userDocuments.filter((d) => {
        const taken = section.documents.find((sd) => sd.document?.id === d.id);
        return !taken;
    });

    return available.sort((a, b) =>
        a.documentName.localeCompare(b.documentName)
    );
}

export function getSectionDocumentTypes(
    section: ApplicationSectionData,
    contextData: ContextDataInterface
): DocumentType[] {
    const documentTypes = contextData.documentTypes.filter((dt) =>
        dt.pathName.startsWith(pathSeparator + section.applicationSection.name)
    );
    return documentTypes.sort((a, b) =>
        getDocumentTypeName(a).localeCompare(getDocumentTypeName(b))
    );
}
