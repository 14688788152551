import { useState, useEffect } from "react";
import {
    Autocomplete,
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    RadioGroup,
    Radio,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import auLocale from "date-fns/locale/en-AU";
import { states, getHospitalsByStateSector } from "../../assets/hospitals";
import { PageHeader } from "../common/pageHeader";
import { MarkdownDialog, MarkdownDialogProps } from "../common/markdownDialog";
import {
    ApplicationInput,
    QualificationLevel,
    PositionType,
    Sector,
} from "../../API";
import * as utils from "../../common/typeUtils";

function getUpdatedInput(
    input: ApplicationInput,
    name: string,
    value: string | undefined | null
) {
    const updateValue = value ?? "";
    const updated: ApplicationInput = {
        ...input,
        [name]: updateValue,
    };
    return updated;
}

export type ApplicationDetailsProps = {
    application: ApplicationInput;
    onChanged: (ApplicationInput) => void;
    isValid: React.MutableRefObject<() => boolean>;
};

export function ApplicationDetails({
    application,
    onChanged,
    isValid,
}: ApplicationDetailsProps) {
    const closedProps: MarkdownDialogProps = {
        open: false,
        title: "Edit Notes",
        content: "",
        callback: (i, d) => {},
    };

    const [markdownProps, setMarkdownProps] = useState(closedProps);
    const [selectedHospitals, setHospitals] = useState<string[]>(
        getHospitalsByStateSector(
            application.state ?? "",
            application.sector ?? ""
        )
    );

    useEffect(() => {
        isValid.current = validate;
    });

    const [errorData, setErrorData] = useState<any>({
        name: "",
        type: "",
        agency: "",
        dueDate: "",
    });

    const setValue = (name: string, value: string | undefined | null) => {
        const updated = getUpdatedInput(application, name, value);
        onChanged(updated);
    };

    const stateChanged = (e) => {
        const state = e.target.value as string;
        if (application.state === state) return;
        let updated = getUpdatedInput(application, "state", state);
        updated = getUpdatedInput(updated, "hospital", "");
        if (
            state !== "Victoria" &&
            updated.sector === String(Sector.PublicRural)
        ) {
            updated = getUpdatedInput(updated, "sector", String(Sector.Public));
        }
        onChanged(updated);
        updateHospitals(state, updated.sector ?? String(Sector.Public));
    };

    const sectorChanged = (e) => {
        const sector = e.target.value;
        if (application.sector === sector) return;
        let updated = getUpdatedInput(application, "sector", sector);
        updated = getUpdatedInput(updated, "hospital", "");
        onChanged(updated);
        updateHospitals(updated.state ?? "", sector);
    };

    const updateHospitals = (state: string, sectorString: string) => {
        const hospitals = getHospitalsByStateSector(state, sectorString);
        setHospitals(hospitals);
    };

    const dueDateChanged = (dateString: string | null) => {
        setValue("dueDate", dateString);
        if (!utils.hasExpired(dateString)) errorChanged("dueDate", "");
        else errorChanged("dueDate", "the date specified is in the past");
    };

    const editNotes = () => {
        let notes = application.notes ?? "";

        const editProps: MarkdownDialogProps = {
            open: true,
            title: "Edit Notes",
            content: notes,
            callback: editNotesCallback,
        };
        setMarkdownProps(editProps);
    };

    const editNotesCallback = (changed: boolean, notes: string) => {
        if (changed) {
            setValue("notes", notes);
        }
        setMarkdownProps(closedProps);
    };

    const errorChanged = (name: string, message: string) => {
        const currentValue = errorData[name];
        if (currentValue === message) return;
        setErrorData({
            ...errorData,
            [name]: message,
        });
    };

    const validate: () => boolean = () => {
        if (!application.name) return false;
        if (!utils.isValidISODate(application.dueDate)) return false;
        return true;
    };

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <MarkdownDialog {...markdownProps} />
            <PageHeader title="Details of this Job" />
            {utils.hasExpired(application.dueDate) && (
                <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    color="error"
                >
                    This application has passed its due date
                </Typography>
            )}
            <Box>
                <TextField
                    autoFocus
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin="dense"
                    inputProps={{ maxLength: 256 }}
                    name="name"
                    label="Job Name*"
                    value={application.name}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                    error={application.name ? false : true}
                    helperText={application.name ? "" : "required field"}
                />

                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={auLocale}
                >
                    <DatePicker
                        value={application.dueDate}
                        onChange={(date) =>
                            utils.tryParseDateAndSetISOValue(
                                date,
                                dueDateChanged
                            )
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                size="small"
                                margin="dense"
                                name="dueDate"
                                label="Application Due Date"
                                error={application.dueDate ? false : true}
                                helperText={
                                    application.dueDate ? "" : "required field"
                                }
                            />
                        )}
                    />
                </LocalizationProvider>

                <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin="dense"
                >
                    <InputLabel id="stateLabel">
                        State job is located in
                    </InputLabel>
                    <Select
                        labelId="stateLabel"
                        label="State job is located in"
                        name="state"
                        value={application.state}
                        onChange={stateChanged}
                        error={!application.state}
                        MenuProps={{
                            PaperProps: { sx: { maxHeight: 200 } },
                        }}
                    >
                        {states.map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl>
                    <FormLabel>
                        Is this role in the Private or Public Sector?
                    </FormLabel>
                    <RadioGroup
                        row
                        value={application.sector}
                        sx={{ width: "100%" }}
                        onChange={sectorChanged}
                    >
                        <FormControlLabel
                            key={Sector.Private}
                            value={String(Sector.Private)}
                            control={<Radio />}
                            label={String(Sector.Private)}
                        />

                        <FormControlLabel
                            key={Sector.Public}
                            value={String(Sector.Public)}
                            control={<Radio />}
                            label={String(Sector.Public)}
                        />

                        {application.state === "Victoria" && (
                            <FormControlLabel
                                key={Sector.PublicRural}
                                value={String(Sector.PublicRural)}
                                control={<Radio />}
                                label={String(Sector.PublicRural)}
                            />
                        )}
                    </RadioGroup>
                </FormControl>

                <Autocomplete
                    freeSolo
                    options={selectedHospitals}
                    value={application.hospital}
                    onInputChange={(e, v) => {
                        if (v !== application.hospital)
                            setValue("hospital", String(v ?? ""));
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                size="small"
                                margin="dense"
                                // inputProps={{ maxLength: 256 }}
                                name="specialization"
                                label="Name of Hospital/Medical Centre"
                                error={!application.hospital}
                            />
                        );
                    }}
                />

                <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin="dense"
                >
                    <InputLabel id="leveLabel">Level</InputLabel>
                    <Select
                        labelId="leveLabel"
                        label="Level"
                        name="level"
                        value={application.level}
                        onChange={(e) =>
                            setValue(
                                e.target.name,
                                QualificationLevel[e.target.value ?? ""]
                            )
                        }
                        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                    >
                        {Object.values(QualificationLevel).map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin="dense"
                >
                    <InputLabel id="typeLabel">Type</InputLabel>
                    <Select
                        labelId="typeLabel"
                        label="Job Type"
                        name="positionType"
                        value={application.positionType}
                        onChange={(e) =>
                            setValue(
                                e.target.name,
                                PositionType[e.target.value ?? ""]
                            )
                        }
                        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                    >
                        {Object.values(PositionType).map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin="dense"
                    multiline={true}
                    minRows={10}
                    maxRows={10}
                    inputProps={{ maxLength: 1256 }}
                    name="notes"
                    label="Notes"
                    value={application.notes}
                    onChange={(e) => setValue(e.target.name, e.target.value)}
                />
            </Box>
        </Box>
    );
}
