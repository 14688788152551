import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
    Avatar,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Typography,
} from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import { callApi } from "../../common/apiUtils";
import { PageHeader } from "../common/pageHeader";
import { PageInfo } from "../common/pageInfo";
import { ConfirmDialogUnsavedChanges } from "../common/confirmDialogUnsavedChanges";
import { useCallbackPrompt } from "../../common/useCallbackPrompt";
import { getErrorMessage } from "../../common/errorUtils";
import { ProfileGeneralDetails } from "./profileGeneralDetails";
import { ProfileEmploymentDetails } from "./profileEmploymentDetails";
import { ProfileInterests } from "./profileInterests";
import { ProfileFinancialDetails } from "./profileFinancialDetails";
import { ProfileAdditional } from "./profileAdditional";
import { UserContext } from "../../common/userContext";
import { User, UserInput } from "../../API";
import { createUser, updateUser } from "../../graphql/mutations";
import { isEqual } from "lodash";
import * as common from "./profileCommon";
import * as utils from "../../common/typeUtils";

function goToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
}

export type ProfileProps = {
    dialog: boolean;
};

export function Profile({ dialog }: ProfileProps) {
    const navigate = useNavigate();
    const { user, ledgeUser, setLedgeUser } = useContext(UserContext);
    const orig = common.getUserInput(user.sub, user.email, ledgeUser);
    const [backdropOpen, setBackdropOpen] = useState<boolean>(false);
    const [pageInfo, setPageInfo] = useState({ message: "", color: "" });
    const [origInput, setOrigInput] = useState(orig);
    const [userInput, setUserInput] = useState(orig);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [showUnsavedChangesPrompt, confirmNavigation, cancelNavigation] =
        useCallbackPrompt(unsavedChanges);

    const setValue = (name: string, value: any) => {
        setUserInput((current) => {
            const updated = common.getUpdatedInput(current, name, value);
            updateUnsavedChanges(updated);
            return updated;
        });
    };

    const updateProfile = async () => {
        if (
            !userInput.firstName ||
            !userInput.lastName ||
            !utils.isValidPhoneNumber(userInput.phone)
        )
            return;

        const updateActiveCampaign =
            origInput.activeCampaignPendingUpdate ||
            origInput.firstName !== userInput.firstName ||
            origInput.lastName !== userInput.lastName ||
            origInput.phone !== userInput.phone ||
            origInput.level !== userInput.level;

        setBackdropOpen(true);
        setPageInfo({ message: "", color: "" });
        const isNew = !ledgeUser?.id;

        const [operation, operationName] = isNew
            ? [createUser, "createUser"]
            : [updateUser, "updateUser"];

        const updatedUser = await callApi<User>(user, operationName, {
            query: operation,
            variables: {
                item: {
                    ...userInput,
                    activeCampaignPendingUpdate: updateActiveCampaign,
                },
            },
        });

        setBackdropOpen(false);

        if (!updatedUser.Result) {
            const message = getErrorMessage(
                "updating your profile",
                updatedUser.Error
            );
            setPageInfo({ message: message, color: "error" });
            return;
        }

        setOrigInput({
            ...userInput,
            activeCampaignPendingUpdate: false,
        });
        setUnsavedChanges(false);
        setLedgeUser(updatedUser.Result);

        if (isNew) {
            navigate("/about");
            return;
        }

        setPageInfo({
            message: "Your profile details have been updated!",
            color: "primary.main",
        });

        goToTop();
    };

    function updateUnsavedChanges(updated: UserInput) {
        if (dialog) return;
        const hasChanges = isEqual(origInput, updated) === false;
        setUnsavedChanges(hasChanges);
        var message = hasChanges ? "There are unsaved changes" : "";
        setPageInfo({ message: message, color: "secondary.main" });
    }

    const confirmNavigationWithSave = async (saveFirst: boolean) => {
        if (saveFirst) await updateProfile(); // This will navigate after the save
        confirmNavigation();
    };

    if (dialog)
        return (
            <Box sx={{ mt: 1, ml: 0 }}>
                <PageInfo {...pageInfo} sx={{ mt: 1 }} />
                <ProfileGeneralDetails
                    userInput={userInput}
                    setValue={setValue}
                    gridItemWidth={6}
                />
                <ProfileInterests
                    userInput={userInput}
                    setValue={setValue}
                    gridItemWidth={6}
                />
                <DialogActions>
                    <Button onClick={() => user.signOut()}>Cancel</Button>
                    <Button variant="contained" onClick={updateProfile}>
                        Save
                    </Button>
                </DialogActions>
            </Box>
        );

    return (
        <Box sx={{ mt: 0, ml: 0 }}>
            <ConfirmDialogUnsavedChanges
                showDialog={showUnsavedChangesPrompt}
                confirmNavigation={confirmNavigationWithSave}
                cancelNavigation={cancelNavigation}
            />
            <Avatar sx={{ bgcolor: deepPurple[500], ml: 2 }}>
                {`${userInput.firstName[0] ?? ""}${
                    userInput.lastName[0] ?? ""
                }`}
            </Avatar>
            {/* {idToken.picture ? (
                <Avatar src={idToken.picture} />
            ) : (
                <Avatar
                    sx={{ bgcolor: deepPurple[500], ml: 2 }}
                >
                    {`${userInput.firstName[0] ?? ""}${
                        userInput.lastName[0] ?? ""
                    }`}
                </Avatar>
            )} */}
            <Typography
                variant="h6"
                sx={{ mt: 1, ml: 2 }}
            >{`${userInput?.email}`}</Typography>

            <PageInfo {...pageInfo} sx={{ mt: 1, ml: 2 }} />

            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <Box sx={{ m: 1 }}>
                        <ProfileGeneralDetails
                            userInput={userInput}
                            setValue={setValue}
                            gridItemWidth={12}
                        />
                    </Box>
                    <Box sx={{ m: 1 }}>
                        <ProfileInterests
                            userInput={userInput}
                            setValue={setValue}
                            gridItemWidth={12}
                        />
                    </Box>
                    <Box sx={{ m: 1 }}>
                        <ProfileEmploymentDetails
                            userInput={userInput}
                            setValue={setValue}
                            gridItemWidth={12}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box sx={{ m: 1 }}>
                        <ProfileFinancialDetails
                            userInput={userInput}
                            setValue={setValue}
                            gridItemWidth={12}
                        />
                    </Box>
                    <Box sx={{ m: 1 }}>
                        <ProfileAdditional
                            userInput={userInput}
                            setValue={setValue}
                            gridItemWidth={12}
                        />
                    </Box>
                </Grid>
            </Grid>

            <DialogActions>
                <Button variant="contained" onClick={updateProfile}>
                    Save
                </Button>
            </DialogActions>

            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}

export function ProfileDialog() {
    return (
        <Dialog open={true} fullWidth maxWidth="md">
            <DialogContent>
                <Box>
                    <PageHeader title="Complete Registration" />
                    <hr color="error" />
                    <DialogContentText>
                        Please enter the following details to complete your
                        account setup
                    </DialogContentText>
                    <Profile dialog={true} />
                </Box>
            </DialogContent>
        </Dialog>
    );
}
