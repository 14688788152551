import { useContext, useState } from "react";
import { Route, Routes, Link, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import {
    Avatar,
    Box,
    Drawer,
    Toolbar,
    List,
    Menu,
    MenuItem,
    Typography,
    Divider,
    ListItemText,
    ListItemButton,
    ListItemIcon,
    IconButton,
} from "@mui/material";
import { UserContext } from "../common/userContext";
import { deepPurple } from "@mui/material/colors";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import InfoIcon from "@mui/icons-material/Info";
import SupportIcon from "@mui/icons-material/Support";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import LogoutIcon from "@mui/icons-material/Logout";
import QuizIcon from "@mui/icons-material/Quiz";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SecurityIcon from "@mui/icons-material/Security";
import DocumentTypeAdmin from "./documentTypes/documentTypeAdmin";
import TemplateAdmin from "./templates/templateAdmin";
import TemplateEdit from "./templates/templateEdit";
import MyDocuments from "./documents/myDocuments";
import MyApplications from "./applications/myApplications";
import { Profile, ProfileDialog } from "./profile/profile";
import { Privacy } from "./profile/privacy";
import ApplicationEdit from "./applications/applicationEdit";
import About from "./about";
import Logo from "../assets/logo.svg";
import PeterMac from "../assets/peterMac.png";
import Rmit from "../assets/rmit.png";

type OpenProps = {
    open: boolean;
};

const appBarHeight = 70;
const drawerWidth = 210;
const selectedColor = "#E4E9E9";

const Main = styled("main", {
    shouldForwardProp: (prop) => prop !== "open",
})<OpenProps>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    height: `calc(100vh - ${appBarHeight}px)`,
    // height: `calc(100% - ${appBarHeight}px)`,
    // height: `calc(${window.innerHeight - appBarHeight}px)`,
    // height: `calc(${window.innerHeight}px)`,
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<OpenProps>(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

export function MainWindow() {
    const contextData = useContext(UserContext);
    const theme = useTheme();
    const location = useLocation();
    const [open, setOpen] = useState(true);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    if (!contextData.ledgeUser) return <ProfileDialog />;

    return (
        <Box sx={{ display: "flex", borderRadius: 0 }}>
            <AppBar
                position="fixed"
                open={open}
                sx={{
                    height: appBarHeight,
                    background: "linear-gradient(to right, #6CA6A2, #1D6560)",
                    borderRadius: 0,
                }}
            >
                <Toolbar disableGutters>
                    <IconButton
                        color="inherit"
                        aria-label="close drawer"
                        onClick={handleDrawerClose}
                        edge="start"
                        sx={{
                            ml: 2,
                            mr: 2,
                            ...(open === false && { display: "none" }),
                        }}
                    >
                        {theme.direction === "ltr" ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ ml: 2, mr: 2, ...(open && { display: "none" }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <a href="https://www.petermac.org" target="PeterMac">
                        <img
                            height={appBarHeight - 10}
                            style={{ marginTop: "5px" }}
                            src={PeterMac}
                            alt="Peter MacCallum Cancer Centre"
                        />
                    </a>
                    <a href="https://www.rmit.edu.au/" target="Rmit">
                        <img
                            height={appBarHeight - 20}
                            style={{ marginTop: "5px", marginLeft: "20px" }}
                            src={Rmit}
                            alt="RMIT University"
                        />
                    </a>
                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                    ></Box>
                    <Box
                        sx={{
                            flexGrow: 0,
                            ml: 2,
                            mr: 2,
                        }}
                    >
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar
                                sx={{ bgcolor: deepPurple[500] }}
                                alt={`${contextData.ledgeUser.firstName} ${contextData.ledgeUser.lastName}`}
                            >
                                {`${contextData.ledgeUser.firstName[0] ?? ""}${
                                    contextData.ledgeUser.lastName[0] ?? ""
                                }`}
                            </Avatar>
                        </IconButton>
                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem
                                key="profile"
                                component={Link}
                                to="/profile"
                            >
                                Profile
                            </MenuItem>
                            <MenuItem
                                key="SignOut"
                                onClick={async (e) =>
                                    contextData.user.signOut()
                                }
                            >
                                Sign out
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                        backgroundColor: "#EDF2F5",
                        borderRadius: 0,
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader
                    sx={{
                        m: 0,
                        height: appBarHeight,
                        display: "flex",
                        justifyContent: "space-around",
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            padding: "0px 0px",
                            height: appBarHeight - 10, //7 / 8,
                        }}
                        src={Logo}
                        alt="logo"
                    />
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItemButton
                        key="MyDocuments"
                        component={Link}
                        to="/myDocuments"
                        sx={{
                            background:
                                location.pathname === "/myDocuments"
                                    ? selectedColor
                                    : undefined,
                        }}
                    >
                        <ListItemIcon>
                            <ContentCopyIcon />
                        </ListItemIcon>
                        <ListItemText primary="My Documents" />
                    </ListItemButton>
                    <ListItemButton
                        key="MyApplications"
                        component={Link}
                        to="/myApplications"
                        sx={{
                            background:
                                location.pathname === "/myApplications"
                                    ? selectedColor
                                    : undefined,
                        }}
                    >
                        <ListItemIcon>
                            <WorkOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="My Applications" />
                    </ListItemButton>
                    <ListItemButton
                        key="KnowledgeCenter"
                        onClick={() =>
                            window.open(
                                "https://help.ledgemed.com.au/",
                                "KnowledgeCenter",
                                "noopener,noreferrer"
                            )
                        }
                    >
                        <ListItemIcon>
                            <QuizIcon />
                        </ListItemIcon>
                        <ListItemText primary="Knowledge Center" />
                    </ListItemButton>
                </List>
                {contextData.user.admin && (
                    <>
                        <Divider />
                        <Typography variant="h6" sx={{ ml: 2, mt: 1 }}>
                            Admin
                        </Typography>
                        <List sx={{ mt: 0 }}>
                            <ListItemButton
                                key="DocumentTypes"
                                component={Link}
                                to="/documentTypeAdmin"
                                sx={{
                                    background:
                                        location.pathname ===
                                        "/documentTypeAdmin"
                                            ? selectedColor
                                            : undefined,
                                }}
                            >
                                <ListItemIcon>
                                    <FilterNoneIcon />
                                </ListItemIcon>
                                <ListItemText primary="Document Types" />
                            </ListItemButton>
                            <ListItemButton
                                key="Templates"
                                component={Link}
                                to="/templateAdmin"
                                sx={{
                                    background:
                                        location.pathname === "/templateAdmin"
                                            ? selectedColor
                                            : undefined,
                                }}
                            >
                                <ListItemIcon>
                                    <DocumentScannerIcon />
                                </ListItemIcon>
                                <ListItemText primary="Templates" />
                            </ListItemButton>
                        </List>
                    </>
                )}
                <Divider />
                <List style={{ marginTop: `auto`, marginBottom: 2 }}>
                    <Divider />
                    <ListItemButton
                        key="Profile"
                        component={Link}
                        to="/profile"
                        sx={{
                            background:
                                location.pathname === "/profile"
                                    ? selectedColor
                                    : undefined,
                        }}
                    >
                        <ListItemIcon>
                            <ManageAccountsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Profile" />
                    </ListItemButton>
                    <ListItemButton
                        key="Privacy"
                        component={Link}
                        to="/privacy"
                        sx={{
                            background:
                                location.pathname === "/privacy"
                                    ? selectedColor
                                    : undefined,
                        }}
                    >
                        <ListItemIcon>
                            <SecurityIcon />
                        </ListItemIcon>
                        <ListItemText primary="Privacy" />
                    </ListItemButton>
                    <ListItemButton
                        key="ServiceDesk"
                        onClick={() =>
                            window.open(
                                "https://elco.atlassian.net/servicedesk/customer/portal/1",
                                "ServiceDesk",
                                "noopener,noreferrer"
                            )
                        }
                    >
                        <ListItemIcon>
                            <SupportIcon />
                        </ListItemIcon>
                        <ListItemText primary="Service Desk" />
                    </ListItemButton>
                    <ListItemButton
                        key="About"
                        component={Link}
                        to="/about"
                        sx={{
                            background:
                                location.pathname === "/about"
                                    ? selectedColor
                                    : undefined,
                        }}
                    >
                        <ListItemIcon>
                            <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary="About" />
                    </ListItemButton>
                    <ListItemButton
                        key="SignOut"
                        onClick={(e) => contextData.user.signOut()}
                    >
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sign Out" />
                    </ListItemButton>
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader
                    sx={{
                        mt: 1,
                        height: appBarHeight,
                        display: "flex",
                        justifyContent: "space-around",
                    }}
                />
                <Routes>
                    <Route path="/" element={<Profile dialog={false} />} />
                    <Route
                        path="/profile"
                        element={<Profile dialog={false} />}
                    />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/myDocuments" element={<MyDocuments />} />
                    <Route
                        path="/myApplications"
                        element={<MyApplications />}
                    />
                    <Route
                        path="/applicationEdit/:id/:mode"
                        element={<ApplicationEdit />}
                    />
                    <Route
                        path="/documentTypeAdmin"
                        element={<DocumentTypeAdmin />}
                    />
                    <Route path="/templateAdmin" element={<TemplateAdmin />} />
                    <Route
                        path="/templateEdit/:id"
                        element={<TemplateEdit />}
                    />
                    <Route path="/about" element={<About />} />
                </Routes>
            </Main>
        </Box>
    );
}
