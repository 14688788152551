import { useState, useContext } from "react";
import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TableCell,
    Tooltip,
    Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import CachedIcon from "@mui/icons-material/Cached";
import {
    ApplicationSectionDocumentAddDialog,
    ApplicationSectionDocumentAddProps,
} from "./applicationSectionDocumentAddDialog";
import {
    ApplicationSectionDocumentEditDialog,
    ApplicationSectionDocumentEditProps,
} from "./applicationSectionDocumentEditDialog";
import { ConfirmDialog, ConfirmDialogProps } from "../common/confirmDialog";
import {
    getDocumentName,
    getDocumentTypeName,
    getSectionAvailableDocuments,
    getSectionDocumentTypes,
    ApplicationSectionData,
    ApplicationDocumentData,
} from "./applicationCommon";
import { Document, DocumentType } from "../../API";
import { UserContext } from "../../common/userContext";
import { hasExpired } from "../../common/typeUtils";

export type ApplicationSectionEditProps = {
    data: ApplicationSectionData;
    deleteSection: () => void;
    addSectionDocument: (
        documentType: DocumentType | undefined,
        documentMaybe: Document | undefined
    ) => void;
    editSectionDocument: (
        applicationDocumentId: string,
        document: Document | undefined
    ) => void;
    removeSectionDocument: (applicationDocumentId: string) => void;
};

export function ApplicationSectionEdit({
    data,
    deleteSection,
    addSectionDocument,
    editSectionDocument,
    removeSectionDocument,
}: ApplicationSectionEditProps) {
    const contextData = useContext(UserContext);

    const sortedDocuments = data.documents.sort((a, b) =>
        getDocumentName(a.document).localeCompare(getDocumentName(b.document))
    );

    const [confirmDialogProps, setConfirmDialogProps] =
        useState<ConfirmDialogProps>({
            open: false,
            title: "",
            description: "",
            action: "",
            data: "",
            callback: () => {},
        });

    const [sectionDocumentAddProps, setSectionDocumentAddProps] =
        useState<ApplicationSectionDocumentAddProps>({
            open: false,
            documentTypes: [],
            documents: [],
            callback: (t, d) => {},
            handleClose: (e) => {},
        });

    const [documentDialogProps, setDocumentDialogProps] =
        useState<ApplicationSectionDocumentEditProps>({
            open: false,
            applicationDocumentId: "",
            documentType: { pathName: "" } as DocumentType,
            selected: undefined,
            available: [],
            callback: (i, d) => {},
            handleClose: (e: any) => {},
        });

    const addDocument = () => {
        const sectionAvailableDocuments = getSectionAvailableDocuments(
            data,
            contextData
        );

        const sectionDocumentTypes = getSectionDocumentTypes(data, contextData);

        let editProps: ApplicationSectionDocumentAddProps = {
            open: true,
            documentTypes: sectionDocumentTypes,
            documents: sectionAvailableDocuments,
            callback: handleAddResult,
            handleClose: (e) =>
                setSectionDocumentAddProps({
                    ...sectionDocumentAddProps,
                    open: false,
                }),
        };
        setSectionDocumentAddProps(editProps);
    };

    const handleAddResult = (
        documentType: DocumentType | undefined,
        documentMaybe: Document | undefined
    ): void => {
        setSectionDocumentAddProps({
            ...sectionDocumentAddProps,
            open: false,
        });
        addSectionDocument(documentType, documentMaybe);
    };

    const editDocument = (row: ApplicationDocumentData) => {
        const sectionAvailableDocuments = getSectionAvailableDocuments(
            data,
            contextData
        );
        const editProps: ApplicationSectionDocumentEditProps = {
            ...documentDialogProps,
            open: true,
            applicationDocumentId: row.applicationDocument.id,
            documentType: row.documentType,
            selected: row.document,
            available: sectionAvailableDocuments,
            callback: editDocumentResult,
            handleClose: (e) =>
                setDocumentDialogProps({ ...documentDialogProps, open: false }),
        };
        setDocumentDialogProps(editProps);
    };

    const editDocumentResult = (
        applicationDocumentId: string,
        selected: Document | undefined
    ) => {
        setDocumentDialogProps({ ...documentDialogProps, open: false });
        editSectionDocument(applicationDocumentId, selected);
    };

    const removeDocument = (row: ApplicationDocumentData) => {
        setConfirmDialogProps({
            ...confirmDialogProps,
            open: true,
            title: "Remove Item",
            action: "Yes",
            data: row.applicationDocument.id,
            description: `Are you sure that you would like to remove the document ${getDocumentName(
                row.document
            )} from the checklist?`,
            callback: removeDocumentResult,
        });
    };

    const removeDocumentResult = (
        action: string,
        data: any,
        result: boolean
    ) => {
        setConfirmDialogProps({
            ...confirmDialogProps,
            open: false,
            data: "",
            description: "",
        });

        if (!result || !data) return;

        removeSectionDocument(data as string);
    };

    const getRowStatusIcon = (row: ApplicationDocumentData) => {
        if (!row.document)
            return (
                <Tooltip title="Document missing">
                    <IconButton>
                        <ErrorOutlinedIcon
                            color="error"
                            sx={{ fontSize: "medium" }}
                        />
                    </IconButton>
                </Tooltip>
            );

        if (hasExpired(row.document.expires))
            return (
                <Tooltip title="Document expired">
                    <IconButton>
                        <WarningOutlinedIcon
                            color="warning"
                            sx={{ fontSize: "medium" }}
                        />
                    </IconButton>
                </Tooltip>
            );

        if (!row.document.uploaded)
            return (
                <Tooltip title="Document missing">
                    <IconButton>
                        <WarningOutlinedIcon
                            color="warning"
                            sx={{ fontSize: "medium" }}
                        />
                    </IconButton>
                </Tooltip>
            );

        return (
            <Tooltip title="Document attached">
                <IconButton>
                    <CheckCircleIcon
                        color="success"
                        sx={{ fontSize: "medium" }}
                    />
                </IconButton>
            </Tooltip>
        );
    };

    return (
        <Box
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <ConfirmDialog {...confirmDialogProps} />
            <ApplicationSectionDocumentAddDialog {...sectionDocumentAddProps} />
            <ApplicationSectionDocumentEditDialog {...documentDialogProps} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    mt: 2,
                    alignItems: "center",
                    verticalAlign: "center",
                    //border: "solid black 1px",
                }}
            >
                <Typography
                    sx={{
                        alignSelf: "center",
                        fontSize: "medium",
                        fontWeight: "bolder",
                        padding: "3px 10px",
                    }}
                >
                    {data.applicationSection.name.toUpperCase()}
                </Typography>
                <Tooltip title="Remove section from the checklist">
                    <IconButton
                        onClick={deleteSection}
                        sx={{ padding: "0", ml: 0 }}
                    >
                        <DeleteIcon color="error" sx={{ fontSize: "medium" }} />
                    </IconButton>
                </Tooltip>
                <div style={{ flexGrow: "1" }}></div>
                <Tooltip title="Add item">
                    <IconButton onClick={addDocument} sx={{ mr: "38px" }}>
                        <AddIcon
                            sx={{ fontSize: "medium", color: "text.primary" }}
                        />
                    </IconButton>
                </Tooltip>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }}>
                    <TableBody>
                        {sortedDocuments.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell
                                    sx={{ padding: "2px 16px", width: "100%" }}
                                >
                                    {getDocumentName(row.document)}{" "}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        padding: "2px 16px",
                                        color: "#CCCCCC",
                                        //fontStyle: "italic",
                                        textAlign: "right",
                                    }}
                                >
                                    {getDocumentTypeName(
                                        row.documentType
                                    ).replace(/ /g, "\u00a0")}
                                </TableCell>
                                <TableCell
                                    sx={{ padding: "2px 2px" }}
                                    //align="right"
                                >
                                    <Tooltip title="Replace item">
                                        <IconButton
                                            onClick={() => editDocument(row)}
                                        >
                                            <CachedIcon
                                                color="inherit"
                                                sx={{ fontSize: "medium" }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        padding: "2px 2px",
                                        borderTopRightRadius: "5px",
                                        borderBottomRightRadius: "5px",
                                    }}
                                    //align="right"
                                >
                                    <Tooltip title="Remove item">
                                        <IconButton
                                            onClick={() => removeDocument(row)}
                                        >
                                            <RemoveIcon
                                                color="error"
                                                sx={{ fontSize: "medium" }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        padding: "2px 2px",
                                        bgcolor: "background.default",
                                    }}
                                >
                                    <Box>{getRowStatusIcon(row)}</Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
