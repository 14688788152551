import animation from "../assets/animation.mp4";
import { PageHeader } from "./common/pageHeader";
import { Box, Link, Typography } from "@mui/material";

export default function About() {
    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <video
                    controls
                    autoPlay
                    src={animation}
                    style={{
                        width: "60%",
                    }}
                />
            </Box>
            <br />
            <PageHeader title="Welcome!" />
            <p>
                Providing documentation and submitting credentials when applying
                for jobs in the medical profession is always more time consuming
                than it ever should be.
            </p>
            <p>
                Hospitals understandably need to be rigorous in their
                recruitment, meaning identification, references, working with
                children checks and qualifications all need to be collected and
                uploaded.
            </p>
            <p>
                For Doctors, it’s a frustrating hurdle that needs to be jumped,
                with the added complication that two hospitals rarely recruit in
                the same way, or ask for exactly the same information.
            </p>

            <p>
                That’s why HR tech business Ledge Group has created the Ledge
                Med Doctor Portal, a centralised platform that enables Doctors
                to create and store documentation, which can be quickly
                downloaded to apply for vacancies.
            </p>
            <p>The process is simple.</p>
            <p>
                First, upload your documents to LedgeMed’s secure online
                portal.
            </p>
            <p>
                Next, when the time comes to apply for a role, create a new
                application checklist via our platform and LedgeMed will
                automatically collate only the documents relevant to the role to
                a single location. Documents that are missing or expired will be
                flagged so you can see at a glance what items require action.
            </p>
            <p>
                After this, it’s just a matter of downloading the collated
                documents and sending them to the job owner as usual.
            </p>
            <p>
                In addition, we have launched the LedgeMed Knowledge Centre,
                this is your one-stop-shop for resources related to applying for
                roles, career paths and thriving within the medical profession.
            </p>
            <p>
                Preparing for a job application should be a simple process. Take
                back your time with LedgeMed, sign up today via ledgemed.com.au
            </p>
            <Typography
                sx={{
                    color: "text.secondary",
                    fontSize: "smaller",
                }}
            >
                Please see our{" "}
                <Link
                    href="#"
                    onClick={() => window.open("https://help.ledgemed.com.au/lmf/LedgeMed-Portal-Guide.46073537.html", "KnowledgeCenter", 'noopener,noreferrer')}
                >
                    LedgeMed Portal Guide
                </Link>{" "}
                section of the Knowledge Centre for a comprehensive guide on how
                to use this Portal.
            </Typography>
            <br />
        </Box>
    );
}
