import { useState, useContext } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Backdrop,
    Box,
    CircularProgress,
    Popover,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    useGridApiRef,
    DataGridPro,
    GridActionsCellItem,
    GridRowParams,
    GridCallbackDetails,
    GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import InfoIcon from "@mui/icons-material/Info";
import MDEditor from "@uiw/react-md-editor";
import { PageInfo } from "../common/pageInfo";
import { EditResult, EditProps, EditPropsClosed } from "../../common/types";
import {
    ConfirmDialog,
    ConfirmDialogProps,
    ConfirmDialogPropsClosed,
} from "../common/confirmDialog";
import { DocumentEditDialog } from "../documents/documentEdit";
import { Document } from "../../API";
import {
    deleteSingle,
    downloadDocument,
    viewDocument,
} from "../../common/documentUtils";
import { UserContext } from "../../common/userContext";
import * as utils from "../../common/typeUtils";

export type DocumentsCardProps = {
    name: string;
    info: string | null | undefined;
    documents: Document[];
};

export function DocumentsCard({ name, info, documents }: DocumentsCardProps) {
    const contextData = useContext(UserContext);
    const apiRef = useGridApiRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [pageError, setPageError] = useState<string | undefined>();
    const [processing, setProcessing] = useState<boolean>(false);
    const [backdropOpen, setBackdropOpen] = useState<boolean>(false);
    const [confirmDialogProps, setConfirmDialogProps] =
        useState<ConfirmDialogProps>(ConfirmDialogPropsClosed);
    const [editDialogProps, setEditDialogProps] =
        useState<EditProps>(EditPropsClosed);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget as any);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const getRowClassName = (params: any) => {
        const isExpired = utils.hasExpired(params.row.expires);
        return isExpired ? "super-app-theme--expired" : "";
    };

    const handleRowDoubleClick = (
        params: GridRowParams,
        event: any,
        details: GridCallbackDetails
    ) => {
        event.defaultMuiPrevented = true;
        doDocumentAction(params.row.id, viewDocument);
    };

    const viewRow = (id: string) => async (event: any) => {
        event.stopPropagation();
        doDocumentAction(id, viewDocument);
    };

    const downloadRow = (id: string) => async (event: any) => {
        event.stopPropagation();
        doDocumentAction(id, downloadDocument);
    };

    const doDocumentAction = async (id: string, documentAction:(UserWrapper, Document) => Promise<Error | undefined>) => {
        const row = apiRef.current.getRow(id) as Document;
        if (!row) {
            console.log(`Edit row for ${id} was null!`);
            return;
        }

        try {
            setBackdropOpen(true);
            const error = await documentAction(
                contextData.user,
                row
            );
            if (error) {
                setPageError(error.message);
                return;
            }
        } catch (error) {
            alert(`Unable to view document! ${error}`);
        } finally {
            setBackdropOpen(false);
        }
    };

    const updateRow = (id: string) => (event: any) => {
        event.stopPropagation();
        let editProps: EditProps = {
            open: true,
            id: id,
            data: undefined,
            callback: handleEditDialogResult,
            handleClose: (e) => setEditDialogProps(EditPropsClosed),
        };
        setEditDialogProps(editProps);
    };

    const handleEditDialogResult = (editResult: EditResult, document: any) => {
        setEditDialogProps(EditPropsClosed);
        switch (editResult) {
            case EditResult.Added:
                if (document) {
                    contextData.setDocuments([
                        ...contextData.documents,
                        document,
                    ]);
                }
                break;
            case EditResult.Updated:
                if (document) {
                    const except = contextData.documents.filter(
                        (r) => r.id !== document.id
                    );
                    contextData.setDocuments([...except, document]);
                }
                break;
            default:
                break;
        }
    };

    const deleteRow = (id: string) => (event: any) => {
        event.stopPropagation();
        const row = apiRef.current.getRow(id);
        if (!row) {
            console.log(`Edit row for ${id} was null!`);
            return;
        }
        let props: ConfirmDialogProps = {
            open: true,
            title: "Delete Document",
            description: `Are you sure that you would like to delete the document ${row.documentName}?`,
            action: "Delete",
            data: id,
            callback: handleDeleteRow,
        };
        setConfirmDialogProps(props);
    };

    const handleDeleteRow = async (
        action: string,
        data: any,
        result: boolean
    ) => {
        setConfirmDialogProps(ConfirmDialogPropsClosed);

        if (!result || action !== "Delete") return;

        const id = data as string;
        if (!id) {
            console.log("Delete row unexpected data!");
            return;
        }

        try {
            setProcessing(true);
            const error = await deleteSingle(contextData.user, id);
            if (error) {
                setPageError(error.message);
                return;
            }
            const except = contextData.documents.filter((r) => r.id !== id);
            contextData.setDocuments(except);
        } catch (error) {
            console.log(error);
        } finally {
            setProcessing(false);
        }
    };

    const columns = [
        {
            field: "id",
            headerName: "Id",
            hide: true,
            type: "string",
        },
        {
            field: "documentName",
            headerName: "Document Name",
            flex: 1,
            resizable: true,
            // renderCell: (params: GridRenderCellParams<string>) => (
            //     <DocumentTypeLabel id={params.row.documentTypeId} />
            // ),
        },
        {
            field: "expires",
            headerName: "Expiration",
            //flex: 0.5,
            width: 150,
            resizable: false,
            type: "date",
            renderCell: (params: GridRenderCellParams<string>) => (
                <>{params.value ? params.value : "n/a"}</>
            ),
        },
        // {
        //     field: "uploaded",
        //     headerName: "Uploaded",
        //     //flex: 0.5,
        //     width: 100,
        //     resizable: false,
        //     type: "boolean",
        // },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            //flex: 0.5,
            width: 150,
            resizable: false,
            cellClassName: "actions",
            getActions: ({ id }: any) => {
                const row = apiRef.current.getRow(id);
                const documentMissing = row?.uploaded === false ?? false;

                return [
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Edit">
                                <EditIcon />
                            </Tooltip>
                        }
                        label="Edit"
                        className="textPrimary"
                        onClick={updateRow(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Delete">
                                <DeleteIcon />
                            </Tooltip>
                        }
                        label="Delete"
                        onClick={deleteRow(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Download">
                                <DownloadIcon />
                            </Tooltip>
                        }
                        label="Download"
                        onClick={downloadRow(id)}
                        color="inherit"
                        disabled={documentMissing}
                        //readOnly={documentMissing}
                    />,
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="View">
                                <VisibilityIcon />
                            </Tooltip>
                        }
                        label="View"
                        onClick={viewRow(id)}
                        color="inherit"
                        disabled={documentMissing}
                        //readOnly={documentMissing}
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                width: "100%",
                //display: "flex-column",
                // border: "3px dashed green",
                "& .actions": {
                    color: "text.secondary",
                },
                "& .textPrimary": {
                    color: "text.primary",
                },
                "& .super-app-theme--expired": {
                    bgcolor: "mistyrose",
                },
            }}
        >
            <ConfirmDialog {...confirmDialogProps} />
            <DocumentEditDialog {...editDialogProps} />
            <PageInfo message={pageError} color="error" />
            <Accordion
                defaultExpanded={true}
                TransitionProps={{
                    timeout: 0,
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    //aria-controls="panel1bh-content"
                    //id="panel1bh-header"
                >
                    <Typography sx={{ margin: 0, padding: 0 }}>
                        {name}
                    </Typography>
                    <Box
                        aria-owns={open ? "mouse-over-popover" : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                        sx={{
                            m: 0,
                            display: "flex",
                            color: "text.primary",
                        }}
                    >
                        <InfoIcon
                            // onClick={handleClick}
                            sx={{
                                color: "primary.main",
                                fontSize: 20,
                                ml: 1,
                            }}
                        />
                        <Popover
                            sx={{
                                pointerEvents: "none",
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            disableRestoreFocus
                        >
                            <Box component="div" data-color-mode="light">
                                <MDEditor.Markdown
                                    //style={{background: "#FFFFFF", color: "#494949", borderRadius: "10", padding: "5px"}}
                                    style={{ padding: "5px" }}
                                    source={
                                        info ??
                                        `Click upload document to add document of type ${name}`
                                    }
                                />
                            </Box>
                        </Popover>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <DataGridPro
                        //autoPageSize
                        pagination={false}
                        autoHeight={true}
                        hideFooter={true}
                        disableColumnReorder={true}
                        rows={documents}
                        columns={columns}
                        apiRef={apiRef}
                        loading={processing}
                        disableSelectionOnClick
                        editMode="row"
                        getRowClassName={(params) =>
                            `${getRowClassName(params)}`
                        }
                        onRowDoubleClick={handleRowDoubleClick}
                        initialState={{
                            //pinnedColumns: { right: ["actions"] },
                            sorting: {
                                sortModel: [
                                    { field: "documentName", sort: "asc" },
                                ],
                            },
                        }}
                        components={{
                            NoRowsOverlay: () => (
                                <Box
                                    sx={{
                                        color: "text.disabled",
                                        mt: 1,
                                        ml: 1,
                                        mr: 1,
                                    }}
                                >
                                    You currently do not have any documents of
                                    this type uploaded.
                                </Box>
                            ),
                        }}
                    />
                </AccordionDetails>
            </Accordion>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}
