import { Theme, SxProps, Typography } from "@mui/material";

export type PageHeaderProps = {
    title: string;
    sx?: SxProps<Theme> | undefined;
};

export function PageHeader({ title }: PageHeaderProps) {
    return (
        <Typography variant="h5">
            {title.toUpperCase()}
        </Typography>
    );
}
