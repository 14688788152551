import { Theme, SxProps } from "@mui/material";
import { Typography } from "@mui/material";

export type PageInfoProps = {
    message: string | undefined;
    color: string;
    sx?: SxProps<Theme> | undefined;
};

export function PageInfo({ message, color, sx }: PageInfoProps) {

    return (
        <>
            {message && (
                <Typography
                    //variant="h6"
                    gutterBottom
                    component="div"
                    color={color}
                    sx={sx}
                >
                    {message}
                </Typography>
            )}
        </>
    );
}
