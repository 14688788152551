import { UserWrapper } from "../common/userContext";
import { callApi } from "../common/apiUtils";
import {
    Application,
    ApplicationInput,
    ApplicationSectionInput,
    ApplicationDocumentInput,
    QualificationLevel,
    PositionType,
} from "../API";
import { getNextMonthISODate } from "./typeUtils";

const { v4: uuid } = require("uuid");

export const templateUserId = "00000000-0000-0000-0000-000000000000";

export type ApplicationLookupResult = {
    applicationInput: ApplicationInput | undefined;
    error: Error | undefined;
    isTemplate: boolean;
    basedOn: string;
};

export const getApplicationById = /* GraphQL */ `
    query GetApplicationById($pk: ID!) {
        getApplicationById(pk: $pk) {
            id
            name
            userId
            state
            sector
            hospital
            positionType
            level
            notes
            dueDate
            created
            updated
            applicationSections {
                id
                applicationId
                name
                created
                updated
                applicationDocuments {
                    id
                    applicationSectionId
                    documentTypeId
                    documentId
                    created
                    updated
                }
            }
        }
    }
`;

export function getNewApplicationAsInput(
    isTemplate: boolean
): ApplicationLookupResult {
    const blank: ApplicationInput = {
        id: uuid(),
        name: "",
        state: "",
        sector: "Public",
        hospital: "",
        positionType: PositionType.Permanent,
        level: QualificationLevel.Student,
        dueDate: getNextMonthISODate(),
        notes: "",
        applicationSections: [],
    };
    return {
        applicationInput: blank,
        error: undefined,
        isTemplate: isTemplate,
        basedOn: "",
    } as ApplicationLookupResult;
}

export async function getExistingApplicationAsInput(
    user: UserWrapper,
    id: string,
    cloneAsNew: boolean
): Promise<ApplicationLookupResult> {
    const application = await callApi<Application>(user, "getApplicationById", {
        query: getApplicationById,
        variables: { pk: id },
    });
    const applicationInput = application.Result
        ? convertToApplicationInput(application.Result, cloneAsNew)
        : undefined;

    const result: ApplicationLookupResult = {
        applicationInput: applicationInput,
        error: application.Error,
        isTemplate: application.Result?.userId === templateUserId ?? true,
        basedOn: cloneAsNew ? application.Result?.name ?? "" : "",
    };
    return result;
}

export function convertToApplicationInput(
    application: Application,
    cloneAsNew: boolean
) {
    const isTemplate = application.userId === templateUserId;
    const sections = application.applicationSections.map((s) => {
        const documents = s.applicationDocuments.map((d) => {
            const documentInput: ApplicationDocumentInput = {
                isNew: cloneAsNew,
                id: cloneAsNew ? uuid() : d.id,
                applicationSectionId: d.applicationSectionId,
                documentTypeId: d.documentTypeId,
                documentId: d.documentId,
            };
            return documentInput;
        });

        const sectionInput: ApplicationSectionInput = {
            isNew: cloneAsNew,
            id: cloneAsNew ? uuid() : s.id,
            applicationId: s.applicationId,
            name: s.name,
            applicationDocuments: documents.sort((a, b) =>
                a.id.localeCompare(b.id)
            ),
        };
        return sectionInput;
    });

    const input: ApplicationInput = {
        id: cloneAsNew ? uuid() : application.id,
        name: cloneAsNew
            ? isTemplate
                ? ""
                : "Copy of " + application.name
            : application.name,
        state: application.state,
        sector: application.sector ?? "Public",
        hospital: application.hospital,
        positionType: application.positionType,
        level: application.level,
        dueDate: cloneAsNew ? getNextMonthISODate() : application.dueDate,
        notes: cloneAsNew ? "" : application.notes,
        applicationSections: sections.sort((a, b) =>
            a.name.localeCompare(b.name)
        ),
    };
    return input;
}
