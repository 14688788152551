import { Box, Grid, TextField, Typography } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import auLocale from "date-fns/locale/en-AU";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import MuiPhoneNumber from "material-ui-phone-number";
import { UserInput } from "../../API";
import * as common from "./profileCommon";
import * as utils from "../../common/typeUtils";

export type ApplicationSectionProps = {
    userInput: UserInput;
};

export function ProfileGeneralDetails({
    userInput,
    setValue,
    gridItemWidth,
}: common.ProfileSectionProps) {
    return (
        <Box
            sx={{
                boxSizing: "border-box",
                p: 1,
                borderRadius: "5px",
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">General Details</Typography>
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <TextField
                        autoFocus
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                        inputProps={{ maxLength: 256 }}
                        name="firstName"
                        label="First Name*"
                        value={userInput.firstName}
                        onChange={(e) =>
                            setValue(e.target.name, e.target.value)
                        }
                        error={userInput.firstName ? false : true}
                        helperText={userInput.firstName ? "" : "required field"}
                        sx={{ backgroundColor: "white" }}
                    />
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                        inputProps={{ maxLength: 256 }}
                        name="lastName"
                        label="Last Name*"
                        value={userInput.lastName}
                        onChange={(e) =>
                            setValue(e.target.name, e.target.value)
                        }
                        error={userInput.lastName ? false : true}
                        helperText={userInput.lastName ? "" : "required field"}
                        sx={{ backgroundColor: "white" }}
                    />
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <MuiPhoneNumber
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                        inputProps={{ maxLength: 256 }}
                        name="phone"
                        label="Phone Number*"
                        defaultCountry={"au"}
                        onlyCountries={["au", "nz"]}
                        value={userInput.phone}
                        onChange={(phoneNumber) => {
                            console.log(phoneNumber);
                            setValue("phone", phoneNumber);
                        }}
                        error={
                            utils.isValidPhoneNumber(userInput.phone) === false
                        }
                        helperText={
                            utils.isValidPhoneNumber(userInput.phone)
                                ? ""
                                : "required number with country code, e.g. +61 438 333 333"
                        }
                        sx={{ 
                            backgroundColor: "white",
                              '& .MuiPhoneNumber-flagButton': {
                                width: 'min-content'
                            }}}
                    />
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={auLocale}
                    >
                        <DatePicker
                            value={utils.parseISOAndConvertToDate(userInput.dateOfBirth)}
                            onChange={(date) =>
                                utils.tryParseDateAndSetISOValue(date, (s) =>
                                    setValue("dateOfBirth", s)
                                )
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    //inputProps={{ maxLength: 256 }}
                                    name="dateOfBirth"
                                    label="DOB"
                                    sx={{ backgroundColor: "white" }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </Box>
    );
}
