// When using TypeScript 4.x and above
//import type {} from '@mui/x-data-grid/themeAugmentation';
// import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// export const theme = responsiveFontSizes(createTheme({
// // palette: {
// //     primary: {
// //       main: "#131349"
// //     },
// //     secondary: {
// //       main: "#b0ddc9"
// //     },
// //     tertiary: {
// //       main: "#fe6962"
// //     }
//   components: {
//     // Use `MuiDataGrid` on both DataGrid and DataGridPro
//     // MuiDataGrid: {
//     //   styleOverrides: {
//     //     root: {
//     //       backgroundColor: 'red',
//     //     },
//     //   },
//     // },
//     // MuiTextField: {
//     //   styleOverrides: {
//     //     root: {
//     //         fullWidth: "true",
//     //         variant:"outlined",
//     //         size:"small",
//     //         margin:"dense",
//     //     },
//     //   },
//     // },
//   },
// }));

import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import { styled, createTheme, responsiveFontSizes } from "@mui/material/styles";
import { common } from "@mui/material/colors";

export const theme = responsiveFontSizes(
    createTheme({
        palette: {
            primary: {
                main: "#014F41",
            },
            secondary: {
                main: "#494949",
            },
            // tertiary: {
            // 	main: '#fe6962',
            // },
            background: {
                default: "#F9F9F9",
                paper: "#FFFFFF",
            },
            divider: "#A0A0A0",
            success: {
                main: "#014F41",
            },
            warning: {
                main: "#FECE25",
            },
            error: {
                main: "#E25858",
            },
            common: {},
        },
        typography: {
            fontFamily: "Roboto",
            h1: {
                fontSize: "1.375em",
                fontWeight: "600",
            },
            h2: {
                fontSize: "1.25em",
                fontWeight: "600",
            },
            h3: {
                fontSize: "1.125em",
                fontWeight: "600",
            },
            body1: {
                fontSize: "1em",
                fontWeight: "500",
                color: "#494949",
            },
            body2: {
                fontSize: "0.875em",
                fontWeight: "500",
                color: "#494949",
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 5,
                        boxShadow: "none",
                    },
                    contained: {
                        "&:hover": {
                            boxShadow: "none",
                        },
                    },
                },
            },
            // MuiDivider: {
            //     styleOverrides: {
            //         root: {
            //             background: "#A0A0A0",
            //         },
            //     },
            // },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        background: "#FFFFFF",
                        // "&:hover": {
                        //     background: "#EEEEEE",
                        // },
                    },
                },
            },
            // MuiListItemButton: {
            //     styleOverrides: {
            //         root: {
            //             background: "#FFFFFF",
            //             "&:hover": {
            //                 background: "#EEEEEE",
            //             },
            //         },
            //     },
            // },
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        borderRadius: 5,
                        boxShadow: "none",
                    },
                    // variants: {
                    //   // outlined: {
                    //   //   style :{
                    //   //     borderRadius: 0,
                    //   //   }
                    //   // },
                    // },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: 5,
                        boxShadow: "none",
                        elevation: 0,
                        backgroundColor: "#FFFFFF",
                    },
                },
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#F9F9F9",
                    },
                },
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#F9F9F9",
                    },
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#F9F9F9",
                    },
                },
            },
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        borderRadius: 5,
                    },
                    columnHeaders: {
                        backgroundColor: "#E8F0F0",
                        color: "#014F41",
                    },
                    columnHeaderTitleContainer: {
                        padding: 0,
                    },
                    main: {
                        backgroundColor: "#FFFFFF",
                    },
                },
            },
            // MuiCircularProgress: {
            //     styleOverrides: {
            //         root: {
            //             color: "green",
            //         },
            //     },
            // },
            // MuiBreadcrumbs: {
            // 	styleOverrides: {
            // 		root: {
            // 			color: '#000000',
            // 		},
            // 	},
            // },
            // MuiChip: {
            // 	styleOverrides: {
            // 		root: {
            // 			borderRadius: 0,
            // 			boxShadow: 'none',
            // 		},
            // 	},
            // },
        },
    })
);
