import { useState, useContext } from "react";
//import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { UserContext } from "../../common/userContext";
import {
    useGridApiRef,
    DataGridPro,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { deleteDocumentType } from "../../graphql/mutations";
import { DocumentTypeLabel } from "../common/documentTypeLabel";
import { DocumentTypeEditDialog } from "./documentTypeEdit";
import {
    ConfirmDialog,
    ConfirmDialogProps,
    ConfirmDialogPropsClosed,
} from "../common/confirmDialog";
import { EditResult, EditProps, EditPropsClosed } from "../../common/types";
import { PageHeader } from "../common/pageHeader";
import { DocumentType } from "../../API";
import { callApi } from "../../common/apiUtils";

function EditToolbar(props: any) {
    const { addRow } = props;

    const handleClick = () => {
        addRow();
    };

    return (
        <GridToolbarContainer>
            <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleClick}
                sx={{mb:1}}
            >
                Add
            </Button>
        </GridToolbarContainer>
    );
}

// EditToolbar.propTypes = {
//   apiRef: PropTypes.shape({
//     current: PropTypes.object.isRequired,
//   }).isRequired,
// };

export default function DocumentTypeAdmin() {
    const apiRef = useGridApiRef();
    //const loaded = useRef(false);
    const contextData = useContext(UserContext);
    const [processing, setProcessing] = useState<boolean>(false);
    const [confirmDialogProps, setConfirmDialogProps] =
        useState<ConfirmDialogProps>(ConfirmDialogPropsClosed);
    const [editDialogProps, setEditDialogProps] =
        useState<EditProps>(EditPropsClosed);

    const addRow = () => {
        let editProps: EditProps = {
            open: true,
            id: "add",
            data: undefined,
            callback: handleEditDialogResult,
            handleClose: (e) => setEditDialogProps(EditPropsClosed),
        };
        setEditDialogProps(editProps);
    };

    const updateRow = (id: string) => (event: any) => {
        event.stopPropagation();
        let editProps: EditProps = {
            open: true,
            id: id,
            data: undefined,
            callback: handleEditDialogResult,
            handleClose: (e) => setEditDialogProps(EditPropsClosed),
        };
        setEditDialogProps(editProps);
        // const encoded = encodeURIComponent(id);
        // navigate(`/${encoded}/documentTypeEdit`);
    };

    const deleteRow = (id: string) => async (event: any) => {
        event.stopPropagation();
        const row = apiRef.current.getRow(id);
        if (!row) return;
        let props: ConfirmDialogProps = {
            open: true,
            title: "Delete Document Type",
            description: `Are you sure that you would like to delete the document ${row.pathName}?`,
            action: "Delete",
            data: id,
            callback: handleConfirmDialogResult,
        };
        setConfirmDialogProps(props);
    };

    const handleEditDialogResult = async (
        editResult: EditResult,
        documentType: any
    ) => {
        setEditDialogProps(EditPropsClosed);
        switch (editResult) {
            case EditResult.Added:
                if (documentType) {
                    contextData.setDocumentTypes([
                        ...contextData.documentTypes,
                        documentType,
                    ]);
                }
                break;
            case EditResult.Updated:
                if (document) {
                    const except = contextData.documentTypes.filter(
                        (r) => r.id !== documentType.id
                    );
                    contextData.setDocumentTypes([...except, documentType]);
                }
                break;
            default:
                break;
        }
    };

    const handleConfirmDialogResult = async (
        action: string,
        data: any,
        result: boolean
    ) => {
        setConfirmDialogProps(ConfirmDialogPropsClosed);

        if (!result || action !== "Delete") return;

        const row = apiRef.current.getRow(data as string);
        if (!row) {
            console.log(`Edit row for ${data} was null!`);
            return;
        }

        try {
            setProcessing(true);
            const deleteResult = await callApi<DocumentType>(
                contextData.user,
                "deleteDocumentType",
                {
                    query: deleteDocumentType,
                    variables: { pk: row.id },
                }
            );
            if (!deleteResult.Result)
                console.log(
                    "Error deleting document type record: " + deleteResult.Error
                );
            const except = contextData.documentTypes.filter(
                (r) => r.id !== row.id
            );
            contextData.setDocumentTypes(except);
        } catch (error) {
            console.log(error);
        } finally {
            setProcessing(false);
        }
    };

    const columns = [
        {
            field: "id",
            headerName: "id",
            hide: true,
        },
        {
            field: "pathName",
            headerName: "Name",
            flex: 5,
            renderCell: (params: GridRenderCellParams<string>) => (
                <DocumentTypeLabel id={params.row.id} />
            ),
        },
        {
            field: "hasMany",
            headerName: "HasMany",
            flex: 1,
            type: "boolean",
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: ({ id }: any) => {
                // const isInEditMode = apiRef.current.getRowMode(id) === "edit";
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={updateRow(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={deleteRow(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
            }}
        >
            <ConfirmDialog {...confirmDialogProps} />
            <DocumentTypeEditDialog {...editDialogProps} />
            <PageHeader title="Document Types" />
            <DataGridPro
                autoPageSize
                pagination
                loading={processing}
                rows={contextData.documentTypes}
                columns={columns}
                apiRef={apiRef}
                // getRowId={(row) => `${row.pathName}`}
                editMode="row"
                components={{
                    Toolbar: EditToolbar,
                }}
                componentsProps={{
                    toolbar: { addRow },
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "pathName", sort: "asc" }],
                    },
                }}
            />
        </Box>
    );
}
