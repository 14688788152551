import { useState } from "react";
import { Box, IconButton, Link, Tooltip } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { DocumentsCardView } from "./documentsCardView";
import { DocumentsGridView } from "./documentsGridView";
import { PageHeader } from "../common/pageHeader";

export default function MyDocuments() {
    const [isCardView, setIsCardView] = useState<boolean>(true);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",
                    width: "100%",
                    mb: 1,
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <PageHeader title="My Documents" />
                </Box>

                <Tooltip title="Card View">
                    <IconButton
                        onClick={() => setIsCardView(true)}
                        edge="start"
                        sx={{
                            color: isCardView
                                ? "primary.main"
                                : "text.disabled",
                        }}
                    >
                        <GridViewIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Grid View">
                    <IconButton
                        color="inherit"
                        onClick={() => setIsCardView(false)}
                        edge="start"
                        sx={{
                            color: isCardView
                                ? "text.disabled"
                                : "primary.main",
                        }}
                    >
                        <TableRowsIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            <p>
                Think of this as your personal library of documents. What you
                add here will be used to automatically fill the Applications you
                create. Just add a document, name it, pick its Document Type and
                expiry if relevant, click save and it’s in the library! If you
                need to download some or all of the documents, select the
                documents, click download and they’ll be downloaded in a .zip
                file for you.{" "}
            </p>

            <p>
                If you need any information on where to attain any of the
                specific documents, please see{" "}
                <Link
                    href="#"
                    onClick={() =>
                        window.open(
                            "https://help.ledgemed.com.au/lmf/How-to-attain-specific-documentation.46073638.html",
                            "KnowledgeCenter",
                            "noopener,noreferrer"
                        )
                    }
                >
                    this section
                </Link>{" "}
                of the Knowledge Centre
            </p>

            {isCardView ? <DocumentsCardView /> : <DocumentsGridView />}
        </Box>
    );
}
