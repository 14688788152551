import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TableCell,
    Tooltip,
    Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    ApplicationSectionInput,
    ApplicationDocumentInput,
    DocumentType,
} from "../../API";
import {
    parsePathName,
} from "../../common/typeUtils";

export type TemplateSectionData = {
    applicationSection: ApplicationSectionInput;
    documents: TemplateDocumentData[];
};

export type TemplateDocumentData = {
    applicationDocument: ApplicationDocumentInput;
    documentType: DocumentType;
};

export type TemplateSectionEditProps = {
    data: TemplateSectionData;
    editSection: () => void;
    deleteSection: () => void;
};

function getName(data: TemplateDocumentData) {
    if (data.documentType)
        return parsePathName(data.documentType.pathName).name;
    return "Missing";
}

export function TemplateSectionEdit({
    data,
    editSection,
    deleteSection,
}: TemplateSectionEditProps) {

    const sortedDocuments = data.documents.sort((a, b) =>
        getName(a).localeCompare(getName(b))
    );

    return (
        <Box
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    mt: 2,
                    alignItems: "center",
                    verticalAlign: "center",
                }}
            >
                <Typography
                    sx={{
                        alignSelf: "center",
                        fontSize: "medium",
                        fontWeight: "bolder",
                        padding: "3px 16px",
                    }}
                >
                    {data.applicationSection.name.toUpperCase()}
                </Typography>
                <Tooltip title="Edit group to add or remove checklist items">
                    <IconButton onClick={editSection}>
                        <EditIcon sx={{ fontSize: "medium" }} />
                    </IconButton>
                </Tooltip>
                <IconButton onClick={deleteSection}>
                    <DeleteIcon color="error" sx={{ fontSize: "medium" }} />
                </IconButton>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }}>
                    <TableBody style={{ height: "1px" }}>
                        {sortedDocuments.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ padding: "2px 16px" }}>
                                    {getName(row)}{" "}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
