import { useState, useContext, useEffect } from "react";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {
    ConfirmDialog,
    ConfirmDialogProps,
    ConfirmDialogPropsClosed,
} from "../common/confirmDialog";
import { getErrorMessage } from "../../common/errorUtils";
import { callApi } from "../../common/apiUtils";
import { UserAuth0, UserAuth0Input } from "../../API";
import { getUserAuth0ById } from "../../graphql/queries";
import { updateUserAuth0 } from "../../graphql/mutations";
import { PageHeader } from "../common/pageHeader";
import { UserContext } from "../../common/userContext";

export function Privacy() {
    const contextData = useContext(UserContext);

    const [info, setInfo] = useState<[string, boolean]>(["", false]);
    const [backdropOpen, setBackdropOpen] = useState<boolean>(true);
    const [confirmDialogProps, setConfirmDialogProps] =
        useState<ConfirmDialogProps>(ConfirmDialogPropsClosed);
    const [usesMfa, setUsesMfa] = useState(false);
    const [initialised, setInitialised] = useState(false);

    useEffect(() => {
        const initialise = async () => {
            try {
                const result = await callApi<UserAuth0>(
                    contextData.user,
                    "getUserAuth0ById",
                    {
                        query: getUserAuth0ById,
                        variables: { pk: contextData.user.sub },
                    }
                );
                if (result.Error) {
                    setBackdropOpen(false);
                    setInfo([result.Error.message, true]);
                    return;
                }
                setUsesMfa(result.Result?.useMfa ?? false);
                setInitialised(true)
            } catch (error) {
                console.log(error);
                setInfo(["There was en error initialising: " + error, true]);
            } finally {
                setBackdropOpen(false);
            }
        };

        initialise();
    }, []);

    const doUpdateMfa = async (enable: boolean, signOutOnComplete: boolean) => {
        try {
            setBackdropOpen(true);
            const update: UserAuth0Input = {
                id: contextData.user.sub,
                useMfa: enable,
                resetMfa: true,
            };
            const result = await callApi<UserAuth0>(
                contextData.user,
                "updateUserAuth0",
                {
                    query: updateUserAuth0,
                    variables: { item: update },
                }
            );
            if (result.Error) {
                setBackdropOpen(false);
                setInfo([result.Error.message, true]);
                return;
            }
            if (signOutOnComplete) {
                contextData.user.signOut();
                return;
            }
            setUsesMfa(enable);
            setInfo(["", false]);
        } catch (error) {
            setInfo([getErrorMessage("Update privacy", error), true]);
        } finally {
            setBackdropOpen(false);
        }
    };

    const updateMfa = async (enable: boolean) => {
        const handleConfirmDialogResult = async (
            action: string,
            data: any,
            result: boolean
        ) => {
            setConfirmDialogProps(ConfirmDialogPropsClosed);
            if (enable) {
                await doUpdateMfa(enable, result);
            } else {
                if (result) await doUpdateMfa(enable, false);
            }
        };

        const props: ConfirmDialogProps = enable
            ? {
                  open: true,
                  title: "Enable MFA",
                  description:
                      "Enabling MFA will require an initial configuration that will log you out of your account. Do you want to configure MFA for your account now or on your next login?",
                  action: "Yes, I would like to configure MFA now",
                  cancel: "I'll configure MFA later",
                  callback: handleConfirmDialogResult,
                  data: enable,
              }
            : {
                  open: true,
                  title: "Disable MFA",
                  description:
                      "Are you sure that you would like to disable MFA for your account?",
                  action: "Yes",
                  callback: handleConfirmDialogResult,
                  data: enable,
              };
        setConfirmDialogProps(props);
    };

    return (
        <Box sx={{ mt: 1, ml: 0, width: "800px" }}>
            <ConfirmDialog {...confirmDialogProps} />

            <PageHeader title="Privacy" />

            <Typography variant="h6" component="div" sx={{ mt: 2 }}>
                Multi Factor Authentication
            </Typography>

            <p>
                Before you begin using the LedgeMed Portal, we strongly suggest
                that you enable multi-factor authentication (MFA) to ensure
                maximum protection over any personal details or documents you
                may choose to upload. MFA can be enabled or disabled at any time
                via this page.
            </p>

            {initialised && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        mt: 2,
                    }}
                >
                    {usesMfa ? (
                        <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                    ) : (
                        <ErrorIcon color="error" sx={{ mr: 1 }} />
                    )}
                    {usesMfa === false
                        ? "Multi Factor Authentication is currently disabled"
                        : "Multi Factor Authentication is currently enabled"}
                </Box>
            )}

            {info[0] && (
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ mt: 2 }}
                    color={info[1] === false ? "green" : "error"}
                >
                    {info[0]}
                </Typography>
            )}

            <br />

            <Button
                variant="contained"
                onClick={() => updateMfa(true)}
                disabled={!initialised || usesMfa}
                sx={{ mr: 2 }}
            >
                Enable MFA
            </Button>

            <Button
                variant="contained"
                onClick={() => updateMfa(false)}
                disabled={!initialised || !usesMfa}
            >
                Disable MFA
            </Button>

            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}
