import {
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    Grid,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { QualificationLevel, UserInput } from "../../API";
import * as common from "./profileCommon";
import * as utils from "../../common/typeUtils";

export type ApplicationSectionProps = {
    userInput: UserInput;
};

export function ProfileInterests({
    userInput,
    setValue,
    gridItemWidth,
}: common.ProfileSectionProps) {
    return (
        <Box
            sx={{
                boxSizing: "border-box",
                p: 1,
                borderRadius: "5px",
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">Interests</Typography>
                    <p>
                        This will help us to suggest jobs that we think you will
                        be interested in. You can select multiple options in
                        both the fields bellow.
                    </p>
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                    >
                        <InputLabel id="seekingLevelsLabel">
                            What level job are you looking for?
                        </InputLabel>
                        <Select
                            multiple
                            labelId="stageOfTrainingLabel"
                            label="What level job are you looking for?"
                            name="seekingLevels"
                            value={userInput.seekingLevels ?? []}
                            onChange={(e) => {
                                const selected = e.target.value as string[];
                                const levels =
                                    utils.filterDefined<QualificationLevel>(
                                        selected.map(
                                            (l) => QualificationLevel[l]
                                        )
                                    );
                                setValue("seekingLevels", levels);
                            }}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={{
                                PaperProps: { sx: { maxHeight: 200 } },
                            }}
                            sx={{ backgroundColor: "white" }}
                        >
                            {Object.values(QualificationLevel).map((option) => (
                                <MenuItem key={option} value={option}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={
                                                (
                                                    userInput.seekingLevels ??
                                                    []
                                                ).indexOf(option) > -1
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={option} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                    >
                        <InputLabel id="specialityLabel">
                            What specialty field are you looking for?
                        </InputLabel>
                        <Select
                            multiple
                            labelId="specialityLabel"
                            label="What specialty field are you looking for?"
                            name="seekingSpecialities"
                            value={userInput.seekingSpecialities ?? []}
                            onChange={(e) => {
                                setValue(
                                    e.target.name,
                                    e.target.value as string[]
                                );
                            }}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={{
                                PaperProps: { sx: { maxHeight: 200 } },
                            }}
                            sx={{ backgroundColor: "white" }}
                        >
                            {common.AreasOfSpecialization.map((option) => (
                                <MenuItem key={option} value={option}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={
                                                (
                                                    userInput.seekingSpecialities ??
                                                    []
                                                ).indexOf(option) > -1
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={option} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
}
